<template>
  <div>
    <div class="code_box">
        <div class="code_box_item">
          <img :src="codeUrl" @click="getCode" />
         
        </div>
        <div class="code">
          <input v-model="imgCode" ref="code"  />
         
        </div>
    </div>
    <div class="code_p">
      <p>看不清?点击图片切换</p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
    name: 'GetImgCode',
    props:{
      userPhone:{
        type:String
      }
    },
    data() {
      return {
        codeUrl:'',
        imgUrl:'data:image/gif;base64,',
        // userPhone:this.$parent.userPhone
        imgCode:''
        }
    }
    ,methods:{
        getCode(){
          //生成图片验证码
            axios({
                  method: "post",//指定请求方式
                  url: "/web/sms/imgCode",
                  data: {
                    userPhone:Number(this.userPhone),
                  }
                })
                .then((res)=>{
                  if(res.data.code==200){
                    //let json = JSON.stringify(res.data.data.url);//tostring
                    this.codeUrl= this.imgUrl+res.data.data.url
                  }else{
                    this.$message.error('获取失败！');
                    console.log(res);
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })      
        }
        ,verificationImgCode(){
           //图片验证码验证 相同就发送短信
          
            axios({
                  method: "post",//指定请求方式
                  url: "/web/sms/verificationImgCode",
                  data: {
                    userPhone:Number(this.userPhone),
                    imgCode:this.imgCode
                  }
                })
                .then((res)=>{
                  if(res.data.code==200){
                    //alert("调用前")
                    this.$message.success(res.data.message);
                    
                    //this.$parent.countDown();
                    //this.$parent.closeModal();
                    //alert("调用")
                    this.$emit('refreshMethod');
                  }else{
                    this.$message.error(res.data.message);
                    console.log(res);
                    
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })  

        }
        

    },mounted() {
        this.getCode();
        this.$refs.code.focus()
    },
}
</script>
<style>
.code_box{
  text-align: center;
  display: flex;
    justify-content: center;
}
.code_box img{
  height: 3rem;
}
.code_box input{
  height: 3rem;
  text-align: center;
  border: 0px;
  
}

.code_box input:focus {
        outline: 0px solid #51c9ff;
        background: rgba(3, 16, 28, 0);
}
.code{
  border-bottom: 2px solid #333;
  overflow: hidden;
}
.code_p{
    text-align: center;
    font-size: 13px;
    color: #999;
}
/* @media all and (orientation : portrait) {
  .code_box_item{
    width: 100%;
  }
  .code_box_item img{
    height: 100%;
  }
  .code{
    width: 100%;
  }
  .code input{
    width: 100%;
    height: 100%;
    font-size: 8rem;
  }

} */
</style>