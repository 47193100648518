<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  
    <NavBar></NavBar>
    <CarouselEl></CarouselEl>
    <IndexTitle :ftitle='this.title'/>
    <AnnunciatorBoards/>
    <IndexTitle :ftitle='this.title2'/>
    <content-show/>
    <IndexTitle :ftitle='this.title3'/>
    <GameFeature></GameFeature>
    <IndexTitle :ftitle='this.title4'/>
    <ShowVideo></ShowVideo>
    <PageBottom/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'  
 
import CarouselEl from '@/components/index/CarouselEl.vue'
import AnnunciatorBoards from '@/components/index/AnnunciatorBoards.vue'
import IndexTitle from '@/components/index/IndexTitle.vue';
import PageBottom from '@/components/PageBottom.vue';
import ContentShow from '@/components/index/ContentShow.vue';

import ShowVideo from '@/components/index/ShowVideo.vue'
import GameFeature from '@/components/index/GameFeature.vue';

export default {
  name: 'HomeView',
  components: {
    NavBar,
    CarouselEl,
    AnnunciatorBoards,
    IndexTitle,
    PageBottom,
    ContentShow,
   
    ShowVideo,
    GameFeature
},data() {
      return {
        title: '最新资讯',
        title2: '游戏地图',
        title3: '游戏特色',
        title4: '游戏视频',
      }
    },methods:{
      loadInfo(){
        this.$http.post('/web/visit/addVisitRecord')
                .then((res)=>{
                  if(res.data.code==200){
                   console.log(res.data)
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
      }
    },mounted(){
      this.loadInfo();
    }
}
</script>
<style scoped>
.home{
  background-color: #17171A;
}
</style>
