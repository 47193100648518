import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//
import animated from 'animate.css'
import 'wowjs/css/libs/animate.css'
//
import axios from 'axios'


// import VueAxios from 'vue-axios'
// Vue.use(VueAxios)
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

// Vue.use(Editor);
// Vue.use(Toolbar);
//main.js 文件
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)


import moment from 'moment'  //引入
moment.locale("zh-cn"); //设置语言 或 moment.lang('zh-cn');
Vue.prototype.$moment = moment; //赋值使用
// 挂载  时间处理  2022-06-12T00:00:00.000+0800
Vue.prototype.moment = moment
Vue.filter('dateFormat', function (dateStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dateStr).format(pattern)
})


//Vue.use(axios)
Vue.prototype.axios = axios;
Vue.prototype.$http = axios;
axios.defaults.headers['token']=localStorage.getItem("token")
axios.defaults.headers['a_token']=localStorage.getItem("a_token")

Vue.prototype.animated = animated;

//Vue.use(animated)

Vue.use(ElementUI);
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  created(){
    if (document.getElementById("Loading")) {
      document.getElementById("Loading").remove();
    }
  },
  render: h => h(App)
}).$mount('#app')
