<template>
    <div class="admin_header">
        <div class="header_log">
          <img src="@/assets/logo.png">
        </div>
        <div class="header_item">
          
        </div>
        <div class="header_right">
            <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-menu-item index="1">
                  <el-avatar size="large" :src="circleUrl"></el-avatar>
                </el-menu-item>
                <el-submenu index="2">
                    <template slot="title">个人中心</template>
                    <el-menu-item index="2-1">选项1</el-menu-item>
                    <el-menu-item index="2-2">选项2</el-menu-item>
                    <el-menu-item index="2-3">选项3</el-menu-item>
                  
                </el-submenu>
             
                <el-menu-item index="100" @click="outLogin">退出登录</el-menu-item>
            </el-menu>
        </div>
    </div>
</template>
<script>
    export default {
    name:'AdminTopNav',
    data() {
      return {
        circleUrl:require("@/assets/images/icons/headdemo1.png"),
        activeIndex: '1',
        activeIndex2: '1',
        isCollapse: true
      };
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      outLogin(){
         this.$confirm('确定要退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          localStorage.removeItem("a_token") 
          localStorage.removeItem("adminId")
          localStorage.removeItem("a_data");
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          this.$router.push("/adminlogin");
          location.reload();

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      }
    }
  }
</script>
<style>
.header_log{
  width: 200px;
  text-align: center;
}
.header_log img{
  height: 60px;
}
.admin_header{
  display: flex;
  border-bottom: solid 1px #e6e6e6;
}

.header_right{
  margin-left: auto;
}
</style>