<template>
    <div class="comment_value">
        <div ref="comment_message" style="display: none;">还没有评论喔！</div>
        <!--  -->
        <div class="comment_value_item" v-for="commentInfo in commentInfoList" :key="commentInfo.commentNumber">
            <div class="comment_user_photo_box">
                <img class="comment_user_photo" :src="commentInfo.userInfo.userPhoto">
            </div>
            <div class="comment_bottom_msg">
                <div class="comment_bottom_box">
                    <div class="comment_bottom_box_header">
                        <div class="comment_cursor">
                            {{commentInfo.userInfo.userName}}
                        </div>
                        <div></div>
                    </div>
                    <div class="comment_bottom_box_value">
                       {{commentInfo.commentContent}}
                    </div>
                    <div class="comment_bottom_box_end">
                        <div>
                            <span class="comment_bottom_box_end_i">{{commentInfo.commentTime| dateFormat}}</span>|
                        </div>
                        <div>
                            <span :value="commentInfo.commentId" class="comment_bottom_box_end_i comment_cursor" @click="showTextarea($event,commentInfo.commentId,commentInfo.userInfo.userId,commentInfo.commentId)" >回复</span>
                        </div>
                        <div>
                            <span :data-id="commentInfo.commentId" class="comment_bottom_box_end_i comment_cursor" @click="showModal(commentInfo.commentId)">举报</span>
                        </div>
                    </div>
                    <div class="reComponentBox" :id="commentInfo.commentId" style="display: none;">
                        <TextareaComponent></TextareaComponent>
                    </div>
                    
                </div>
            <!-- ----- 回复的回复------ -->
             <div v-if="commentInfo.replyCommentList.length>0">
                <div class="comment_reply_box" v-for="reComment in commentInfo.replyCommentList" :key="reComment.commentNumber">
                    <div>
                        <div class="comment_reply_header">
                            <img :src="reComment.userInfo.userPhoto">
                            <span class="comment_reply_name">
                                <a href="">{{reComment.userInfo.userName}} </a>
                                <span v-if="reComment.reUserInfo!=null">
                                    回复<a href="">{{reComment.reUserInfo.userName}}</a>
                                </span>
                            </span>
                            <div class="reply_repor" @click="showModal(commentInfo.commentId)">
                                举报
                            </div>
                        </div>
                        <div class="comment_reply_value">{{reComment.commentContent}}
                        </div>
                        <div class="comment_reply_end">
                            <span class="comment_reply_end_i">{{reComment.commentTime| dateFormat}}</span>|
                            <span class="comment_reply_end_i comment_cursor" @click="showTextarea($event,reComment.commentId,reComment.userInfo.userId,commentInfo.commentId)">回复</span>
                        </div>
                        <div class="reComponentBox" :id="reComment.commentId" style="display: none;">
                            <TextareaComponent></TextareaComponent>
                        </div>
                        
                        <!-- <TextareaComponent v-show="false"></TextareaComponent> -->
                    </div>
                </div>
             </div>
            <!--  -->
            <div v-if="commentInfo.total>3" class="load_more_r">
                <span v-b-modal.modal-scrollable  class="comment_cursor" @click="loadMoreReComment(commentInfo.commentId)" >查看更多</span>
            </div>
            </div>
        </div>
        <!--  -->
        <div v-if="lastRows>1" class="load_more_comment">
            <button @click="clickMoreComment">查看更多</button>
        </div>
        <!--  -->
        <b-modal id="modal-scrollable" size="lg" scrollable centered  title="评论">
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <b-button size="sm" variant="×" @click="close()">
                    返回
                </b-button>
            </template>
            <MoreReComment :ReCommentId="moreReCommentId"></MoreReComment>
            <template #modal-footer="{hide}">
           
                <!-- Button with custom close trigger value -->
                <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
                    关闭
                </b-button>
                </template>
        </b-modal>
        <!--  -->
        <b-modal id="addReportModal2"  centered  scrollable hide-footer hide-header>
            <div class="addReport_box">
                <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入举报内容"
                v-model="reportValue">
                </el-input>
                <div class="addReport_btn">
                    <el-button type="primary" @click="addReportRecord()">提交</el-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
import TextareaComponent from '@/components/Post/TextareaComponent.vue';
import MoreReComment from '@/components/Post/MoreReComment.vue';
 export default {
      name: 'PostComment',
      components: {
        TextareaComponent,
        MoreReComment
    },
      data(){
         return{
            page:1,
            total:5,
            lastRows:0,
            postId:this.$route.query.postId,
            commentInfoList:[],
            replyCommentId:0,
            replyId:0,
            fatherCommentId:"",
            moreReCommentId:0 //--------->评论中的加载更多时使用
            ,reportCid:null
            ,reportValue:null,
            reportPid:this.$route.query.postId,
         }
        },methods:{
            showModal(reportCid){
                this.reportCid=reportCid;
                let token =  localStorage.getItem("token")
                if(token){
                    this.$bvModal.show('addReportModal2')
                }else{
                    this.$message.info("请先登录！")
                }
            },
            // id--->这条评论的id userId ----->被评论用户的 faId--->父级评论的id
            showTextarea(e,id,userId,faId){
                  console.log(id);
                 // this.$refs.reComponentBox.$el.style.brckground = 'pink';
                  //this.$refs.reComponentBox.$el.style.display = 'none'
                  console.log(this.$refs[id])
                  this.replyCommentId=id
                  this.userId=userId
                  this.fatherCommentId=faId
                  console.log( this.replyCommentId+"-------父----"+this.userId);
                  var x = document.getElementsByClassName("reComponentBox");
                        var i;
                        for (i = 0; i < x.length; i++) {
                            x[i].style.display="none"
                        }

                  ///document.getElementsByClassName("reComponentBox").setAtribute('display',"none")
                
                  var element = document.getElementById(id);
            
                  element.style.display="block"
                    // let a = event.target.value;
                    // console.log(event.target.value);
                 
            },
            loadComment(){
                console.log("评论加载------》");
                axios({
                  method: "post",//指定请求方式
                  url: "/web/comment-info/queryCommentByPage",
                  data: {
                    page:this.page,
                    total:this.total,
                    postId:this.postId,
                    }
                    })
                    .then((res)=>{
                    if(res.data.code==200){
                        //let json = JSON.stringify(res.data.data);//tostring

                       
                        if(res.data.data.commentInfoList.length==0){
                            //alert()
                           // document.getElementById("comment_message").style.display="block"
                            this.$refs.comment_message.style.display="block"
                        }else{
                            this.$refs.comment_message.style.display="none"
                            let resItems=res.data.data.commentInfoList;
                            let rows=res.data.data.rows;
                            this.lastRows=rows-(this.page*this.total);
                            //console.log(this.lastRows+"----------------")
                            // let set = new Set(this.commentInfoList);
                            // this.commentInfoList=Array.from(set)    
                           if(this.page==1){
                            this.commentInfoList=resItems
                           }else{
                            this.commentInfoList=this.commentInfoList.concat(resItems)
                           }
                           
                            
                            
                           
                        }
                        
                        //console.log(json);
                    }else{
                        this.$message.error('加载评论失败！');
                    }
                    })
                    .catch(function(err){
                    //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                    console.log(err);
                
                    })  
            },
            clickMoreComment(){
                //最下面的加载更多
                this.page=Number(this.page)+1;
                this.loadComment();
            }
            ,loadMoreReComment(commentId){
                //评论中的加载更多
                this.moreReCommentId=commentId;//
            }
            ,addReportRecord(){
                axios({
                    method: "post",//指定请求方式
                    url: "/web/reportRecords/insertReportRecords",
                    data: {
                        reportType:2,
                        token:localStorage.getItem("token"),
                        reportValue:this.reportValue,
                        reportPid:this.reportPid,
                        reportCid:this.reportCid
                        }
                    })
                    .then((res)=>{
                        if(res.data.code==200){
                            this.$message.success('提交成功！');
                            this.$bvModal.hide('addReportModal2')
                        }else{
                            this.$message.error('举报失败！');
                        }
                    })
                    .catch(function(err){
                    //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                    console.log(err);
                
                    }) 

            }
           

        },mounted(){
            this.loadComment();
        }
    }
</script>
<!-- <style src="@/assets/css/comment.css" scoped></style> -->
<style scoped>
.comment_value{
    background-color: #fff;
    box-shadow: 5px 5px 5px #999;
    padding: 4rem;
    width: 100%;
    display: flex;
    min-height: 10rem;
    line-height: 30px;
    color: #666;
    letter-spacing: 1px;
    margin-top: 2rem;
    flex-wrap: wrap;
}
.comment_value_item{
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
}
.comment_user_photo{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}
.comment_bottom_box{
    /* border:1px solid #409EFF; */
    padding-left: 1rem;
    width: 100%;
}
.comment_bottom_msg{
    flex: auto;
    border-bottom: 1px solid #efefef;
}
.comment_bottom_box_header{
    /* border:1px solid #409EFF; */
    display: flex;
    /* font-size: 20px; */
    font-weight: 600;
    height: 3rem;
    align-items: center;
}
.comment_bottom_box_value{
    /* border:1px solid #409EFF; */
    color: #777;
    font-size: 15px;
    min-height: 3rem;
}
.comment_bottom_box_end{
    /* border:1px solid #409EFF; */
    display: flex;
    justify-content: flex-end;
}
.comment_bottom_box_end_i{
    padding: 0 0.2rem;
    color: #888;
    font-size: 0.9rem;
}
.comment_reply_box{
    /* border:1px solid #409EFF; */
    background-color: #f7f8fc;
    padding: 1rem;
    width: 100%;
    border-bottom: 1px solid #efefef;
}
.comment_reply_header{
   
    height: 2rem;
    display: flex;
    align-items: center;
}
.comment_reply_header img{
    height: 100%;
    border-radius: 50%;
}
.comment_reply_name{
    padding-left: 10px;
    font-size: 15px;
}
.reply_repor{
    float: right;
    color: #999;
    cursor: pointer;
    font-size: 15px;
    
}
.reply_repor:hover{
    color: #409EFF;
}
.comment_reply_value{
    padding: 10px 43px;
    font-size: 14px;
    line-height: 22px;
}
.comment_reply_name a{
    list-style: none;
    color: #409EFF;
    text-decoration: none;
}
.comment_reply_end{
    justify-content: flex-end;
    display: flex;
    color: #888;
    font-size: 0.9rem;
}
.comment_reply_end_i{
    padding-right: 5px;
}
.textarea{
    width: 100%;
    height: 10rem;
    border: 1px solid #ebebeb;
    border-radius: 1rem;
    padding: 5px;
    color: #777;
    outline-color: #409EFF;
}
.sendReplyMsgbtn{
    background-color: #409eef;
    color: #fff;
    border: 0;
    width: 70px;
}
.sendReplyBottom{
    display: flex;
    /* justify-items: flex-end; */
    /* width: 100%; */
    justify-content: flex-end;
    margin-bottom: 1rem;
}
.comment_cursor{
    cursor: pointer;
}
.comment_cursor:hover{
    color: #409EFF;
}
.load_more_r{
    text-align: center;
    background-color: #f7f8fc;
    color: #999;
}
.load_more_comment{
    display: flex;
    justify-content: center;
    width: 100%;   
}
.load_more_comment button{
    border: 0px;
    background-color: #fff;
    width: 50%;
    height: 4rem;
    color: #888;
}
.comment_user_photo_box{
    padding-top: 0.3rem;
}
.addReport_box{
    padding: 1rem 0rem;
    text-align: center;
  }
  .addReport_btn{
    padding-top: 1rem;
  }

@media all and (orientation : portrait) {
    .comment_value{
        padding: 1rem;
    }
    .comment_reply_value{
        padding: 1rem;
    }

    /* .add_post_commet_head{
        font-size: 3rem;
    }
    .comment_user_photo{
        width: 10rem;
        height: 10rem;
    }
    .comment_bottom_box_header{
        font-size: 4rem;
       
        height: 10rem;
    }
    .comment_bottom_box_value{
        font-size: 4rem;
    }
    .comment_bottom_msg{
        font-size: 3rem;
    }
    .comment_reply_header{
        height: 7rem;
    }
    .comment_reply_name{
        font-size: 3rem;
    }
    .reply_repor
    {
        font-size: 3rem;
    }
    .comment_reply_value{
        padding: 1rem 7rem;
        font-size: 3rem;
        line-height: 4.2rem;
    }
    .comment_reply_end{
        font-size: 2.2rem;
    }
    .comment_bottom_box_end_i{
        font-size: 2.9rem;
    } */

}
</style>