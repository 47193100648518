<template>
    <div>
        <div>
         

        </div>
    </div>
</template>
<script>

export default {
    name:'AdminUpdateComment',

    props: ['rowData'],
    data() {
      return {
      }
    },
    methods: {
       
            
        },
        
}
</script>
<style scoped>



</style>