<template>
    <div> 
        <NavBar></NavBar>
        <div class="top_margin">

        </div>
        <b-container>
            <el-row>
                <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                    <div>
                        <div class="strategy_title">{{officiaData.officialTitle}}</div>
                        <div style="display: flex;justify-content: space-between;">
                            <div>
                               <!-- <span class="type_label"> 所属分类：{{dictLabel}} </span> -->
                            </div>
                            <div>
                                <span class="time_span">{{officiaData.officialTime |dateFormat }}</span> 
                            </div>
                        </div>
                        <hr/>
                    </div>
                    <div class="content_text" v-html="officiaData.officialContent"></div>
                </el-col>
                <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                    <div class="r_box">
                        <div class="r_title">
                            最新公告
                            
                        </div>
                        <div v-for="item in officiaList" :key="item.officialId" class="item_box " @click="goToUrl(item.officialId)"  >
                            <div class="item_head" :style="`background-image: url(${item.officialImgsList[0]});`">

                            </div>
                            <div class="item_content">
                                <div class="item_title">{{item.officialTitle}}</div>
                                <div class="item_text">{{item.officialText}}</div>
                                <div class="item_time">{{item.officialTime |dateFormat}}</div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </b-container>
        <PageBottom></PageBottom>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import PageBottom from '@/components/PageBottom.vue';
export default {
    name: 'InfoShowView',
    components:{
        NavBar,
    PageBottom,
    },
    data() {
        return {
            dictLabel:null,
            officiaList:[],
            officiaData:{
                officialContent:"<h1>加载失败</h1>"
            },
            OfficialInfo:{
                officialId:this.$route.query.officialId,
               
            },
            official:{
                page:1,
                total:3 
            }
        }
    },methods:{
        goToUrl(officialId){
            //this.$router.push({path:'StrategyShow',query:{'strategyId':strategyId}})
            window.open('/#/InfoShowView/?officialId='+officialId, '_blank');
            
        },
        loadOfficialInfo(){
            this.$http.post("/web/official/selectOfficialInfoById",this.OfficialInfo)
                .then((res) => {
                if (res.data.code == 200) {
                    this.officiaData=res.data.data
                }
                else {
                    this.$message.error(res.data.message);
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        },
        //加载最新
        loadOfficialList(){
            this.$http.post("/web/official/selectOfficialInfoByPage",this.official)
                .then((res) => {
                if (res.data.code == 200) {
                    let list = res.data.data.officialInfoList
                    this.officiaList=this.officiaList.concat(list)
                }
                else {
                    this.$message.error(res.data.message);
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        }
        
        
    },mounted(){
        this.loadOfficialInfo();
        this.loadOfficialList();
    }
}
</script>
<style scoped>
.top_margin{
    margin-top: 4rem;
}
.strategy_title{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    color: #555;
}
.type_label{
    font-size: 0.9rem;
    color: #777;
}
.time_span{
    color: #c63000;
    font-size: 0.9rem;
    
    padding-right: 1rem;

}
.content_text{
    border: 1px solid #dfdfdf;
    padding: 0.5rem;
}
.content_text >>> img{
  max-width: 100%;
  height: auto;
 }
 .r_box{
    padding: 2rem 1rem;
 }
 .r_title{
    font-weight: 600;
    margin: 1rem 0px;
    border-left: 5px solid #c63000;
 }
.item_box{
    border: 1px solid #dfdfdf;
    margin-bottom: 2rem;
    margin-right: 1rem;
    transition: 0.8s;
    cursor: pointer;
}
.item_box:hover{
        box-shadow: 5px 5px 5px #dfdfdf;
    }
.item_head{
    width: 100%;
    aspect-ratio:2/1;
    background-position: center;
    background-size: cover;
}
.item_content{
    padding: 1rem;
}
.item_title{
    font-size: 1rem;
    font-weight: 600;
    
}
.item_text{
    font-size: 0.8rem;
    color: #777;
    padding: 1rem 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 6rem;
  
}
.item_time{
    font-size: 0.7rem;
    color: #c63000;
}
@media all and (orientation : portrait) {
    /* .top_margin{
        margin-top: 10rem;
    }
    .strategy_title{
        font-size: 7rem;
    }
    .type_label{
        font-size: 5rem;
    }
    .time_span{
        font-size: 5rem;
    }
    .content_text{
        font-size: 5rem;
        color: #555;
    }
    .r_box{
        padding: 12rem 2rem;
    }
    .r_title{
        font-size: 7rem;
        margin: 2rem 0px;
    }
    .item_title{
        font-size: 4.5rem;
    }
    .item_text{
        font-size: 4rem;
    }
    .item_time{
        font-size: 3.5rem;
    } */
}
</style>