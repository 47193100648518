<template>
    <div>
        <div>
            <div class="cus_lable">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="ID">
                        <el-input v-model="userId" placeholder="ID"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="userPhone" placeholder="手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="账号">
                        <el-input v-model="userAccount" placeholder="账号"></el-input>
                    </el-form-item>
                    <el-form-item label="名称">
                        <el-input v-model="userName" placeholder="名称"></el-input>
                    </el-form-item>

                    <el-form-item label="类型">
                        <el-select v-model="userType" placeholder="类型">
                        <el-option label="不限" value="0"></el-option>
                        <el-option label="普通" value="1"></el-option>
                        <el-option label="其他" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="userState" placeholder="状态">
                        <el-option label="不限" value="0"></el-option>
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="异常" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                  
                    <el-form-item label="创建时间">
                        <el-date-picker
                            v-model="time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @keydown.enter="loadUserInfo" @click="loadUserInfo">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!--  -->
        <div>
            <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="userId"
                    label="用户ID"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="userPhoto"
                    label="头像"
                    width="80">
                    <template slot-scope="scope">
                        <el-image 
                             class="table_img"
                            :src="scope.row.userPhoto" 
                           >
                        </el-image>    
                    </template>
                </el-table-column>
                <el-table-column
                    prop="userAccount"
                    label="账号"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="userName"
                    label="用户名"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="userPhone"
                    label="手机号">
                </el-table-column>
                <el-table-column
                    prop="userSignature"
                    label="签名"
                    width="280">
                    <template slot-scope="scope">
                      <div class="table_cell">
                        {{scope.row.userSignature}}
                      </div>
                    </template>

                </el-table-column>
                <el-table-column
                    prop="userMailbox"
                    label="邮箱"
                    width="280">
                    <template slot-scope="scope">
                      <div class="table_cell">
                        {{scope.row.userMailbox}}
                      </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="userRemark"
                    label="备注"
                    width="280">
                    <template slot-scope="scope">
                      <div class="table_cell">
                        {{scope.row.userRemark}}
                      </div>
                    </template>
                </el-table-column>
                
                <el-table-column
                    prop="userType"
                    label="类型">
                    <template slot-scope="scope">
                            <el-tag v-if="scope.row.userType==1" >普通</el-tag>
                            <el-tag v-else type="success">其他</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="userState"
                    label="状态">
                    <template slot-scope="scope">
                            <el-tag v-if="scope.row.userState==1" >正常</el-tag>
                
                            <el-tag v-else type="success">其他</el-tag>
                        </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="创建时间">
                    <template slot-scope="scope">
                            {{scope.row.createTime| dateFormat}}
                        </template>

                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button type="text" @click="showModeal(scope.row)" size="small">编辑</el-button>
                </template>
                </el-table-column>
            </el-table>
        </div>
        <!--  -->
        <div class="table_footer_page_box">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="rows">
            </el-pagination>
        </div>
        <!--  -->
        <b-modal id="userModal" size="xl" centered  scrollable title="编辑" hide-footer hide-header>
           <AdminUpdateUser @customEvent="refLoad" :rowData='this.rowData'></AdminUpdateUser>
        </b-modal>
    </div>
</template>
<script>
import AdminUpdateUser from './AdminUpdateUser.vue';

export default {
    name: 'AdminUserInfo',
    components:{
        AdminUpdateUser
    },
    data() {
        return {
            page: 1,
            total: 10,
            rows: 0,
            userId: null,
            userAccount: null,
            userPhone: null,
            userName: null,
            userType: null,
            userState: null,
            time: [new Date(2023, 10, 10, 10, 10), new Date(2024, 11, 11, 10, 10)],
            tableData: null,
            rowData: null,
            
        };
    },
    methods: {
        loadUserInfo() {
            this.$http({
                method: "post",
                url: "/web/user/selectUserInfoByPage",
                data: {
                    page: this.page,
                    total: this.total,
                    userId: this.userId,
                    userAccount: this.userAccount,
                    userPhone: this.userPhone,
                    userName: this.userName,
                    userType: this.userType,
                    userState: this.userState,
                    createTime: this.time[0],
                    endTime: this.time[1],
                }
            })
                .then((res) => {
                if (res.data.code == 200) {
                    let resItems = res.data.data.userInfoList;
                    this.tableData = resItems;
                    this.rows = res.data.data.rows;
                }
                else {
                    this.$message.error('加载失败！');
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = Number(val);
            this.loadUserInfo();
        },
        showModeal(rowData) {
            this.$bvModal.show('userModal');
            this.rowData = rowData;
        },
        refLoad() {
            this.$bvModal.hide('userModal');
            this.loadUserInfo();
        }
    }, mounted() {
        this.loadUserInfo();
    },
   
}
</script>
<style scoped>


</style>