<template>
    <div class="login_bg">
      <!-- <img alt="Vue logo" src="../assets/logo.png">
      <HelloWorld msg="Welcome to Your Vue.js App"/> -->
     
      <NavBar></NavBar>
        <LoginPage></LoginPage>
      <PageBottom/>
      
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import PageBottom from '@/components/PageBottom.vue';
  import LoginPage from '@/components/login/LoginPage.vue';
  import NavBar from '@/components/NavBar.vue'
  export default {
    name: 'LoginView',
    components: {
        NavBar,
        LoginPage,
        PageBottom
  }
  }
  </script>
  <style scoped>
  .login_bg{
    background-color: #17171A;
    width: 100%;
    height: 100%;
   
    
    

  }
  </style>
  