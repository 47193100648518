<template>
    <div>
        <div v-for="comment in commentInfoList" :key="comment.commentId" class="re_comment_item">
            <div class="re_comment_headr">
                <img :src="comment.userInfo.userPhoto">
            </div>
            <div class="re_comment_content">
                <div class="re_comment_name">
                    {{comment.userInfo.userName}}<span class="re_comment_span">回复</span>
                    {{comment.reUserInfo.userName}}
                </div>
                <div class="re_comment_value">
                   {{comment.commentContent}}
                </div>
                <div class="re_comment_bottom">
                    <span class="re_comment_bottom_span">
                        {{comment.commentTime| dateFormat}}
                    </span>
                    <span class="re_comment_bottom_span" @click="showTextarea($event,comment.commentId,comment.userInfo.userId)">
                        回复
                    </span>
                    <span class="re_comment_bottom_span">
                        举报
                    </span>
                   
                </div>
                <div :id="comment.commentId*-1" class="reComponentBox" style="display: none;">
                    <TextareaComponent></TextareaComponent>
                </div>
            </div>
        </div>
        <div class="reCommendListEnd">

        </div>
    </div>
</template>
<script>
import axios from "axios";
import TextareaComponent from '@/components/Post/TextareaComponent.vue';
export default {
    name: 'MoreReComment',
    props: {
     ReCommentId: {
      type: Number,
      default: 0,
    },
  },
    components: {
        TextareaComponent,
    },
    data(){
       return{
            page:1,
            total:10,
            postId:this.$route.query.postId,
            commentInfoList:[],
            replyCommentId:0,
            fatherCommentId:0,
            userId:0
        }      
    }
    ,methods:{
        // id--->这条评论的id userId ----->被评论用户的 faId--->父级评论的id
        showTextarea(e,id,userId){
                //console.log(id);
                // this.$refs.reComponentBox.$el.style.brckground = 'pink';
                //this.$refs.reComponentBox.$el.style.display = 'none'
                //console.log(this.$refs[id])
                this.replyCommentId=id
                this.userId=userId
                this.fatherCommentId=this.ReCommentId//这里直接获取 父类传的id
                console.log( this.replyCommentId+"-------父----"+this.userId);
                var x = document.getElementsByClassName("reComponentBox");
                    var i;
                    for (i = 0; i < x.length; i++) {
                        x[i].style.display="none"
                    }

                ///document.getElementsByClassName("reComponentBox").setAtribute('display',"none")
            
                var element = document.getElementById(id*-1);
        
                element.style.display="block"
                // let a = event.target.value;
                // console.log(event.target.value);
                
        },
        loadComment(){
        axios({
            method: "post",//指定请求方式
            url: "/web/comment-info/queryReCommentByPage",
            data: {
                page:this.page,
                total:this.total,
                postId:this.postId,
                fatherCommentId:this.ReCommentId,
                rows:1
            }
            })
            .then((res)=>{
            if(res.data.code==200){
                //let json = JSON.stringify(res.data.data);//tostring

                
                if(res.data.data.length<1){
                    //alert()
                    document.getElementsByClassName("reCommendListEnd")[0].innerHTML ="没了！"
                }else{
                    //this.commentInfoList=res.data.data;
                    let resItems=res.data.data.reCommentList;
                  
                    this.commentInfoList=this.commentInfoList.concat(resItems)
                    console.log("-commentInfoList--------->"+this.commentInfoList)
                    this.rows=res.data.data.total;
                }
                
                //console.log(json);
            }else{
                this.$message.error('加载评论失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            })  
        }
    }
    ,mounted(){
         //let id =  this.$parent.moreReCommentId;
            //alert(this.ReCommentId)
            this. loadComment();


        }
}
</script>
<style scoped>
.re_comment_item{
    display: flex;
    width: 100%;
}
.re_comment_headr img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.re_comment_item{
    padding: 1rem;
    /* border-bottom: 1px solid #999; */
    background-color: #f7f8fc;
    margin: 1rem 0;
    border-radius: 1rem;
    cursor: pointer;
}
.re_comment_content{
    padding-left: 10px;
    width: 100%;
    
}
.re_comment_name{
    font-size: 0.7rem;
    font-weight: 600;
    color: #666;
    height: 1.4rem;
    line-height: 1.4rem;
}
.re_comment_value{
    padding: 5px;
    color: #777;
    font-size: 13px;
}
.re_comment_bottom{
    display: flex;
    justify-content: flex-end;
    font-size: 0.8rem;
}
.re_comment_span{
    padding: 5px;
}
.re_comment_bottom_span{
    padding: 1px 10px;
    color: #999;
}
@media all and (orientation : portrait) {
.re_comment_item{
    padding:0;
    margin: 0.3rem 0;
}
.re_comment_headr img{
    width: 2rem;
    height: 2rem;
}

}
</style>