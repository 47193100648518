<template>
    <div>
        <div class="to_box">
            <div class="fast_box">
              <button @click="
       showModal2" class="fast_send_post">
                立即发帖
              </button>
          </div>
          <div>
            <button class="to_top_btn" @click="toTop">
                <i class="el-icon-arrow-up"></i>
            </button>
          </div>
        </div>
        <!--  -->
        <b-modal id="modal2"  scrollable  centered  hide-footer>
          <template #modal-header="{ close }">
            <div class="modal_header">
              <b-button size="sm" variant="outline-secondary" @click="close()">
                ×
               </b-button>
            </div>
          </template>
          <SendPost></SendPost>
        </b-modal>
    </div>
</template>
  
<script>
import SendPost from '../user/SendPost.vue';
  export default {
    name: 'ToTop',
    components: { SendPost },
    data() {
      return {
        isActive: false,
        token:localStorage.getItem("token")
      }
    },
    methods: {
       toTop() {
          document.documentElement.scrollTop = 0;

        },
       showModal2() {
                if(this.token!=null){
                  this.$bvModal.show("modal2");
                }else{
                  this.$message.info("请先登录！");
                }
                
            }

    }
  }
  </script>
  <style scoped>
.to_box{
  position: fixed;
  display: flex;
  right: 5rem;
  bottom: 10rem;
  flex-wrap: wrap;
  justify-content: center;
}
.to_top_btn {
 
  width: 5rem;
  height: 5rem;
  bottom: 5rem;
  right: 2rem;
  box-shadow: 5px 5px 5px #333;
  border-radius: 50%;
  border: 0px;
}
.fast_send_post{
  border: 0px;
  
  color: #fff;
  background-color: #409eef;
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  font-size: 2.7rem;
  box-shadow: 5px 5px 5px #333;
}
.fast_box{
  width: 100%;
  text-align: center;
  display: none;
  margin: 3rem 0rem;
  
}
.modal_header{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media all and (orientation : portrait) {
  .to_box{
    right: 0rem;
  }
  .fast_box{
    display: block;
    margin:1rem 0 ;
   
  }
  .fast_send_post{
    width: 5rem;
    height: 5rem;
    font-size: 1rem;
  }

  /* .modal_header{
    height: 10rem;
  }
  .modal_header button{
    font-size: 4rem;
    padding: 0 4rem;
    border-color: #999;
  }
 
  .to_box{
    bottom: 80rem;
  }
  .fast_send_post button{
    height: 10rem;
    font-size: 3rem;

  }
  .to_top_btn{
    width: 15rem;
    height: 15rem;
  }
 .el-icon-arrow-up{
    font-size: 3rem;
 }
 #modal2{
   height: 10rem;
 } */

}
 
  </style>
  