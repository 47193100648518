import { render, staticRenderFns } from "./ToTop.vue?vue&type=template&id=63c5b5c1&scoped=true"
import script from "./ToTop.vue?vue&type=script&lang=js"
export * from "./ToTop.vue?vue&type=script&lang=js"
import style0 from "./ToTop.vue?vue&type=style&index=0&id=63c5b5c1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c5b5c1",
  null
  
)

export default component.exports