<template>
    <div>
        <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        router
        @open="handleOpen"
        @select="handleSelect"
        @close="handleClose">
            <el-menu-item index="AdminHome">
                <i class="el-icon-s-home"></i>
                <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="AdminUserInfo">
                <i class="el-icon-user"></i>
                <span slot="title">用户管理</span>
            </el-menu-item>
            <el-menu-item index="AdminPost">
                <i class="el-icon-s-order"></i>
                <span slot="title">帖子管理</span>
            </el-menu-item>
            <el-menu-item index="AdminComment">
                <i class="el-icon-chat-line-round"></i>
                <span slot="title">评论管理</span>
            </el-menu-item>
            <el-menu-item index="AdminPage">
                <i class="el-icon-tickets"></i>  
                <span slot="title">页面管理</span>
            </el-menu-item>

            <el-submenu index="AnnounceList">
              <template slot="title">
                <i class="el-icon-postcard"></i>
                <span>公告管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="AnnounceList">资讯列表</el-menu-item>
                <el-menu-item index="AdminAddOfficial">添加资讯</el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-menu-item index="SysDict">
                <i class="el-icon-collection"></i>  
                <span slot="title">字典管理</span>
            </el-menu-item>
           
            <el-submenu index="Strategy">
              <template slot="title">
                <i class="el-icon-coordinate"></i>
                <span>游戏资料</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="Strategy">资讯列表</el-menu-item>
                <el-menu-item index="addStrategy">发布资讯</el-menu-item>
                <el-menu-item index="GameProp">游戏道具(旧)</el-menu-item>
                <el-menu-item index="EquipCompound">装备合成(旧)</el-menu-item>
                <el-menu-item index="CompoundFormula">装备配方(新)</el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-menu-item index="AdminUser">
                <i class="el-icon-s-custom"></i>  
                <span slot="title">管理员管理</span>
            </el-menu-item>
            <el-menu-item index="AdminVisit">
                <i class="el-icon-view"></i>  
                <span slot="title">访问记录</span>
            </el-menu-item>
            <el-menu-item index="ReportRecords">
                <i class="el-icon-question"></i>  
                <span slot="title">举报记录</span>
            </el-menu-item>
            <el-menu-item index="Blacklist">
                <i class="el-icon-question"></i>  
                <span slot="title">黑名单</span>
            </el-menu-item>
            <el-menu-item index="AdminSensitiveWords">
                <i class="el-icon-warning-outline"></i>  
                <span slot="title">敏感词汇</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>
<script>
    export default {
    name:'AdminLeftNav',
    data() {
      return {
        activeIndex: '1',
        activeIndex2: '1'
      };
    },
    methods: {
      handleSelect(key) {
        console.log("left------->"+key);
          this.$emit('child-event',key);
         
      },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    }
  }
</script>
<style>

</style>