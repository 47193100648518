<template>
    <div>
    <div>
      <NavBar/>
    </div>
    <div class="forum_bg">
        <div style="width: 100%;">
            <div style="width: ;">

            </div>
        </div>
        <div style="background-color: #fff;">
          <b-container>
            <el-row   style="align-items: center;" class="top_row">
              <el-col :xs="24"  :md="12" :lg="12" :xl="12" >
                  <div class="top_left">
                    <div class="top_left_box">
                    <span :class="{activeColor:1==orderByType}" @click="chooseOrderByType(1)">最热</span>
                    <span :class="{activeColor:2==orderByType}" @click="chooseOrderByType(2)">最新</span>
                    </div>
                </div>
              </el-col>
              <el-col :xs="24"  :md="12" :lg="12" :xl="12"><div class="top_right">
                  <div class="top_right_box">
                    <span :class="{activeColor2:0==postType}" @click="choosePostType(0)">
                      所有
                      </span>
                    <span :class="{activeColor2:1==postType}" @click="choosePostType(1)">
                       闲聊
                      </span>
                      <span :class="{activeColor2:2==postType}" @click="choosePostType(2)">
                      BUG反馈
                      </span>
                      <span :class="{activeColor2:3==postType}" @click="choosePostType(3)">
                      活动分享
                    </span>
                  </div>
                </div>
              </el-col>
            </el-row>
        </b-container>
        </div>
        <b-container >
          <b-row>
            <b-col   md="9" xs="12">
              <ForumHeader></ForumHeader>
              <content-list v-if="refresh" :oType="orderByType" :pType="postType"></content-list>
            </b-col>
            <b-col   md="3" xs="0" class="main_box_right">
              <OffInfo></OffInfo>
              <HotActivity></HotActivity>
              <FastSendPost></FastSendPost>
            </b-col>
          </b-row>
        </b-container>
        <PageBottom></PageBottom>
        <ToTop></ToTop>
    </div>
  </div> 
  </template>
  
  <script>
  // @ is an alias to /src
  import NavBar from '@/components/NavBar.vue'  
import PageBottom from '@/components/PageBottom.vue';
import ContentList from '@/components/Forum/ContentList.vue';
import ToTop from '@/components/Forum/ToTop.vue'; 
import OffInfo from '@/components/Forum/OfficialInformation.vue';
import HotActivity from '@/components/Forum/HotActivity.vue';
import FastSendPost from '@/components/Forum/FastSendPost.vue';
import ForumHeader from '@/components/Forum/ForumHeader.vue';
  export default {
    name: 'ForumView',
    components: {
         NavBar,
        PageBottom,
        ContentList,
        OffInfo,
        HotActivity,
        FastSendPost,
        ForumHeader,
        ToTop
  },data(){
    return{
      refresh: true,
      orderByType:1,
      postType:0,
    }
  },methods:{
    
    refreshComp(){
            //console.log("addComent");
            this.refresh=false
            this.$nextTick(()=>{
                //alert("刷新")
                this.refresh = true
            })
        },
    
      chooseOrderByType(index) {
        this.orderByType = index;
        this.refreshComp()
      },
      choosePostType(index) {
        this.postType = index;
        this.refreshComp()
      },
  }
  }
  </script>
  <style scoped>
  .activeColor{
    color: #409EFF;
  }
  .activeColor2{
    color: #f29921;
  }
 .forum_bg{
    min-height: 80rem;
    width: 100%;
    background-color: #e4e4e4;
    /* padding-top: 6rem; */
   
 }
 .top{
    background-color: #fff;
    /* height: 5rem; */
    /* position: fixed; */
    width: 100%;
    /* z-index: 998; */
 }
 .top_left{
     margin-top: 1rem;
    font-size: 1rem;
    font-weight: 900;
 }
 .top_left_box{
    padding: 5px;
 }
 .top_left_box span{
  padding: 0 10px;
  cursor: pointer;
 }
 .top_right{
  margin-top: 1rem;
    
 }
 .top_right_box{
    /* font-size: 20px; */
    height: 3rem;
    line-height: 3rem;
 }
 .top_right_box span{
  
  /* background-color: #409EFF; */
  
  margin: 1rem;
  cursor: pointer;
 }
 .main_box{
  display: flex;
  justify-content: center;
 }
 .main_box_list{
   width: 50rem;
   min-width: 600px;
 }
 @media all and (orientation : portrait) {
  .main_box_right{
    display: none;
    min-width: auto;
   }
   .top_row {
    flex-wrap:wrap-reverse;
   }
 }

 /* @media all and (orientation : portrait) {

.el-header{
  height: 1rem;
}
  .top{
    height: 11rem;
  }
   .main_box_list{
    width: 100%;
    min-width: auto;
   }
   .main_box_right{
    display: none;
    min-width: auto;
   }
   .top_left{
    font-size: 4rem;
   }
   .el-header{
    height: 0px;
   }
   .top_right{
    font-size: 3rem;
   }
} */
  </style>
  