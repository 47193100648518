<template>
    <div>
    <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      @select="handleSelect">
      <el-menu-item index="1">
        <i class="el-icon-user"></i>
          <span slot="title">基本资料</span>
      </el-menu-item>
      <el-menu-item index="2">
        <i class="el-icon-unlock"></i>
          <span slot="title">密码修改</span>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-tickets"></i>
          <span slot="title">发帖</span>
      </el-menu-item>
      <el-menu-item index="4">
        <i class="el-icon-postcard"></i>
          <span slot="title">发帖记录</span>
      </el-menu-item>
    </el-menu>

    </div>
</template>
<script>
//import UserInfo from '@/components/user/UserInfo.vue';

export default {
    name: 'LeftMenu'
    ,data() {
        return{
          isCollapse: false
        }
    },
    methods: {
      choose(){
       
       }
      ,handleSelect(key) {
          this.$emit('child-event',key);
         
      }
    }
}

</script>