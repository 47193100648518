<template>
    <div class="fast_box">
        <button @click="showModal">快速发帖</button>
        <b-modal id="modal"  size="xl" scrollable  centered  hide-footer>
          <template #modal-header="{ close }">
            <span></span>
            
            <b-button size="sm" variant="outline-secondary" @click="close()">
                ×
           </b-button>
          </template>
          <SendPost></SendPost>
        </b-modal>
    </div>
  </template>
  
  <script>
import SendPost from '../user/SendPost.vue';


  export default {
    name: 'FastSendPost',
    data() {
        return {
          token:localStorage.getItem("token")
        };
    },
    methods: {
        showModal() {
            if(this.token!=null){
              this.$bvModal.show("modal");
            }else{
              this.$message.info("请先登录！");
            }
            
        }
    },
    components: { SendPost }
}
  </script>
  <style scoped>
  .fast_box{
    min-height: 20rem;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
  .fast_box button{
    border: 0px;
    width: 90%;
    height: 4rem;
    background-color: #409eef;
    color: #fff;
    box-shadow: 2px 2px 2px #999;
    border-radius: 1rem;
    transition: 1s;
  }
  .fast_box button:hover{
    width: 100%;
    box-shadow: 5px 5px 5px #999;
  }
  
 
  </style>
  