<template>
    <div class="post_bg">
        <NavBar></NavBar>
        <div>
            <div>

            </div>
            <div>
                <div class="p_main_box">
                    <div class="p_main_content">
                        <div class="comment_box">
                           
                        </div>
                    </div>
                    <div class="p_main_box_right">
                        <!-- <PostUserCard></PostUserCard> -->
                        
                    </div>
                </div>
            </div>
            <b-container >
                <b-row>
                    <b-col md="9" sm="12">
                        <PostContent></PostContent>
                            <AddPostComment></AddPostComment>
                            <!-- -----------------324 -->
                            <PostComment ref="comments"></PostComment>
                    </b-col>
                    <b-col md="3" sm="0" class="p_main_box_right">
                        <PostUserCard></PostUserCard>
                        <OffInfo></OffInfo>
                        <HotActivity></HotActivity>
                        <FastSendPost></FastSendPost>
                    </b-col>
                </b-row>

            </b-container>
            <div>
                <!-- <PageBottom></PageBottom> -->
                <ToTop></ToTop>
             </div>
        </div>
<!-- -->
<PageBottom></PageBottom>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
    import PageBottom from '@/components/PageBottom.vue';
    import ToTop from '@/components/Forum/ToTop.vue'; 
    import OffInfo from '@/components/Forum/OfficialInformation.vue';
    import HotActivity from '@/components/Forum/HotActivity.vue';
    import PostContent from '@/components/Post/PostContent.vue';
    import PostComment from '@/components/Post/PostComment.vue';
    // import PostUserShow from '@/components/Post/PostUserShow.vue';
     import PostUserCard from '@/components/Post/PostUserCard.vue';
    import AddPostComment from '@/components/Post/AddPostComment.vue';
    import FastSendPost from '@/components/Forum/FastSendPost.vue';
    import NavBar from '@/components/NavBar.vue';
    export default {
        name: 'PostContentView',
        components: {
            PageBottom,
            NavBar,
            OffInfo,
            HotActivity,
            PostContent,
            PostComment,
            // PostUserCard,
            AddPostComment,
            FastSendPost,
            PostUserCard,
            ToTop
    },data(){
        return{
             refresh: true
        }
    },provide() {
        return {
            fatherMethod: this.refreshComp,
        };
     } ,methods:{
        refreshComp(){
           this.$refs.comments.loadComment();
        }

    }
  }
  </script>
  
  <style scoped>
  .active{
    color: #0b87ac;
  }
  .comment_box{
    padding: 1rem;
 }
 .post_bg{
    background-color: #e4e4e4;
 }
.p_main_box{
    display: flex;
    justify-content: center;
}
.p_main_content{
    width: 55rem;
}

@media all and (orientation : portrait) {
     /* .p_main_content{
        width: 100%;
    } */
    .p_main_box_right{
        display: none;
    } 
    

}
  </style>
  