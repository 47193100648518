<template>
    <div class="show_video_bg" :style="`background-image: url(${url})`">
        <div class="show_video_f">
            <div>
                <h1> 
                    <span v-b-modal.modal-1 class="i_box"><i class="el-icon-caret-right"></i></span>
                </h1>
            </div>
            <div>
               
            </div>
            <b-modal id="modal-1" size="lg" centered hide-header hide-footer>
                <div>
                    <video style="width: 100%;" playsinline="" autoplay="" loop="" muted="">
                        <source src="https://www.bilibili.com/video/BV1eG411k78y/?spm_id_from=333.999.0.0&vd_source=d06dffc642dac00750f7d71b08362191" type="video/mp4" autoplay="" loop="" muted="">
                    </video>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import { WOW } from "wowjs";
 export default {
    name: 'ShowVideo',
    data() {
      return {
        url:'http://yujituol.com/img/a.png',   
      }
    } ,mounted() {
        let wow = new WOW({
          boxClass: "wow", //需要执行动画的元素的 class
          animateClass: "fadeInUp", //fadeInDown 动画的 class
          offset: 10, //距离可视区域多少开始执行动画
          mobile: true, //是否在移动设备上执行动画
          live: true, //异步加载的内容是否有效
        });
        wow.init();
  },
}
</script>
<style>
.show_video_bg{
    width: 100%;
    height: 20rem;
    background-size: cover;
    background-position: center;
}
.show_video_f{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.i_box{
    border: 1px solid #fff;
    padding: 1rem ;
    cursor: pointer;
    transition:0.5s
}
.i_box:hover{
    padding: 1rem 1.5rem;
}
@media all and (orientation : portrait) {
    /* .show_video_bg{
        height: 55rem;
    } */
}

</style>