<template>
    <div>
        <div class="header_box">
            <div class="item" v-for="item in topPostInfo" :key="item.postId" @click="goToUrl(item.postId)">
                <div class="zd_icon">置顶</div>
                <div class="zd_title">{{item.postTitle}}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ForumHeader',
    components:{
       
    },
    data() {
        return {
            topPostInfo:[]
        }
    },methods:{
        goToUrl(postId){
            //this.$router.push({path:'StrategyShow',query:{'strategyId':strategyId}})
            window.open('/#/postcontent/?postId='+postId, '_blank');
        },
        topPost(){
          this.$http.post("/web/post-info/selectTopPostInfo")
          .then((res)=>{
            if(res.data.code==200){
              let resItems=  res.data.data;
              this.topPostInfo=resItems

            }else{
              this.$message.error('加载置顶失败！');
            }
          })
          .catch(function(err){
          //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
          })  
        },

    },mounted(){
        this.topPost();
    }
}
</script>
<style scoped>
.header_box{
    background-color: aliceblue;
    background-color: #fff;
    min-height: 10rem;
    margin-top: 1rem;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    padding: 1rem;
}
.item{
    display: flex;
    margin: 1rem;
    cursor: pointer;
}
.zd_icon{
    box-sizing: content-box;
    border: 1px solid #f29921;
    color: #f29921;
    border-radius: 2px;
    text-align: center;
    font-weight: 600;
    padding: 0 4px;

}
.zd_title{
    font-weight: 600;
    padding: 0 0.5rem;
    letter-spacing: 1px;
    color: #666;
}
.zd_title:hover{
    color: #f29921;
}
</style>