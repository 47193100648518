<template>
    <div>
        <div class="user_info_box">
            <div class="user_header_box">
                <div class="user_header_img" @click="showModal " :style="`background-image: url(${userPhoto});`">
                    <div class="user_header_i">选择头像</div>
                </div>
            </div>
            <div class="user_info_item">
                <div>
                    昵称：
                </div>
                <div class="user_info_item_input">
                    <input v-model="userName"/>
                </div>
            </div>
            <div class="user_info_item">
                <button @click="changeUserInfo">提交修改</button>
            </div>
      
        </div>
        <b-modal  size="lg" id="modal" centered title="头像选择" hide-footer hide-header>
            <CropperImage  @uploadImgSuccess="uploadImgSuc"></CropperImage>

        </b-modal>
    </div>
</template>
<script>

import CropperImage from '@/components/user/CusoVueCropper.vue';

export default {
    name: 'UserInfo',
    props: ['value'],
    components: {
        CropperImage
        },
    data() {
      return {
        user:this.$parent.userInfo,
        userName:this.$parent.userInfo.userName,
        userPhoto:this.$parent.userInfo.userPhoto,
      }
    },
    methods:{
        showModal(){
            this.$bvModal.show('modal')
        },
        //图片上传成功后
        uploadImgSuc (data){
            console.log("头像返回------>"+JSON.stringify(data))
            this.userPhoto=data.url
        }
        ,changeUserInfo(){
            this.$http({
                  method: "post",
                  url: "/web/user/changeUserBasicInfo",
                  data: {
                    token:localStorage.getItem("token"),
                    userName:this.userName,
                    userPhoto:this.userPhoto
                  }
                })
                .then((res)=>{
                  if(res.data.code==200){
                   this.$message.success("修改成功！")
                   localStorage.setItem("userPhoto",this.userPhoto);
                  }else{
                    this.$message.error('修改信息失败！');
                    console.log(res);
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
        }
    }
}
</script>
<style scoped>
.user_info_box{
    text-align: center;
}
.user_info_box img{
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
}
.user_info_item{
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    color: #555;
}
.user_info_item_input{
    border-bottom: 2px solid #999;
}
.user_info_item_input input{
    border: 0px;
    outline: none;
    text-align: center;
}
.user_info_item button{
    border: 0px;
    background: #409ffe;
    color: #fff;
    padding: 1rem 5rem;
}
.user_header_box{
    display: flex;
    justify-content: center;
}
.user_header_img{
    width: 10rem;
    height: 10rem;
    background-image: url("http://localhost/poto1.png");
    border-radius: 50%;
    overflow: hidden;
    background-size: 100%;
    background-position: center;
    border: 1px solid #efefef;
     background-color: #ccc;

}
.user_header_i{
    width: 100%;
    height: 30%;
    margin-top: 70%;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    font-size: 13px;
    text-align: center;
}
 
  @media all and (orientation : portrait) {
   
    /* .user_header_img{
        width: 20rem;
        height: 20rem;
    }
    .user_header_i{
        font-size: 3rem;
    }
    .user_header_i{
        font-size: 5rem;
    }
    .user_info_item button{
        padding: 2rem 8rem;
        font-size: 4rem;
        margin-top: 5rem;
    }
    .user_info_item{
        font-size: 5rem;
        margin-top: 5rem;
    }
    .user_header_i{
        font-size: 3rem;
    } */
  }

</style>