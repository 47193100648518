<template>
    <div class="post_content">
        <div class="post_header">
            <!-- <div v-if="postType==1" class="post_header_type bg_black">
                              普通
            </div>
            <div v-else-if="postType==2" class="post_header_type bg_red">
                BUG
            </div>
            <div v-else-if="postType==3" class="post_header_type bg_blue">
                活动
            </div> -->

            <div class="post_header_title">
                {{postTitle}}
            </div>
            <div class="post_header_bottom">
            <div  v-if="postType==1" class="post_header_bottom_right_i">
                类型：普通
            </div>
            <div  v-if="postType==2" class="post_header_bottom_right_i">
                类型： BUG反馈
            </div>
            <div  v-if="postType==3" class="post_header_bottom_right_i">
                类型：活动
            </div>
            <div class="post_header_bottom_right_i">
                        <b-icon icon="chat-right-dots"></b-icon><span class="total">{{commentNumber}}</span>
                </div>
                <div class="post_header_bottom_right_i">
                        <b-icon icon="hand-thumbs-up"></b-icon><span class="total">{{likeNumber}}</span>
                </div>
                <div class="post_header_bottom_right_i">
                    <span id="inform_btn" class="total hover_red" @click="showModal()" > 举报</span>
                </div>
            </div>
        </div>
        <div class="post_body">
                <div v-html="postContent" class="content_html">
                    
                </div>
                <hr/>
                <div class="post_body_bottom">
                    <div>
                        <span>发布时间：{{postTime | dateFormat}}</span>
                    </div>
                    <div class="post_body_bottom_i">
                        <b-icon class="up_i" icon="hand-thumbs-up" style="" @click="addLikeNumber"></b-icon><br/>
                        <span class="total"> {{likeNumber}}</span>
                    </div>
                </div>
        </div>
         <!--  -->
         <b-modal id="addReportModal"  centered  scrollable hide-footer hide-header>
            <div class="addReport_box">
                <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入举报内容"
                v-model="reportValue">
                </el-input>
                <div class="addReport_btn">
                    <el-button type="primary" @click="addReportRecord()">提交</el-button>
                </div>
            </div>
        </b-modal>
    </div>

</template>
<script>
import axios from "axios";
 export default {
      name: 'PostContent',
      data(){
         return{
            postTitle:"标题",
            postContent:"内容",
            likeNumber:0,
            commentNumber:0,
            postType:"类型",
            postTime:"2023-12-06T08:01:10.000+00:00",
            postId:this.$route.query.postId, 
            reportValue:null,
            reportPid:this.$route.query.postId,
           
         }
        },methods:{
            showModal(){
                let token =  localStorage.getItem("token")
                if(token){
                    this.$bvModal.show('addReportModal')
                }else{
                    this.$message.info("请先登录！")
                }
                
            },
            addLikeNumber(){
                let token =  localStorage.getItem("token")
                if(token){
                    axios({
                    method: "post",//指定请求方式
                    url: "/web/post-info/addLikeNumber",
                    data: {
                        postId:this.postId,
                        token:localStorage.getItem("token"),
                        }
                    })
                    .then((res)=>{
                        if(res.data.code==200){
                            this.likeNumber=parseInt(this.likeNumber)+1
                            this.$message.success(res.data.message);
                        }else{
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch(function(err){
                    //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                    console.log(err);
                
                    }) 
                }else{
                    this.$message.info("请先登录！")
                }
               
            }
            ,addReportRecord(){
                axios({
                    method: "post",//指定请求方式
                    url: "/web/reportRecords/insertReportRecords",
                    data: {
                        reportType:1,
                        token:localStorage.getItem("token"),
                        reportValue:this.reportValue,
                        reportPid:this.reportPid,
                        }
                    })
                    .then((res)=>{
                        if(res.data.code==200){
                            this.$message.success('提交成功！');
                            this.$bvModal.hide('addReportModal')
                        }else{
                            this.$message.error('举报失败！');
                        }
                    })
                    .catch(function(err){
                    //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                    console.log(err);
                
                    }) 

            }
            

        },mounted(){
            axios({
                    method: "post",//指定请求方式
                    url: "/web/post-info/selecPostByPostId",
                    data: {
                        postId:this.postId,
                        }
                    })
                    .then((res)=>{
                        if(res.data.code==200){
                            //let json = JSON.stringify(res.data);//tostrin
                            this.postTitle=res.data.data.postTitle;
                            this.postContent=res.data.data.postContent;
                            this.likeNumber=res.data.data.likeNumber;
                            this.commentNumber=res.data.data.commentNumber;
                            this.postId=res.data.data.postId;
                            this.postType=res.data.data.postType;
                            this.postTime=res.data.data.postTime;    

                            //console.log(json);
                        }else{
                            this.$message.error('帖子内容加载失败！');
                        }
                    })
                    .catch(function(err){
                    //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                    console.log(err);
                
                    })  

            
        }
    }
</script>
<!-- <style src="@/assets/css/comment.css" scoped></style> -->
<style scoped>
.forum_bg{
    min-height: 80rem;
    width: 100%;
    background-color: #e4e4e4;
    padding-top: 6rem;
   
 }
 
 .post_content{
   
    box-shadow: 5px 5px 5px #999;
 }
 .content_html{
    width:100%;
    overflow:hidden;
 }
 .content_html >>> img {
        max-width: 100% ;
    }


 .post_header{
    flex-wrap: wrap;
    background-color: #fff;
    padding-top: 1rem;
    width: 100%;
    display: flex;
    min-height: 7rem;
    /* border-top-left-radius: 2rem; */
 }
 .post_header_type{
    width: 4rem;
    background-color: #409EFF;
    color: #fff;
    height: 4rem;
    text-align: center;
    line-height: 4rem;
    font-size: 18px;
    font-weight: 600;
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
 }
 .post_header_title{
    font-size: 2rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
    min-height: 4rem;
    line-height: 4rem;
    padding: 0px 1rem;
 }
 .post_header_bottom{
    width: 100%;
    display: flex;
    min-height: 2rem;
    justify-content: flex-end;
    padding: 0px 4rem;
   
 }
 .post_header_bottom_right_i{
    height: 2rem;
    line-height: 2rem;
    margin: 0px 1rem;
    color: #999;
    font-size: 1rem;
    display: flex;
    align-items: center;
 }
 .post_body{
    background-color: #fff;
    padding: 1rem 4rem;
    width: 100%;
    display: flex;
    min-height: 10rem;
    color: #555;
    letter-spacing: 1px;
    flex-wrap: wrap;
    overflow:hidden;
}
.post_body img{
    max-width:100%;
}
.post_body_bottom{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding-top: 2rem;
    margin-top: 3rem;
    border-top: 1px solid;
    color: #999;
    align-items: center;
}
.up_i{
    color: #666;
    cursor: pointer;
    width: 3rem; 
    height: 3rem;
}
.up_i:hover{
    color: #409EFF;
   
}
.post_body_bottom_i{
    padding-top: 1rem;
}
/* ------------- */
.bg_red{
    background-color: #F56C6C;
  }
  .bg_blue{
    background-color: #409EFF;
  }
  .bg_black{
    background-color: #909399;
  }
  .total{
    padding: 0 5px;
  }
  .hover_red{
    cursor: pointer;
  }
  .hover_red:hover{
    color: #F56C6C;
  }
  .addReport_box{
    padding: 1rem 0rem;
    text-align: center;
  }
  .addReport_btn{
    padding-top: 1rem;
  }

  @media all and (orientation : portrait) {
    .post_header_bottom{
        padding:0;
    }
    .post_body{
        padding: 1rem 1rem;
    }
    .post_header_title{
        font-size:1.5rem;
       
      
    }
    .post_header_bottom_right_i{
        font-size: 0.9rem;
    }
   /* .post_header{
    min-height: 30rem;
   }
   .post_header_type{
    font-size: 4rem;
    width: 10rem;
    height: 7rem;
    line-height: 7rem;
   }
    .post_header_title{
        font-size: 5rem;
        line-height: 8rem;
    }
    .post_header_bottom_right_i{
        font-size: 3rem;
    }
    .post_body{
        font-size: 4rem;
        line-height: 6rem;
    }
    .post_body{
        font-size: 3rem;
    }
    .up_i{
        width: 8rem;
        height: 8rem;
    } */
    

}
</style>