<template>
    <div class="post_record_bg">
        <div>
            <div>
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="标题">
                        <el-input v-model="postTitle" placeholder="标题"></el-input>
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select v-model="postType" placeholder="类型">
                        <el-option label="不限" value="0"></el-option>
                        <el-option label="普通" value="1"></el-option>
                        <el-option label="BUG反馈" value="2"></el-option>
                        <el-option label="活动" value="3"></el-option>
                     
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间范围">
                            <div class="pc_show">
                              <el-date-picker
                                v-model="time"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                                </el-date-picker>
                            </div>

                           <div class="phone_show">
                            <el-date-picker
                              v-model="value1"
                              type="datetime"
                              placeholder="选择日期时间"
                              default-time="12:00:00">
                            </el-date-picker> 
                            <el-date-picker
                                v-model="value2"
                                type="datetime"
                                placeholder="选择日期时间"
                                default-time="12:00:00">
                              </el-date-picker>
                           </div>

                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="loadPostRecords">查询</el-button>
                    </el-form-item>
                    </el-form>
            </div>
            <div>
                <el-table
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column
                        prop="postId"
                        label="ID"
                        width="80">
                    </el-table-column>
                    <el-table-column
                        prop="postTitle"
                        label="标题"
                        width="350">
                    </el-table-column>
                    <el-table-column
                        prop="likeNumber"
                        label="点赞数"
                        width="80">
                    </el-table-column>
                    <el-table-column
                        prop="commentNumber"
                        label="评论数"
                        width="80">
                    </el-table-column>
                    <el-table-column
                        prop="postType"
                        label="类型"
                        width="80">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.postType==1" >普通</el-tag>
                            <el-tag v-else-if="scope.row.postType==2" type="danger" >BUG反馈</el-tag>
                            <el-tag v-else type="success">活动</el-tag>
                        </template>
                       
                    </el-table-column>
                    <el-table-column
                        prop="postState"
                        label="状态"
                        width="80">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.postState==1" >正常</el-tag>
                            <el-tag v-if="scope.row.postState==2" >置顶</el-tag>
                            <el-tag v-else-if="scope.row.postState==3" type="info" >下架</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="postTime"
                        label="时间"
                        width="280">
                        <template slot-scope="scope">
                        {{scope.row.postTime | dateFormat}}
                    </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            @click="open(scope.row.postId)">查看</el-button>
                           
                            <el-button
                            size="mini"
                            type="danger"
                            @click="changePostState(scope.row.postId)">下架</el-button>
                        </template>
                        </el-table-column>
                    </el-table>
                    
            </div>
            <div class="post_record_bottom">
                <el-pagination
                background
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                :total="rows">
                </el-pagination>
            </div>
        </div>
        <div></div>
    </div>
</template>
<script>
export default {
    name: 'PostRecord',
    data() {
      return {
        time: [new Date(2023, 10, 10, 10, 10), new Date(2024, 11, 11, 10, 10)],
        page:1,
        total:10,
        rows:0,
        postTitle:null,
        postType:null,
        tableData: [],
        value1:null,
        value2:null
      }
    },
    methods: {
      onSubmit() {
        console.log('submit!');
      }
      ,loadPostRecords(){
        this.$http({
                  method: "post",//指定请求方式
                  url: "/web/post-info/selectPostRecordsByPage",
                  data: {
                    token:localStorage.getItem("token"),
                    postTitle:this.postTitle,
                    postType:this.postType,
                    page:this.page,
                    total:this.total,
                    postTime:this.value1==null?this.time[0]:this.value1,
                    endTime:this.value2==null?this.time[1]:this.value2
                  }
                })
                .then((res)=>{
                  if(res.data.code==200){
                   
                    this.tableData=res.data.data.postInfoList
                    this.rows=res.data.data.rows
                    this.$message.success(res.data.message);
                  }else{
                    this.$message.error(res.data.message);
                    
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
      },changePostState(postId){
        this.$http({
                  method: "post",//指定请求方式
                  url: "/web/post-info/updatePostState",
                  data: {
                    token:localStorage.getItem("token"),
                    postId:postId
                   
                  }
                })
                .then((res)=>{
                  if(res.data.code==200){
                    this.$message.success(res.data.message);
                    this.loadPostRecords();
                  }else{
                    this.$message.error(res.data.message);
                    
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
      }
       ,handleCurrentChange(val) {
         console.log(`当前页: ${val}`);
         this.page=val;
         this.loadPostRecords();
      },
      open(postId){
        window.open('/#/postcontent/?postId='+postId, '_blank');
      },
    },mounted(){
        this.loadPostRecords();
    }
}
</script>
<style scoped>
.post_record_bg{
    padding-left: 5rem;
}
.post_record_bottom{
    text-align: center;
    padding: 5rem;
}
.phone_show{
  display: none;
}
.pc_show{
  display: block;
}
 @media all and (orientation : portrait) {
    .post_record_bg{
      padding: 1rem;
    }
    .phone_show{
        display: block;

    }
    .pc_show{
      display: none;
    }
}
</style>