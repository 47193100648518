<template>
    <div class="a_bg">
        <div class="login_box">
            <div class="login_top">
                Login
            </div>
            <div class="login_item">
                
                <div class="login_input">
                   <input v-model="amdinInfo.adminPhone" placeholder="账号" type="text">
                </div>
            </div>
            <div class="login_item">
               
                <div class="login_input">
                   <input  v-model="amdinInfo.adminPassword" placeholder="密码" type="password">
                </div>
            </div>
            <div class="">
                   <button class="login_btn" @keydown.enter="login" @click="login">登录</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AdminLoginView',
    data(){
    return{
      amdinInfo:{
         adminPhone:null,
         adminPassword:null,
      }
     
    }
  },methods:{
      login(){
         this.$http.post('/web/admin-info/selectAdminByPwd',this.amdinInfo)
                .then((res)=>{
                  if(res.data.code==200){
                     let token = res.data.data.token
                     let json = JSON.stringify(res.data.data);
                     localStorage.setItem("a_token",token);
                     localStorage.setItem("adminId",res.data.data.adminId);
                     localStorage.setItem("a_data",json);

                    this.$message.success('登录成功！');
                    this.$router.push("/admin");
                  }else{
                    this.$message.error(res.data.message);
                   
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
      }
  }
}
</script>
<style scoped>
 .a_bg{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
 }
 .login_box{
    width: 300px;
    orphans: 2/3;
    aspect-ratio: 2/3;
    background-color: #fff;
    display: flex;
    /* justify-content: center;
    flex-wrap: wrap; */
    flex-direction: column;
    text-align: center;
    font-size: 15px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #999;
 }
 .login_top{
    height: 60px;
    /* border-bottom: 1px solid #999; */
    display: flex;
    justify-content: center;
    align-items: flex-end;;
 }
 .login_item{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
 }
 .login_item input{
    border: 0px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    outline: none;

 }
 .login_input{
    border-bottom: 1px solid #555;
    width: 80%;
 }
 .login_btn{
    border: 0px;
    color: #fff;
    background-color: #409eef;
    padding: 10px 70px;
    margin-top: 43px;
    border-radius: 5px;
 }
</style>