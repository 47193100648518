<template>
    <div class="send_box">
        <div>
            <el-form ref="form" label-width="80px">
                <el-form-item label="帖子标题" class="form_item_lenght">
                    <el-input placeholder="请输入标题"  maxlength="20" show-word-limit @blur="postTitleLength" v-model="postTitle"></el-input>
                </el-form-item>
                <el-form-item label="帖子类型">
                    <el-select v-model="postType">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>

                    <!-- <el-select v-model="postType" placeholder="请选择帖子类型">
                        <el-option label="普通" value=1 active></el-option>
                        <el-option label="BUG反馈" value=2></el-option>
                        <el-option label="活动" value=3></el-option>
                    </el-select> -->
                </el-form-item>
                <div class="editor_box">
                <Toolbar
                    style="border-bottom: 1px solid #ccc"
                    :editor="editor"
                    :defaultConfig="toolbarConfig"
                    :mode="mode"
                />
                <Editor class="editor_value"
                    style=";"
                    v-model="html"
                    :defaultConfig="editorConfig"
                    :mode="mode"
                    @customAlert="customAlert"
                    @onCreated="onCreated"
                />
                </div>
                </el-form>
        </div>
       
        <div class="send_post_btn_box">
            <button class="send_post" @click="addPost">发布</button>
        </div>
        
    </div>
</template>
  
<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
//import axios from "axios";

// let _this=this
// var editorConfig={
//     withCredentials:true,//定义该属性为ture表示允许跨域访问
//     autofocus:false,
//     scroll:true,
//     placeholder: '请输入内容...',
//     maxLength:1200,
//     minLength:300,
//     MENU_CONF:{
        //向编辑器中上传图片或者粘贴图片时触发该方法
    // fieldName:'file',
    // server:'/web/upload/editroUploadImg',//后台服务器地址
    // headers: {
    //     token: localStorage.getItem("token"),
    // },
    // maxFileSize: 3 * 1024 * 1024, //
    // // maxNumberOfFiles: 200,
    // allowedFileTypes: ['image/*'],
    // timeout: 20 * 1000, 
    // maxNumberOfFiles: 1,
    // onFailed(file, res) {           // JS 语法
    //     this.showMs("上传失败,"+res);
    // },
    // onError(file, err) {      
    //     console.log(this)         // JS 语法
    //     this.showMsg(`${file.name} 上传出错:`+err)
    // }

        //自定义上传图片
         // 上传图片
    
         // 自定义图片上传
      

    //    customUpload:(file, insertFn)=> {
    //     // file 即选中的文件
    //     const fd = new FormData()
    //     fd.append('file', file)
    //     axios
    //       .post('/web/upload/editroUploadImg', fd)
    //       .then((res) => {
    //         console.log(res)
    //         let code = res.data.code;
    //         alert(JSON.stringify(res.data))
    //         if(res.data.code==200){
    //             alert(code)
    //             let href=res.data.data.href;
    //              let url =res.data.data.url;
    //             console.log("-url--->"+res.data.message)
    //             insertFn(url, href)
    //         }else{
    //             console.log("---->"+this)
    //             this.$message.error(res.data.message)
    //             console.log("---->"+res.data.message)
    //         }
    //         //const url = process.env.VUE_APP_BASE_IMG + res.data.data.href
    //         // 插入图片
    //         //insertFn(url)
           
    //       })
    //       .catch(() => {})
    //   },

//     },

// };

// editorConfig.MENU_CONF:{
//     uploadImage:{}
//  }

export default Vue.extend({
    components: { Editor, Toolbar },
    data() {
        return {
            editor: null,
            html: '',   
            postTitle:"", 
            postType:1,
            options: [
            {
              value: 1,
              label: '普通'
            },
            {
              value: 2,
              label: 'BUG反馈'
            },
            {
              value: 3,
              label: '活动'
            }
          ],
            mode: 'default', // or 'simple'
            toolbarConfig: {
                // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
                 excludeKeys: [ 'group-video','fullScreen'/* 隐藏哪些菜单 */ ],
            },
            editorConfig: {
                withCredentials:true,//定义该属性为ture表示允许跨域访问
                autofocus:false,
                scroll:true,
                placeholder: '请输入内容...',
                maxLength:1200,
                minLength:300,
                MENU_CONF:{
                    uploadImage:{
                    fieldName:'file',
                    server:'/web/upload/editroUploadImg',//后台服务器地址
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                    maxFileSize: 10 * 1024 * 1024, //
                    // maxNumberOfFiles: 200,
                    allowedFileTypes: ['image/*'],
                    timeout: 20 * 1000, 
                    maxNumberOfFiles: 1,
                    // 自定义插入图片
                    customInsert: (res, insertFn) => {
                        console.log("res-->"+JSON.stringify(res))
                        if (res.code == 200) {
                            // 从 res 中找到 url alt href ，然后插入图片
                            //insertFn(url, alt, href)
                            insertFn(res.data.data.url,"",res.data.data.href)
                        
                        } else {
                            this.$message.error(res.message);
                        }
                    },

                    onFailed:(file, res)=> {           // JS 语法
                        this.$message.erro("上传失败,"+res);
                    },
                    onError:(file, err, res)=> {      
                        console.log(this) 
                        console.log(`${file.name} 上传出错`, err, res)
                        this.$message.error("上传出错:"+err);
                    }

       }
                }
            }
        }
    },
    methods: {
        showMsg(message) {
            this.$confirm(message, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            
            }).catch(() => {
                    
            });
        },
        customAlert() 
        { window.alert(`customAlert in Vue demo`) },
        //自定义上传图片
        uploadImg(file, insertFn) {
            let imgData = new FormData();
            //console.log(file);
            imgData.append("file", file);
            //调用上传图片接口，上传图片
            this.$http.post("/web/upload/editroUploadImg", imgData,{

            })
            this.$http({
                  method: "post",//指定请求方式
                  url: "/web/upload/editroUploadImg",
                  headers:{
                    'Content-Type': 'multipart/form-data',
                     token: localStorage.getItem("token"),    
                  }
                  ,data: {
                     file:file,
                   
                    }
                })
                .then((res) => {
                    alert(res);
                    // 插入后端返回的url
                    insertFn(res[0].url);
                    this.$message.success('上传成功！');
                })
                .catch((error) => {
                    this.$message.error("上传失败,请重新上传"+error);
                });
        }, 

        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        openMsg(message,type) {
            this.$message({
            message: message,
            type: type
            });
      },
        addPost(){
            let images = this.editor.getElemsByType('image')
            var arr = []
            images.forEach(element => {
                let  res = element.src
                arr.push(res);
              
            });
            if(this.postTitle!=null&&this.postTitle!=""){
                this.$http({
                  method: "post",//指定请求方式
                  url: "/web/post-info/insertPost",
                  data: {
                    token:localStorage.getItem("token"),
                    postTitle:this.postTitle,
                    postType:this.postType,
                    postContent:this.html,
                    postText:this.editor.getText(),
                    imagesList:arr,
                  }
                })
                .then((res)=>{
                  if(res.data.code==200){
            
                    this.$message.success(res.data.message);
                  }else{
                    this.$message.error(res.data.message);
                   
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
            }else{
                this.$message.error('请填写标题');
            }

            
        },
        getimgs(){
            let images = this.editor.getElemsByType('image')
            var resImages="";
            var arr = []
            images.forEach(element => {
                let  res = element.src
              
                arr.push(res);
                resImages=resImages+","+res;
            });
            if(resImages!=""&&resImages.length>0){
                resImages = resImages.substring(1);
            }
        }
        ,postTitleLength(){
            let titleLength = this.postTitle.length
            if(titleLength>40){
                this.$message.error("标题太长")
                this.postTitle=this.postTitle.substring(0,40)
            }
        }
    },
    mounted() {
        // 模拟 ajax 请求，异步渲染编辑器
        // setTimeout(() => {
        //     this.html = '<p>输入内容</p>'
        // }, 1500)
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
//
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
  <style scoped>
  .send_box{
    padding-left: 5rem;
  }
  .editor_box{
    border: 1px solid #999;
    width: 80%;
  }
  .form_item_lenght{
    width: 20rem;

  }
  .send_post_btn_box{
    width: 100%;
    text-align: center;
    padding-top: 3rem;
  }
  .send_post{
    border: 0px;
    width: 15rem;
    background-color: #409EFF;
    color: #fff;
    height: 4rem;
  }
  .editor_value{
    min-height: 20rem; 
    overflow-y: hidden
  }
  @media all and (orientation : portrait) {
    .send_box{
        padding: 0.5rem;
    }
    .editor_box{
        width: 100%;
    }
    /* .form_item_lenght{
        width: 100rem;
    }
    .editor_box{
        width: 100%;
    }
    
    .editor_value{
    min-height: 65rem; 
    overflow-y: hidden;
    font-size: 5rem;
  }
  .send_post{
    font-size: 4rem;
    height: 10rem;
    width: 30rem;
       
  } */
  }
 
  </style>
  