<template>
    <div>
        <div>
            <div class="user_info_item">
                <div class="user_info_item_title">
                    手机号：
                </div>
                <div class="user_info_item_input">
                    <input disabled="disabled" value="18716854802"/>
                </div>
            </div>
            <div class="user_info_item">
                <div class="user_info_item_title">
                    新密码：
                </div>
                <div class="user_info_item_input">
                    <input v-model="password" type="password" @blur="passwordVer" placeholder="请输入新密码"/>
                </div>
                <div>
                   
                </div>
            </div>
            <div class="user_info_item">
                <div class="user_info_item_title">
                    确认密码 ：
                </div>
                <div class="user_info_item_input">
                    <input v-model="passwordV" type="password"  @blur="passwordVer" placeholder="请再次输入密码"/>
                </div>
            </div>
            <div class="user_info_item">
                <div class="user_info_item_title">
                    <button @click="showModal">{{sendcodehtml}}</button>
                </div>
                <div class="user_info_item_input">
                    <input  v-model="smsCode" placeholder="请输入短信验证码"/>
                </div>
            </div>

            <div class="change_button_box">
                <button class="change_button" @click="changeUserPaW">提交修改</button>
            </div>
        </div>
        <!--  -->
        <b-modal id="modal" centered >
            <template #modal-header="{ close }">
              <div style="width: 100%;">
                  <b-button class="close_btn" size="sm" variant="outline-secondary" @click="close()">
                  关闭
                </b-button>
              </div>
            </template> 
             <GetImgCode @refreshMethod="refresh" :userPhone="userPhone" ref="GetImgCode"></GetImgCode>
             <template #modal-footer="{ }">
                <div style="width: 100%;text-align: center; ">
                  <button class="VerificationCode" @click="sendSmsCode">立即获取验证码</button> 
                </div>
            </template> 
          </b-modal>
    </div>
</template>
<script>
import GetImgCode from '@/components/login/GetImgCode.vue';
export default {
    name: 'ChangePassword',
    components: {
      GetImgCode
       
    },
    data() {
        return {
            userPhone:this.$parent.userInfo.userPhone,
            countDownNumber:0,
            sendcodehtml:"获取验证码",
            sendState:1,
            password:null,
            passwordV:null,
            passwordState:0,
            smsCode:null,
        } 
    },methods:{
        //密码验证
        passwordVer(){
            if(this.password!=null && this.password!=""){
                if(this.password.length>6){
                    if(this.passwordV!=null&&this.passwordV!=""){
                        if(this.password==this.passwordV){
                            this.passwordState=1
                        }else{
                            this.passwordState=0
                            this.$message.error("前后密码不一致");
                        }
                    }
                }else{
                    this.passwordState=0
                    this.$message.error("密码长度太短"); 
                }
            }else{
                this.passwordState=0
                this.$message.error("密码不能为空");
            }

            
        },
        //倒计时
        countDown(){
            this.countDownNumber=60;
            setInterval(() => {
                if (this.countDownNumber > 0) {
                    this.countDownNumber--;
                    this.sendcodehtml=this.countDownNumber+"秒后重新发送"
                    this.sendState=2;
                } else {
                    this.sendcodehtml="重新获取验证码"
                    this.sendState=1
                    clearInterval();
                }
            }, 1000);  
        },
        showModal(){
            this.passwordVer();
                if(this.passwordState==1){
                    this.$bvModal.show('modal')
                }
        },refresh(){
            this.countDown();
            this.$bvModal.hide('modal')
        },
        sendSmsCode(){
            this.$refs.GetImgCode.verificationImgCode();
        },
        changeUserPaW(){
            //提交修改
            this.$http({
                    method: "post",//指定请求方式
                    url: "/web/user/changePassword",
                    data: {
                        token:localStorage.getItem("token"),
                        userPassword:this.password,
                        smsCode:this.smsCode
                    }
                })
                .then((res)=>{
                  if(res.data.code==200){
                    this.$message.success(res.data.message);
                  }else{
                    this.$message.error(res.data.message);
                    console.log(res);
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })      
        }
    }
}
</script>
<style scoped>
.user_info_item{
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    color: #555;
    height: 5rem;
    align-items: center;
}
.user_info_item_title{
    min-width: 8rem;
    text-align: end;
    color: #555;
    
}
.user_info_item_title button{
    border: 0px;
    color: #fff;
    background-color: #409ffe;
   
    height: 2rem;
    padding: 0 0.1rem;
  
}
.user_info_item_input{
    border-bottom: 1px solid #999;
    width: 20rem;
}
.user_info_item_input input{
    border: 0px;
    outline: none;
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
}
.change_button{
    border: 0px;
    background: #409ffe;
    color: #fff;
    padding: 1rem 5rem;
}
.change_button_box{
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}
.VerificationCode{
    border: 0px;
    background-color: #333;
    color: #fff;
    padding: 15px 7rem;
    transition: 1s;
  }
  .VerificationCode:hover{
    padding: 15px 8rem;
  }
  @media all and (orientation : portrait) {
    /* .user_info_item{
        height: 10rem;
        font-size: 4rem;

    }
    .user_info_item_input{
        width: 45rem;
    }
    .user_info_item_title{
        min-width:31rem;
    }
    .change_button{
        padding: 2rem 6rem;
        font-size: 4rem;
    }
    .user_info_item_title button{
        height: 7rem;
    } */

  }

</style>