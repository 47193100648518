<template>
    <div>
        <div>
            
            <textarea maxlength="10"
  show-word-limit v-model="commentContent" class="textarea"></textarea>
        </div>
        <div class="sendReplyBottom">
            <span>
                {{textNumber}}/100 
            </span>
           <button   class="sendReplyMsgbtn" @click="addComment">发送</button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TextareaComponent',
    data(){
       return{
        commentContent:"",
        commentImgs:"",
        postId:this.$route.query.postId,
        textNumber:0 ,
        replyCommentId:0,
        replyId:0,
        fatherCommentId:0
       }
      }
      ,inject: ["fatherMethod"]
      ,methods:{
            addComment(){
                //this.$parent.alet();
                let replyCommentId= this.$parent.replyCommentId

                let userId= this.$parent.userId

                let fatherCommentId= this.$parent.fatherCommentId

                // console.log("-----replyCommentId-"+replyCommentId);
                // console.log("-----uerId-"+userId);
                // console.log("-----fatherCommentId-"+fatherCommentId);

                this.replyCommentId=replyCommentId;
                this.replyId=userId;
                this.fatherCommentId= fatherCommentId;

                //alert(replyCommentId)
                if(this.commentContent.length>1){
                    this.$http({
                    method: "post",//指定请求方式
                    url: "/web/comment-info/addComment",
                    data: {
                        postId:this.postId,
                        commentContent:this.commentContent,
                        commentImgs:this.commentImgs,
                        commentType:2,
                        replyCommentId:this.replyCommentId, //------》被回复评论的id
                        replyId:this.replyId,               //------》被回复 用户id
                        token:localStorage.getItem("token"),
                        fatherCommentId:this.fatherCommentId
                        }
                    })
                    .then((res)=>{
                        if(res.data.code==200){
                            this.$message.success('评论成功！');
                            this.commentContent=""
                            this.fatherMethod();
                        }else{
                            this.$message.error(res.data.message);
                        }
                        
                    })
                    .catch(function(err){
                    //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                    console.log(err);
                    
                
                    })
                }else{
                    this.$message.error('请输入内容');
                }
            }
          

      },
      watch: {
            /**
             * 深度监听文本内容
             */
             commentContent: {
                handler(newVal, oldVal) {
                    if (newVal != oldVal) {
                    // 文本内容变化
                    this.textNumber=newVal.length
                        if(newVal.length>100){
                            this.$message.error('内容太多了哦！');
                            this.commentContent=newVal.substring(0, 100); 
                        }

                    }
                },
                deep: true
            }
        }

}
</script>
<style scoped>
.textarea{
    width: 100%;
    height: 10rem;
    border: 1px solid #ebebeb;
    border-radius: 1rem;
    padding: 5px;
    color: #666;
    outline-color: #409EFF;
    font-size: 0.9rem;
}
.sendReplyMsgbtn{
    background-color: #409EFF;
    color: #fff;
    border: 0;
    width: 70px;
}
.sendReplyBottom{
    display: flex;
    /* justify-items: flex-end; */
    /* width: 100%; */
    justify-content: flex-end;
    margin-bottom: 1rem;
    color: #999;
}
.sendReplyBottom span{
    padding: 0 5px;
}
@media all and (orientation : portrait) {
    .textarea{
        height: 30rem;
        line-height:4rem;
        font-size: 3.3rem;
    }
    

}

</style>
