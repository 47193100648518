<template>
    <div>
        <div>
            <el-form label-width="80px"  ref="admin" :model="admin" :rules="rules" >
                <el-form-item label="名称" prop="adminName">
                    <el-input v-model="admin.adminName" placeholder="名称"></el-input>
                </el-form-item>
                <el-form-item label="手机号"  prop="adminPhone">
                    <el-input type= "tel" v-model="admin.adminPhone" placeholder="手机号"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="adminPassword">
                    <el-input type="password" v-model="admin.adminPassword" placeholder="密码"></el-input>
                </el-form-item>
                <el-form-item label="等级">
                    <el-select v-model="admin.adminGrade" placeholder="等级">
                        <el-option v-for="item in gradeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="admin.adminState" placeholder="状态">
                        <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('admin')">提交修改</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UpdateAdminUser',
    props: ['rowData'],
    data() {
        return {
            gradeOptions: [
                {
                value: 1,
                label: '普通'
                },
                {
                value: 2,
                label: '高级'
                }
            ], stateOptions: [
                {
                value: 1,
                label: '正常'
                },
                {
                value: 2,
                label: '异常'
                }
            ],
            admin:{
                adminId:null,
                adminName:null,
                adminPhone:null,
                adminPassword:null,
                adminGrade:1,
                adminState:1,
            },
            rules: {
                adminName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                  
                ],adminPhone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                  
                ],adminPassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, message: '长度在 6 ', trigger: 'blur' }
                ],
            }
        }
    },
    methods:{
        submitForm(admin) {
            this.$refs[admin].validate((valid) => {
            if (valid) {
                this.$http.post('/web/admin-info/updateAdminInfoById',this.admin)
                .then((res)=>{
                  if(res.data.code==200){
                    this.$message.success('修改成功！');
                    this.$emit('customEvent');
                  }else{
                    this.$message.error('修改失败！');
                   
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
    }
    ,mounted(){
        if(this.rowData!=null){
            this.admin=this.rowData;
        }
        
      }
}
</script>
<style scoped>



</style>