<template>
    <div class="log_bg" :style="`background-image: url(${backgroundImage});`">
      <div class="log_box">
        <el-row>
        <el-col :xs="0" :sm="12" :md="15" :lg="15">
            <div class="log_box_left">
              <img src="http://yujituol.com//logo1.png">
            </div>
        </el-col>
        <el-col  :xs="24" :sm="12" :md="4" :lg="4">
            <div class="log_box_right">
              <div class="log_choose_item">
                <span :class="{blueColor:1==loginType}" @click="chooseType(1)">免密登录</span>|
                <span :class="{blueColor:2==loginType}" @click="chooseType(2)">密码登录</span>
              </div>
            
             <div class="log_form">
              
                  <div class="log_input_box">
                    <input type="tel" placeholder="手机号" class="log_input" autofocus="autofocus" v-model="userPhone" />
                  </div>
                  
                  <div   v-if="loginType==2" class="log_input_box">
                    <input  placeholder="密码" type="password" class="log_input" v-model="userPassword"/>
                  </div>
                
                  <div v-if="loginType==1" class="log_input_box" >
                    <input placeholder="验证码" class="log_input" v-model="smsCode"/> 
                    <span class="send_sms" @click="code_alert" >{{sendcodehtml}}</span>
                  </div>
                  <button class="log_button" @click="login" @keyup.enter="login">登录/注册</button>
                  <div style="width: 100%;">
                      <div class="orlog_title">其他登录方式</div>
                      
                  </div>
               
              </div>
             
              
            
            </div>
      </el-col>
      </el-row>
      </div>
      <!--  -->

      <div>
          <b-modal id="modal" centered >
            <template #modal-header="{ close }">
              <div class="alert_modal_header">
                  <b-button class="close_btn" size="sm" variant="outline-secondary" @click="close()">
                  关闭
                </b-button>
              </div>
            </template> 
             <GetImgCode @refreshMethod="refresh" :userPhone="userPhone" ref="GetImgCode"></GetImgCode>
             <template #modal-footer="{ }">
                <div class="alert_modal_footer">
                  <button class="VerificationCode" @keydown.enter.stop="sendSmsCode" @click="sendSmsCode">确定</button> 
                </div>
            </template> 
          </b-modal>
        </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import GetImgCode from '@/components/login/GetImgCode.vue';

  export default {
    name: 'LoginPage',
    components: {
      GetImgCode
       
    },
    provide() {
      return {
        userPhone: Number(this.userPhone),
        loginType: this.loginType,
      }
    },
    data() {
      return {
        countDownNumber:60,
        sendcodehtml:"获取验证码",
        sendState:1,
        userPhone:null,
        userPassword:null,
        smsCode:null,
        message:null,
        okVariant:"primary",
        loginType:1,
        backgroundImage: 'http://yujituol.com//10008.png',
        items: [
            {number:1, url:'http://yujituol.com//b1.png',title: '图片title' },
            {number:2, url:'http://yujituol.com//b2.png',title: '图片title2' },
            {number:3, url:'http://yujituol.com//b3.png',title: '图片title3' },
          
            ],
       
      }
    },
    methods:{
      isFormEmpty() {
          console.log("----------->this.userPhone"+this.userPhone);
          //return Object.values(this.userPhone).some(value => value === null||" ");
          if(this.userPhone==null||this.userPhone==""){
            return true
          }else{
            return false
          }
      },
      chooseType(index) {
        this.loginType = index
      },
      countDown(){
        this.countDownNumber=60;
        setInterval(() => {
            if (this.countDownNumber > 0) {
              this.countDownNumber--;
              this.sendcodehtml=this.countDownNumber+"秒后重新发送"
              this.sendState=2;
            } else {
              this.sendcodehtml="重新获取验证码"
              this.sendState=1
              clearInterval();
            }
          }, 1000);  
      },
      showMsg(message) {
        this.$confirm(message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          
        }).catch(() => {
                  
        });
      },
      refresh(){
        this.countDown();
        this.$bvModal.hide('modal')
      },
      phoneState(){
        if(this.isFormEmpty()){
            //this.showMsg("请输入手机号！");
            this.$message.error("请输入手机号！");
            return false;
        }else if(!(/^1[3-9][0-9]\d{8}$/.test(this.userPhone))){
           //this.showMsg("请检查手机号格式！")
           this.$message.error("请检查手机号格式！");
           return false
        }else{
          return true;
        }
      },
      code_alert(){
         //判断
        if(this.phoneState()){
          if(this.sendState==1){
            this.$bvModal.show('modal')
          }
        } 
      },
      sendSmsCode(){
          if(this.loginType==1){
            this.$refs.GetImgCode.verificationImgCode();
          }else if(this.loginType==2){
            if(this.userPassword!=null&&this.userPassword!=""){
              //alert(this.$refs.GetImgCode.imgCode)
              this.smsCode=this.$refs.GetImgCode.imgCode;
              this.loginRequest();
            }
            
          }

      },
       closeModal(){
        this.$bvModal.hide('modal')
       },
      sendSms(){
        //判断
        // if(this.phoneState()){
        //     if(this.sendState==1){
        //       axios({
        //         method: "post",//指定请求方式
        //         url: "/web/sms/sendsms",//请求接口
        //         //url: "/test/test2",//请求接口
        //         data: {
        //           userPhone:this.userPhone,
        //           }
        //         })
        //         .then((res)=>{
        //           if(res.status==200){
        //             this.showMsg("发送成功！");
        //             this.countDown();
        //           }else{
        //             this.showMsg("发送失败!");
        //             console.log(res);
        //           }
        //         })
        //         .catch(function(err){
        //         //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
        //           console.log(err);
              
        //         })
        //     }
        // }
      },
      login(){
          if(this.phoneState()&&this.sendState){
            if(this.loginType==1){
              this. loginRequest();
            }else if(this.loginType==2){
             
              if(this.userPassword!=null&&this.userPassword!=""){
               
                this.code_alert();
              }else{
                this.$message.error("请输入密码！");
              }
              
            }


            
          }else{
            this.$message.error("请检查手机号格式");
          }

          

      },
      loginRequest(){
        axios({
                  method: "post",//指定请求方式
                  url: "/web/user/login",
                  data: {
                    userPhone: this.userPhone,
                    userPassword:this.userPassword,
                    smsCode:this.smsCode,
                    loginType: this.loginType,
                  }
                })
                .then((res)=>{
                  if(res.data.code==200){
                    //this.showMsg(res.data.message);
                    this.$message.success(res.data.message);
                    let json = JSON.stringify(res.data.data);
                    const token = res.data.data.token
                
                    localStorage.setItem("token",token);
                    localStorage.setItem("userId",res.data.data.userId);
                    localStorage.setItem("userPhoto",res.data.data.userPhoto);
                    localStorage.setItem("data",json);
                   
                    this.$router.push("/forum");
                     location.reload();
                    
                  }else{
                    this.$message.error(res.data.message);
                    console.log(res);
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
      }
  }

  
}
  </script>
  

  <style scoped>
 .log_bg{
    min-height: 100vh;
    text-align: center;
    width: 100%;
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;
     background-attachment: fixed;
 }
 .log_box{
   padding-top: 10rem;
   
   width: 100%;
   
 }
 .log_box_left{
   overflow: hidden;
   text-align: center;
 }
 .log_box_left img{
  width: 80%;
 }
 .log_box_right{

  background-color: #fff;
  flex-direction: column;
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  min-width: 22rem;
  flex-wrap: wrap;
 }
.log_choose_item{
  padding-top: 2rem;
  width: 100%;
 
}
.log_choose_item span{
  margin: 10px;
  cursor: pointer;
}
.blueColor{
  color: #f18934;
  font-weight: 600;
}
.log_button{
  width: 100%;
  border: 0px;
  margin-top: 3rem;
  background-color: #000;
  color: #fff;
  padding: 1rem;
}
.log_button:hover{
  background-color: #222;
}

 .log_form{
  padding-top: 1rem;
  background-color: #fff;
  min-height: 20rem;
  width: 100%;
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
 }
 .orlog_title {
   padding:2rem;
 }
 .orlog_title::before{
  content: "---------";
 }
 .orlog_title::after{
  content: "---------";
 }
 .log_input_box{
  border-bottom: 2px solid #000;
  cursor: pointer;
  float: left;
  min-width: 20rem;
  line-height: 3rem;
  margin-top: 2rem;
  display: flex;
    align-items: center;
 }
 
 .log_input{
  height: 3rem;
 
  border: 0px;
  cursor: pointer;
  float: left;
  margin-right: auto;
  
 }
 .log_input:focus {
        outline: 0px solid #51c9ff;
        background: rgba(3, 16, 28, 0);
      }
  .send_sms{
   color: #f18934;
    
  }
  .close_btn{
    float: right;
  }
  .VerificationCode{
    border: 0px;
    background-color: #333;
    color: #fff;
    padding: 15px 7rem;
    transition: 1s;
  }
  .VerificationCode:hover{
    padding: 15px 8rem;
  }
  .alert_modal_header{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .alert_modal_footer{
    width: 100%;
    text-align: center;
  }
  @media all and (orientation : portrait) {
/* 
    .log_choose_item{
      font-size: 5rem;
      padding: 10rem;
    }
    .log_input_box{
      width: 100%;
      margin: 5rem;
    }
    .log_form{
      height: 90rem;
      padding: 10rem;
    }
    .log_input{
     
      height: 100%;
      font-size: 5rem
    }
    .send_sms{
      font-size: 4rem;
      margin-left: auto;
    }
    .log_button{
      margin-top: 10rem;
      font-size: 4rem;
      height: 15rem;
    width: 80%;
    }
    .orlog_title{
      font-size: 4rem;
    }
    .alert_modal_header{
      height: 10rem;
    }
    .alert_modal_header button{
      font-size: 4rem;
    }
    .alert_modal_footer button{
      font-size: 3rem;
      width: 50%;
    } */
    
  }

  </style>
  