<template>
    <div class="activity_box">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>热门活动</span>
                <el-button style="float: right; padding: 3px 0" type="text">
                  <router-link to="/officialinfos">更多>></router-link>
                </el-button>
            </div>
            <div v-for="item in officialInfoList" :key="item.officialId" class="text item">
              <router-link :to="'/InfoShowView/?officialId='+ item.officialId">
                <div class="activity_item_img" :style="`background-image: url(${item.officialImgsList[0]});`">
                   <div class="activity_item_title_bg">
                      <div class="activity_item_title">
                          {{ item.officialTitle}}
                      </div>
                    </div>
                </div></router-link>
            </div>
            </el-card>

    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: 'HotActivity',
    data() {
      return {
        page:1,
        total:10,
        officialInfoList: [],
        officialType:2
      }
    },
    methods:{
      loadOfficialInfo(){
        axios({
            method: "post",//指定请求方式
            url: "/web/official/selectOfficialInfoByPage",
            data: {
                page:this.page,
                total:this.total,
                officialType:this.officialType,
            }
            })
            .then((res)=>{
            if(res.data.code==200){
                //let json = JSON.stringify(res.data.data);//tostring

                if(res.data.data.length<1){
            
                    document.getElementsByClassName("info_content_value")[0].innerHTML ="一条都没了！"
                }else{
                    //this.commentInfoList=res.data.data;
                    let resItems=res.data.data.officialInfoList;
                    this.officialInfoList=resItems;
                 
                   
                    
                }
                
                //console.log(json);
            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      }
    },mounted(){
         //let id =  this.$parent.moreReCommentId;
            //alert(this.ReCommentId)
            this. loadOfficialInfo();
        }
  }
  </script>
  <style scoped>
  .activity_box{
    min-height: 20rem;
    width: 100%;
    margin-top: 1rem;
  }
  .activity_item_img{
    height: 9rem;
    width: 100%;
    overflow: hidden;
    display: inline-block;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    cursor: pointer;
    margin-bottom: 1rem;
   
  }
  .activity_item_title_bg{
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    line-height: 3rem;
  }
  .activity_item_title{
    text-overflow: ellipsis;
    overflow: hidden;
    height: 3rem;
    white-space: nowrap;
    padding:0px 5px
  }
  .clearfix a{
  text-decoration: none;
  color: #999;
 }
 
  </style>
  