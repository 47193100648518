<template>
    <div>
        <div>
            <div class="cus_lable">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="帖子ID">
                        <el-input v-model="postId" placeholder="帖子ID"></el-input>
                    </el-form-item>
                    <el-form-item label="用户ID">
                        <el-input v-model="userId" placeholder="用户ID"></el-input>
                    </el-form-item>
                    <el-form-item label="帖子编号">
                        <el-input v-model="postNumber" placeholder="帖子编号"></el-input>
                    </el-form-item>
                    <el-form-item label="标题">
                        <el-input v-model="postTitle" placeholder="标题"></el-input>
                    </el-form-item>

                    <el-form-item label="类型">
                        <el-select v-model="postType" placeholder="类型">
                        <el-option label="不限" value="0"></el-option>
                        <el-option label="普通" value="1"></el-option>
                        <el-option label="BUG 反馈" value="2"></el-option>
                        <el-option label="活动" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="postState" placeholder="状态">
                        <el-option label="不限" value="0"></el-option>
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="置顶" value="2"></el-option>
                        <el-option label="异常" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="排序方式">
                        <el-select v-model="orderByType" placeholder="排序方式">
                        <el-option label="ID" value="post_id"></el-option>
                        <el-option label="评论数量" value="comment_number"></el-option>
                        </el-select>
                    </el-form-item>
                  
                    <el-form-item label="创建时间">
                        <el-date-picker
                            v-model="time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @keydown.enter="loadPostInfo" @click="loadPostInfo">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!--  -->
        <div>
            <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="postId"
                    label="帖子ID"
                    width="80">
                </el-table-column>
                
                <el-table-column
                    prop="postNumber"
                    label="编号"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="userId"
                    label="用户ID"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="userInfo.userName"
                    label="用户名"
                    width="120">
                </el-table-column>

                <el-table-column
                    prop="imagesList"
                    label="配图"
                    width="80">
                    <template v-if="scope.row.imagesList[0]!=''" slot-scope="scope">
                        <el-image 
                             class="table_img"
                            :src="scope.row.imagesList[0]" 
                            :preview-src-list="scope.row.imagesList"
                           >
                        </el-image>    
                    </template>
                </el-table-column>
                <el-table-column
                    prop="postTitle"
                    label="标题"
                     >
                </el-table-column>
                <el-table-column
                    prop="likeNumber"
                    label="点赞数"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="commentNumber"
                    label="评论数"
                    width="80">
                </el-table-column>
                
                
                <el-table-column
                    prop="postType"
                    label="类型"
                    width="80">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.postType==1" >普通</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.postType==2" >BUG反馈</el-tag>
                        <el-tag type="success" v-else-if="scope.row.postType==3" >活动</el-tag>
                        <el-tag v-else type="info">其他</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="postState"
                    label="状态"
                    width="80">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.postState==1" >正常</el-tag>
                        <el-tag type="success" v-else-if="scope.row.postState==2" >置顶</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.postState==3" >异常</el-tag>
                        <el-tag v-else type="info">其他</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="创建时间">
                    <template slot-scope="scope">
                            {{scope.row.postTime| dateFormat}}
                        </template>

                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <router-link target="_blank" :to="'/postcontent/?postId='+ scope.row.postId"> 
                        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                    </router-link>
                    <el-button type="text" @click="showModeal(scope.row)" size="small">编辑</el-button>
                </template>
                </el-table-column>
            </el-table>
        </div>
        <!--  -->
        <div class="table_footer_page_box">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="rows">
            </el-pagination>
        </div>
        <!--  -->
        <b-modal id="postModal" size="xl" centered  scrollable title="编辑" hide-footer hide-header>
           <AdminUpdatePost @customEvent="refLoad" :rowData='this.rowData'></AdminUpdatePost>
        </b-modal>
    </div>
</template>
<script>
import AdminUpdatePost from './AdminUpdatePost.vue';

export default {
    name: 'AdminPost',
    components:{
        AdminUpdatePost
    },
    data() {
        return {
            page: 1,
            total: 10,
            rows: 0,
            postId: null,
            userId:null,
            postNumber: null,
            postTitle: null,
            likeNumber: null,
            commentNumber:null,
            imagesList:null,
            postType: null,
            postState: null,
            orderByType:null,
            time: [new Date(2023, 10, 10, 10, 10), new Date(2024, 11, 11, 10, 10)],
            tableData: null,
            rowData: null,
            sort:'DESC'
            
        };
    },
    methods: {
        loadPostInfo() {
            this.$http({
                method: "post",
                url: "/web/post-info/selectPostByPage",
                data: {
                    page: this.page,
                    total: this.total,
                    postId: this.postId,
                    userId:this.userId,
                    postNumber: this.postNumber,
                    postTitle: this.postTitle,
                    likeNumber: this.likeNumber,
                    commentNumber:this.commentNumber,
                    postType: this.postType,
                    postState: this.postState,
                    orderByType:this.orderByType,
                    postTime:this.time[0],
                    endTime:this.time[1],
                    sort:this.sort,
                }
            })
                .then((res) => {
                if (res.data.code == 200) {
                    let resItems = res.data.data.postInfoList;
                    this.tableData = resItems;
                    this.rows = res.data.data.rows;
                }
                else {
                    this.$message.error('加载失败！');
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = Number(val);
            this.loadPostInfo();
        },
        showModeal(rowData) {
            this.$bvModal.show('postModal');
            this.rowData = rowData;
        },
        refLoad() {
            this.$bvModal.hide('postModal');
            this.loadPostInfo();
        }
    }, mounted() {
        this.loadPostInfo();
    },
   
}
</script>
<style scoped>


</style>