<template>
    <div class="cumos_shadow" >
      <div class="cus_lable">
          <el-form :inline="true"  class="demo-form-inline">
            <el-form-item label="时间范围">
              <el-date-picker
                v-model="time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="loadVisitData">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
            <div ref="chart" style="width: 100%;height:600px;"></div>
        </div>

        
    </div>
</template>
<script>
import * as echarts from 'echarts'
    export default {
      name: 'AdminVisitEchart',
      data(){
        return{
          
          xData: null,
          yData: null,
          time:  [
          this.$moment().add(-7, "d").endOf('day'),
          this.$moment().endOf('day'),
          ], 
          customVisitData:{
            startTime:new Date(2023, 10, 11, 10, 10),
            endTime:new Date(2024, 10, 10, 10, 10)
          }
        }
      },
      methods:{
          initChart(){
              // 基于准备好的dom，初始化echarts实例
              var myChart = echarts.init(this.$refs.chart);
              var option = {
                color:[
                    '#F56C6C'
                ],
                title: {
                  text: '访问数'
                },
                legend:{
                  data:['访问数']
                },
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'line'
                  }
                },
                xAxis: {
                 
                  data: this.xData
                },
                yAxis: {},
                series: [
                  {
                    name: '访问数',
                    type: 'line',
                    smooth: true,
                    label: {
                        show: true,
                        position: 'top'
                      },
                    data: this.yData
                  }
                ]
              }
              // 绘制图表
              myChart.setOption(option);
             
          }
          ,loadVisitData(){
            this.customVisitData.startTime=this.time[0];
            this.customVisitData.endTime=this.time[1];
            this.$http.post('/web/echarts/queryDayAddVisit',this.customVisitData)
                .then((res)=>{
                  if(res.data.code==200){
                    this.xData= res.data.data.xData
                    this.yData= res.data.data.yData
                   this.initChart()
                   console.log(res.data)
                  }else{
                    this.$message.error('查询失败！');
                   
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
          },
          

      },
      mounted() {
       this.loadVisitData()
       
      }
    }
</script>
<style scope>
.cumos_shadow{
  box-shadow: 1px 1px 1px 1px #ccc;
}

</style>