<template>
    <div>
        <div>
            <div class="cus_lable">
                <el-form :inline="true" ref="admin" :model="admin" :rules="rules" class="demo-form-inline">
                    <el-form-item label="名称" prop="adminName">
                        <el-input v-model="admin.adminName" placeholder="名称"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号"  prop="adminPhone">
                        <el-input type= "tel" v-model="admin.adminPhone" placeholder="手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="adminPassword">
                        <el-input type="password" v-model="admin.adminPassword" placeholder="密码"></el-input>
                    </el-form-item>
                    <el-form-item label="等级">
                        <el-select v-model="admin.adminGrade" placeholder="等级">
                            <el-option v-for="item in gradeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('admin')">添加</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!--  -->
        <div>
            <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="adminId"
                    label="ID"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="adminName"
                    label="名称"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="adminPhone"
                    label="账号"
                    width="120">
                </el-table-column>

                <el-table-column
                    prop="adminGrade"
                    label="等级">
                    <template slot-scope="scope">
                            <el-tag v-if="scope.row.adminGrade==1" >普通</el-tag>
                            <el-tag v-else-if="scope.row.adminGrade==2" >高级</el-tag>
                            <el-tag v-else type="success">其他</el-tag>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="adminState"
                    label="状态">
                    <template slot-scope="scope">
                            <el-tag v-if="scope.row.adminState==1" >正常</el-tag>
                            <el-tag v-else type="warning">其他</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adminTime"
                    label="创建时间">
                    <template slot-scope="scope">
                            {{scope.row.createTime| dateFormat}}
                        </template>
                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button type="text" @click="showModeal(scope.row)" size="small">编辑</el-button>
                </template>
                </el-table-column>
            </el-table>
        </div>
        <!--  -->
        <div class="table_footer_page_box">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="rows">
            </el-pagination>
        </div>
        <!--  -->
        <b-modal id="adminUserModal" size="xl" centered  scrollable title="编辑" hide-footer hide-header>
           <UpdateAdminUser @customEvent="refLoad" :rowData='this.rowData'></UpdateAdminUser>
        </b-modal>
    </div>
</template>
<script>
import UpdateAdminUser from './UpdateAdminUser.vue';

export default {
    name: 'AdminUserInfo',
    components:{
        UpdateAdminUser
    },
    data() {
        return {
            gradeOptions: [
                {
                value: 1,
                label: '普通'
                },
                {
                value: 2,
                label: '高级'
                }
            ],
            admin:{
                page: 1,
                total: 10,
                adminName:null,
                adminPhone:null,
                adminPassword:null,
                adminGrade:1,
            },
            rows: 0,
            tableData: null,
            rowData: null,
            rules: {
                adminName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                  
                ],adminPhone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                  
                ],adminPassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 25, message: '长度在 6 到 25 个字符', trigger: 'blur' }
                ],
            }
        };
    },
    methods: {
        loadAdminUserInfo() {
            this.$http({
                method: "post",
                url: "/web/admin-info/selectAdminInfoByPage",
                data: {
                    page: this.admin.page,
                    total: this.admin.total,
                }
            })
                .then((res) => {
                if (res.data.code == 200) {
                    let resItems = res.data.data.adminInfoList;
                    this.tableData = resItems;
                    this.rows = res.data.data.rows;
                }
                else {
                    this.$message.error('加载失败！');
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        },
        submitForm(admin) {
            this.$refs[admin].validate((valid) => {
            if (valid) {
                this.$http.post('/web/admin-info/insertAdminInfo',this.admin)
                .then((res)=>{
                  if(res.data.code==200){
            
                    this.$message.success('添加成功！');
                    this.loadAdminUserInfo();
                  }else{
                    this.$message.error('添加失败！');
                   
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
       
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = Number(val);
            this.loadAdminUserInfo();
        },
        showModeal(rowData) {
            this.$bvModal.show('adminUserModal');
            this.rowData = rowData;
        },
        refLoad() {
            this.$bvModal.hide('adminUserModal');
            this.loadAdminUserInfo();
        }
    }, mounted() {
        this.loadAdminUserInfo();
    },
   
}
</script>
<style scoped>


</style>