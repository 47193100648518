<template>
    <div>
      <NavBar></NavBar>
        <div class="top_menu">
          <el-menu  class="el-menu-demo" mode="horizontal" @select="choose">
          <el-menu-item index="1">基本资料</el-menu-item>
          <el-menu-item index="2">密码修改</el-menu-item>
          <el-menu-item index="3">发帖</el-menu-item>
          <el-menu-item index="4">发帖记录</el-menu-item>
        </el-menu>
        </div>
        <div class="user_center_box">
            <div class="user_center_left">
              <LeftMenu @child-event="choose"></LeftMenu>
            </div>
          <div class="user_center_right">
            <!-- <SendPost></SendPost><UserInfo></UserInfo> -->
            <component v-model="userInfo" v-if="loadState" :is="currentComponents[index]" />
          </div>
        </div>
       <PageBottom/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavBar from '@/components/NavBar.vue'  
  import SendPost from '@/components/user/SendPost.vue';
  import LeftMenu from '@/components/user/LeftMenu.vue';
  import PageBottom from '@/components/PageBottom.vue';
  import UserInfo from '@/components/user/UserInfo.vue';
  import CusoVueCropper from '@/components/user/CusoVueCropper.vue';
  import ChangePassword from '@/components/user/ChangePassword.vue';
  import PostRecord from '@/components/user/PostRecord.vue';
  export default {
    name: 'UserCenterView',
    components: {
      NavBar,
    PageBottom,
    UserInfo,
    CusoVueCropper,
    SendPost,
    ChangePassword,
    PostRecord,
    LeftMenu
    },data () {
        return {
        
          loadState:false,
          index:0,
          currentComponents:[UserInfo,ChangePassword,SendPost,PostRecord],
          userPhone:null,
          userName:'123',
          userPhoto:null,
          userSignature:null,
          userMailbox:null,
          userType:null,
          userInfo:null
        }
    }
    ,methods:{
        choose(res){
          console.log("res------->"+res)
          this.index=Number(res-1)
        }
        ,loadUserInfo(){
          //加载用户基本数据
          this.$http({
                  method: "post",
                  url: "/web/user/queryUserBasicInfo",
                  data: {
                    token:localStorage.getItem("token"),
                  }
                })
                .then((res)=>{
                  if(res.data.code==200){
                   
                    this.userInfo=res.data.data;
                    this.loadState=true;
                   
                  }else{
                    this.$message.error('查询基本信息失败！');
                    console.log(res);
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })

        }        

    },mounted(){
        this.loadUserInfo();
    }
  }
  </script>
  <style scoped>
  .top_menu{
    display: none;
  }
  .user_center_box{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5rem;
  }
  .user_center_left{
    width: 10rem;
   
  }
  .user_center_right{
    width: 90rem;
    min-height: 60rem;
  }

  @media all and (orientation : portrait) {
    .user_center_box{
      min-height: 100vh;
      justify-content: flex-start;
      padding-top: 1rem
    }
    .top_menu{
      display: block;
    }
     .user_center_left{
       width: auto;
       display:none;
     } 
     .user_center_right{
      width: 100%;
     }   

  }
  </style>
  