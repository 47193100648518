<template>
    <div>
        <div>
            <el-form ref="form" :model="userInfo" label-width="80px">
                <div class="user_header_box">
                    <div class="user_header_img" @click="showModal " :style="`background-image: url(${userInfo.userPhoto});`">
                        <div class="user_header_i">选择头像</div>
                    </div>
                </div>
                <el-form-item label="手机号">
                    <el-input v-model="userInfo.userPhone"></el-input>
                </el-form-item>
                <el-form-item label="用户名称">
                    <el-input v-model="userInfo.userName"></el-input>
                </el-form-item>
                <el-form-item label="用户签名">
                    <el-input v-model="userInfo.userSignature"></el-input>
                </el-form-item>
                <el-form-item label="用户邮箱">
                    <el-input v-model="userInfo.userMailbox"></el-input>
                </el-form-item>
                <el-form-item label="用户备注">
                    <el-input type="textarea" v-model="userInfo.userRemark"></el-input>
                </el-form-item>
                
                <el-form-item label="用户类型">
                    <el-select v-model="userInfo.userType" placeholder="用户类型">
                    <el-option label="普通" value="1"></el-option>
                    <el-option label="其他" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户状态">
                    <el-select v-model="userInfo.userState" placeholder="用户状态">
                    <el-option label="正常" value="1"></el-option>
                    <el-option label="其他" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">提交修改</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!--  -->
        <b-modal  size="lg" id="headPhotoModal" centered title="头像选择" hide-footer hide-header>
            <CropperImage  @uploadImgSuccess="uploadImgSuc"></CropperImage>
        </b-modal>
    </div>
</template>
<script>
import CropperImage from '@/components/user/CusoVueCropper.vue';
export default {
    name:'AdminUpdateUser',
    components: {
        CropperImage
    },
    props: ['rowData'],
    data() {
        return{
            userInfo:{
                userId:null,
                userPhone:null,
                userName:null,
               
                userPhoto:null,
                userSignature:null,
                userMailbox:null,
                userRemark:null,
                userType:null,
                userState:null,
                token:localStorage.getItem("a_token"),
            }
        }
    },methods:{
        showModal(){
            this.$bvModal.show('headPhotoModal')
        },
         //图片上传成功后
         uploadImgSuc (data){
            console.log("头像返回------>"+JSON.stringify(data))
            this.userInfo.userPhoto=data.url
        },
        onSubmit(){
            this.$confirm('此操作将修改用户信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.$http.post('/web/user/changeUserInfo',this.userInfo)
                        .then((res)=>{
                        if(res.data.code==200){
                            this.$message.success('修改成功！');
                            this.$emit('customEvent');
                        }else{
                            this.$message.error('修改失败！');
                        
                        }
                        })
                        .catch(function(err){
                        //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                        console.log(err);
                    
                        })


                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
                });
        },
        updatePost(){
           
           

            
        }
    },mounted(){
          
          if(this.rowData!=null){
                this.userInfo=this.rowData;
                
               
          }
        
      }
}
</script>
<style scoped>
.user_header_box{
    display: flex;
    justify-content: center;
}
.user_header_img{
    width: 100px;
    height: 100px;
    background-image: url("http://localhost/poto1.png");
    border-radius: 50%;
    overflow: hidden;
    background-size: 100%;
    background-position: center;
    border: 1px solid #efefef;
    background-color: #ccc;
    text-align: center;
}
.user_header_i{
    width: 100%;
    height: 30%;
    margin-top: 70%;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    font-size: 13px;
}
 
</style>