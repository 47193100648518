<template>
    <el-row>
    <el-col :span="24">
      <div class="index_title wow slidelinDown" data-wow-duration="2s"  data-wow-offset="10">
        <h1 >{{ftitle}}</h1>
        <i class="el-icon-minus"></i>
       
      </div>
    </el-col>
</el-row>
   
  </template>
  
  <script>
  import { WOW } from "wowjs";
  export default {
    name: 'IndexTitle',
    props: ['ftitle'],
    data() {
      return {
        title: '最新资讯',
      }
    },
    methods: {
     
    },mounted() {
        let wow = new WOW({
          boxClass: "wow", //需要执行动画的元素的 class
          animateClass: "fadeInUp", //fadeInDown 动画的 class
          offset: 10, //距离可视区域多少开始执行动画
          mobile: true, //是否在移动设备上执行动画
          live: true, //异步加载的内容是否有效
        });
        wow.init();
  },
  }
  </script>
  

  <style scoped>
 .index_title{
  margin: 5rem;
  color: #fff;
  text-align: center;
 }
 .index_title h1::after{
  content:""
 
 }
 @media all and (orientation : portrait) {
  .index_title{
    margin: 2rem;
  }
 }
  </style>
  