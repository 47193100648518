<template>
    <div>
        <b-container>
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div>
                        <div class="f_item" @click="choose(0)" :class="{active:0==activeIndex}">
                            为自己而战
                        </div>
                        <div class="f_item" @click="choose(1)" :class="{active:1==activeIndex}">
                            玩家主导经济
                        </div>
                        <div class="f_item" @click="choose(2)" :class="{active:2==activeIndex}">
                            自由建造
                        </div>
                        <div class="f_item" @click="choose(3)" :class="{active:3==activeIndex}">
                            自定义角色
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="f_item_bg" :style="`background-image: url(${bgImg});`">
                        <div class="item_title">
                            {{remark}}
                        </div>
                    </div>
                </el-col>
            </el-row>
        </b-container>
    </div>
</template>
<script>
export default {
    name: 'GameFeature',
    components:{
       
    },
    data() {
        return {
            activeIndex:0,
            bgImg:null,
            remark:null,
            item: [
                {url:'http://yujituol.com/upload/z.png',remark:'在广阔的世界里为自己征服一片领土！有了领土，公会就可以收集宝贵的虹吸能量和资源。占领土地，扩张您的帝国并保护它不被入侵！'},
                {url:'http://yujituol.com/upload/采集.png',remark:'战斗之余,回到您自己的私人岛屿，建造并打理一座农场，可以从事农耕，蓄养家禽，还能使用完整配套的全新建筑物。生产食物，也可以放到市场上出售，从而大赚一笔'},
                {url:'http://yujituol.com/img/a.png',remark:'建一座房子，然后将它布置成别具一格的家！舒适的床，堆满食物的桌子，狩猎战利品，存放贵重物品的宝箱……不断收集家具和装饰品，让您的房子有家的感觉'},
                {url:'http://yujituol.com/upload/天赋.png',remark:'要打造属于您的完美角色，您需要知道命运给您指引的不同道路。这就是天赋罗盘的意义所在！天赋罗盘向您展示关于您的一切，成为何等人物全在您的掌控之中！'},
            ],
        }
    },methods:{
        choose(index){
            this.activeIndex = index
            this.bgImg = this.item[index].url
            this.remark = this.item[index].remark
           
        }
    },mounted(){
        this.bgImg = this.item[0].url
        this.remark = this.item[0].remark
    }
}
</script>
<style scoped>
.f_item{
    color: #fff;
    text-align: center;
    margin: 2rem;
    border: 1px solid #f29921;
    padding: 1rem;
    position: relative;
    width: 80%;
    font-weight: 600;
    transition: 0.5s;
    cursor: pointer;
    z-index: 1; 
}
.active{
    background-color: #f29921;
    width: 82%;
}
.f_item::before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 height: 100%;
 width: 0;
 background-color: #f29921;
 transition: all 250ms;
 z-index: -1;
 

}
.f_item:hover{
 width: 82%;
}

.f_item:hover::before {
 width: 100%;
}

.f_item_bg{
    aspect-ratio: 5/3;
    background-position: center;
    background-size: cover;
    border: 2px solid #f29921;
    border-width: 10%;
    overflow: hidden;
    position: relative;
}

.f_item::after{
    content: "--";
    position: absolute; 
    background-color: #f29921;
    right: -14px; 
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    color: #f29921;
}
.item_title{
    color: #fff;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0;
    padding: 1rem;
    font-size: 14px;
}
</style>