<template>
    <div class="offInfo_box">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>官方资讯</span>
                <el-button style="float: right; padding: 3px 0" type="text">
                  <router-link to="/officialinfos"> 更多>></router-link>
                </el-button>
            </div>
            <div v-for="item in officialInfoList" :key="item.officialId" class="text item">
              <router-link :to="'/InfoShowView/?officialId='+ item.officialId"> <div class="offInfo_box_title">
                    {{ item.officialTitle }}
                </div></router-link>
            </div>
            </el-card>

    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: 'OffInfo',
    data() {
      return {
        page:1,
        total:10,
        officialInfoList: [],
        
      }
    },
    methods:{
      loadOfficialInfo(){
        axios({
            method: "post",//指定请求方式
            url: "/web/official/selectOfficialInfoByPage",
            data: {
                page:this.page,
                total:this.total,
                officialType:this.officialType,
            }
            })
            .then((res)=>{
            if(res.data.code==200){
                //let json = JSON.stringify(res.data.data);//tostring

                if(res.data.data.length<1){
            
                    document.getElementsByClassName("info_content_value")[0].innerHTML ="一条都没了！"
                }else{
                    //this.commentInfoList=res.data.data;
                    let resItems=res.data.data.officialInfoList;
                    
                       
                    this.officialInfoList=resItems;
                  
                   
                    
                }
                
                //console.log(json);
            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      }
    },mounted(){
         //let id =  this.$parent.moreReCommentId;
            //alert(this.ReCommentId)
            this. loadOfficialInfo();
        }
  }
  </script>
  <style scoped>
  .offInfo_box{
    min-height: 20rem;
    width: 100%;
    margin-top: 1rem;

  }
  .offInfo_box_title{
    font-size: 14px;
    color: #999;
    margin: 0.5rem 0;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 1.5rem;
    white-space: nowrap;
  }
  
 .clearfix a{
  text-decoration: none;
  color: #999;
 }
 .text a{
  text-decoration: none;
  counter-reset: #999;
 }
  </style>
  