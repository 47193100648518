<template>
    <div>
        <div class="cus_lable">
            <el-form :inline="true" :model="senWord" class="demo-form-inline">
                <el-form-item label="类型">
                    <el-input v-model="senWord.wordType" placeholder="类型"></el-input>
                </el-form-item>
                <el-form-item label="敏感词汇">
                    <el-input v-model="senWord.sensitiveWord" placeholder="敏感词汇"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <el-button type="primary" @click="loadSensitiveWordInfo">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="showAddSensitiveWord">添加</el-button>
                </el-form-item>
                <!--  -->
                <b-modal id="addSenWordModal" size="lg" centered hide-footer hide-header>
                    <el-form-item label="类型">
                        <el-input v-model="sensitiveWords.wordType" placeholder="类型"></el-input>
                    </el-form-item>
                    <el-form-item label="敏感词汇">
                        <el-input v-model="sensitiveWords.sensitiveWord" placeholder="敏感词汇"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="addSensitiveWord">添加</el-button>
                    </el-form-item>
                </b-modal>
            </el-form>
        </div>
        <div>
            <el-table
            :data="tableData"
            style="width: 100%">
                <el-table-column
                    prop="wordId"
                    label="ID"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="wordType"
                    label="类型"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="sensitiveWord"
                    label="敏感词汇">
                </el-table-column>
                <el-table-column
                    prop="wordState"
                    label="状态">
                    <template slot-scope="scope">
                        <el-switch
                        style="display: block"
                        @change="changeWordState(scope.row)"
                        v-model="scope.row.wordState"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        :active-value="1"
                        :inactive-value="2"
                        active-text="正常"
                        inactive-text="禁用">
                        </el-switch>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table_footer_page_box">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="rows">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AdminSensitiveWords',
    data() {
        return {
            tableData:null
            ,rows:0
            ,sensitiveWords:{
                wordType:null,
                sensitiveWord:null,
            }
            ,senWord:{
                page:1
                ,total:10
                ,wordType:null,
                sensitiveWord:null,
            }    
        }
    },methods:{
        showAddSensitiveWord(){
            this.$bvModal.show("addSenWordModal")
            
        }
        ,addSensitiveWord(){
            this.$http.post("/web/sensitiveWords/insertSenWords",this.sensitiveWords)
                .then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.message);
                    this.$bvModal.hide("addSenWordModal")
                    this.loadSensitiveWordInfo();
                }
                else {
                    this.$message.error(res.data.message);
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        }
        ,changeWordState(row){
            this.$http.post("/web/sensitiveWords/updateSenWordsById",row)
                .then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.message);
                }
                else {
                    this.$message.error(res.data.message);
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        }
        ,loadSensitiveWordInfo(){
        this.$http.post("/web/sensitiveWords/selectWordsByPage",this.senWord)
                .then((res) => {
                if (res.data.code == 200) {
                    let resItems = res.data.data.sensitiveWordsList;
                    this.tableData = resItems;
                    this.rows = res.data.data.rows;
                }
                else {
                    this.$message.error(res.data.message);
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        }
        ,handleCurrentChange(val) {
            this.senWord.page = Number(val);
            this.loadSensitiveWordInfo();
        },
    },mounted(){
        this.loadSensitiveWordInfo()
    }
}
</script>
<style scoped>

</style>