<template>
    <div>
        <div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <div class="card_box">
                            <div class="card_box_i custom_bgcolor_blue">
                                <b-icon class="icon_size" icon="display" variant="light"></b-icon> 
                            </div>
                            <div class="card_box_right" >
                                <div class="card_box_number">
                                    <p>访问总数</p>
                                    <h1 class="custom_color_blue" >{{visitSumRows}}</h1>
                                </div>
                                <div class="card_box_bottom">
                                    <p>今日新增：{{visitToDayRows}}</p>
                                </div>
                            </div>
                            
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="card_box">
                            <div class="card_box_i custom_bgcolor_green">
                                <b-icon class="icon_size" icon="people" variant="light"></b-icon>
                            </div>
                            <div class="card_box_right" >
                                <div class="card_box_number">
                                    <p>用户总数</p>
                                    <h1 class="custom_color_green" >{{userSumRows}}</h1>
                                </div>
                                <div class="card_box_bottom">
                                    <p>今日新增：{{userToDayRows}}</p>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="card_box">
                            <div class="card_box_i custom_bgcolor_yellow">
                                <b-icon class="icon_size" icon="file-earmark-post" variant="light"></b-icon>   
                            </div>
                            <div class="card_box_right" >
                                <div class="card_box_number">
                                    <p>发帖总数</p>
                                    <h1 class="custom_color_yellow" >{{postSumRows}}</h1>
                                </div>
                                <div class="card_box_bottom">
                                    <p>今日新增：{{postoDayRows}}</p>
                                </div>
                            </div>
                        </div>
                    
                    </el-col>
                    <el-col :span="6">
                        <div class="card_box">
                            <div class="card_box_i custom_bgcolor_red">
                                <b-icon class="icon_size" icon="chat-left-text " variant="light"></b-icon>  
                            </div>
                            <div class="card_box_right" >
                                <div class="card_box_number">
                                    <p>评论总数</p>
                                    <h1 class="custom_color_red" >{{commetnSumRows}}</h1>
                                </div>
                                <div class="card_box_bottom">
                                    <p>今日新增：{{commentDayRows}}</p>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
    </div>
</template>
<script>
  export default {
      name: 'AdminHomeTop',
      data(){
        return{
            visitSumRows:0,
            visitToDayRows:0,
            userSumRows:0,
            userToDayRows:0,
            postSumRows:0,
            postoDayRows:0,
            commetnSumRows:0,
            commentDayRows:0,
        }
      },
      methods:{
        loadTopData(){
            this.$http.post('/web/echarts/queryAllAndTodaySum')
                .then((res)=>{
                  if(res.data.code==200){
                    let data= res.data.data
                    this.visitSumRows=data.visitSumRows
                    this.visitToDayRows=data.visitToDayRows
                    this.userSumRows=data.userSumRows
                    this.userToDayRows=data.userToDayRows
                    this.postSumRows=data.postSumRows
                    this.postoDayRows=data.postoDayRows
                    this.commetnSumRows=data.commetnSumRows
                    this.commentDayRows=data.commentDayRows   
                  }else{
                    this.$message.error('查询失败！');
                   
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
        }
      },mounted() {
       this.loadTopData()
       
      }
    }
</script>
<style scope>
.card_box{
    width: 100%;
    height: 100px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    display: flex;
    align-items: center;
   
}
.icon_size{
    font-size: 70px;
    
}
.card_box_right{
    flex: auto;
    height: 100%;
    text-align:center;
}
.card_box_number p{
    font-size:13px;
    margin:0;
    color:#999;
}
.card_box_bottom{
    font-size:13px;
    margin:0;
    color:#999;
}
.card_box_i{
   
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cad_box_n{
    aspect-ratio: 1/1;
    height: 100%;
    color: #fff;
    border-radius: 0rem 3rem 0rem 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom_bgcolor_blue{
    background-color: #409eef;
}
.custom_bgcolor_green{
    background-color: #67C23A;
}
.custom_bgcolor_yellow{
    background-color: #E6A23C
}
.custom_bgcolor_red{
    background-color: #F56C6C
}
.custom_color_blue{
   color: #409eef;
}
.custom_color_green{
    color: #67C23A;
}
.custom_color_yellow{
    color: #E6A23C
}
.custom_color_red{
    color: #F56C6C
}
</style>