 
import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);

 
export default new Vuex.Store({
 
  state: {
    uploadUrl:process.env.VUE_APP_UPLOAD_URL
  },
  mutations: {
    
  },
  actions: {},
  modules: {},
});