<template>
    <div class="add_post_commet">
            <div class="add_post_commet_head">
                看帖是喜欢，评论才是真爱：
            </div>
           <div class="add_post_commet_body">
                <textarea v-model="commentContent" class="add_post_commet_input" ></textarea>
            </div>
             <div class="add_post_commet_bottom">
                <div class="add_post_comment_msg">
                    <span>{{textNumber}}/100</span>
                </div>
                <div>
                    <!-- <button class="add_post_comment_btn" >发送</button> -->
                    <el-button type="primary" @click="addComment">发送</el-button>
                </div>
            </div> 
        </div>
</template>
<script>
//import axios from "axios";
export default {
        name: 'AddPostComment',
    data(){
       return{
            commentContent:"",
            commentImgs:"",
            textNumber:0 ,
            postId:this.$route.query.postId,
        }
       
      }
      ,inject: ["fatherMethod"]
      ,methods:{
            addComment(){
                if(this.commentContent.length>1){
                    this.$http({
                        method: "post",//指定请求方式
                        url: "/web/comment-info/addComment",
                        data: {
                            postId:this.postId,
                            commentContent:this.commentContent,
                            commentImgs:this.commentImgs,
                            commentType:1,
                            token:localStorage.getItem("token"),
                            }
                    })
                    .then((res)=>{
                        if(res.data.code==200){
                            this.$message.success('评论成功！');
                            this.commentContent=""
                            this.fatherMethod();
                        }else{
                            this.$message.error(res.data.message);
                        }
                        
                    })
                    .catch(function(err){
                    //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                    console.log(err);
                
                    })
                }else{
                    this.$message.error('请输入内容');
                }
            }
          

      },
      watch: {
            /**
             * 深度监听文本内容
             */
             commentContent: {
                handler(newVal, oldVal) {
                    if (newVal != oldVal) {
                    // 文本内容变化
                    this.textNumber=newVal.length
                        if(newVal.length>100){
                            this.$message.error('内容太多了哦！');
                            this.commentContent=newVal.substring(0, 100); 
                        }

                    }
                },
                deep: true
            }
        }

}
</script>
<style scoped>
.add_post_commet{
    display: flex;
    width: 100%;
    min-height: 4rem;
    background-color: #fff;
    flex-wrap: wrap;
    padding: 4rem;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #999;
    margin-top: 1rem;
}
.add_post_commet_head{
    width:100%;
    font-size:14px;
    color:#999;
}
.add_post_commet_body{
    width:100%;
}
.add_post_commet_input{
    width: 100%;
    height: 10rem;
    border: 1px solid #ebebeb;
    border-radius: 1rem;
    padding:5px;
    color:#666;
    outline-color:#409EFF;
    font-size: 0.9rem;
}
.add_post_commet_input:focus{
    border-color:#409EFF;

}
.add_post_commet_input:hover{
    border-color:#409EFF;

}
/* textarea:focus {
  border-color: red;
} */
.add_post_commet_bottom{
    width:100%;
    display:flex;
    justify-content: flex-end;
    padding:1rem;
    color: #888;
    align-items: center;
}
.add_post_comment_btn{
    background-color: #409eff;
    color: #fff;
    padding: 10px 40px;
    cursor:pointer;
}
.add_post_comment_msg{
    padding: 0 1rem;
}
@media all and (orientation : portrait) {
    .add_post_commet{
        padding: 1rem;
    }
    /* .add_post_commet_head{
        font-size: 3rem;
    }
    .add_post_commet_input{
        height: 30rem;
        font-size: 3.3rem;
    }
   
    .add_post_comment_msg{
        font-size: 5rem;
    } */

}
</style>