<template>
    <div class="user_box">
        <el-card class="box-card">
            <div>
                <div class="box_card_img">
                    <img :src="userPhoto"/>
                </div>
                <div class="box_card_name">
                    <span>{{userName}}</span>
                </div>
            </div>    
        </el-card>
    </div>
</template>
<script>
import axios from "axios";
 export default {
      name: 'PostUserCard',
      data(){
         return{
            postId:this.$route.query.postId, 
            userName:null,
            userPhoto:null,
         }
        },mounted(){
            axios({
                    method: "post",//指定请求方式
                    url: "/web/post-info/selecPostByPostId",
                    data: {
                        postId:this.postId,
                        }
                    })
                    .then((res)=>{
                        if(res.data.code==200){
                           
                            this.userName=res.data.data.userInfo.userName
                            this.userPhoto=res.data.data.userInfo.userPhoto
                        }else{
                            this.$message.error('加载失败！');
                        }
                    })
                    .catch(function(err){
                    //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                    console.log(err);
                
                    })  

            
        }
    }
</script>
<style scoped>
.user_box{
    padding-top:1rem;
}
.box_card_img{
    width:100%;
    text-align: center;
}
.box_card_img img{
    border-radius: 50%;
    width:6rem;
    height:6rem;
}
.box_card_name{
    width:100%;
    text-align:center;
    font-size: 18px;
    font-weight: 600;
    padding-top:1rem;
}
</style>