<template>
    <div>
        <div>
            <div class="cus_lable">
                <el-form :inline="true" :model="report" class="demo-form-inline">
                <el-form-item label="举报人ID">
                    <el-input v-model="report.reportUser" placeholder="举报人ID"></el-input>
                </el-form-item>
                <el-form-item label="举报类型">
                    <el-select v-model="report.reportType" placeholder="举报类型">
                    <el-option label="不限" value=""></el-option>
                    <el-option label="帖子" value="1"></el-option>
                    <el-option label="评论" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="举报帖子ID">
                    <el-input v-model="report.reportPid" placeholder="举报帖子ID"></el-input>
                </el-form-item>
                <el-form-item label="举报评论id">
                    <el-input v-model="report.reportCid" placeholder="举报评论id"></el-input>
                </el-form-item>
                <el-form-item label="审核状态">
                    <el-select v-model="report.auditState" placeholder="审核状态">
                    <el-option label="不限" value=""></el-option>
                    <el-option label="审核中" value="1"></el-option>
                    <el-option label="已审核" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核人">
                    <el-select v-model="report.auditAdmin" placeholder="审核人">
                        <el-option  label="不限" value=""></el-option>
                        <el-option v-for="admin in adminInfos" :key="admin.adminId" :label="admin.adminName" :value="admin.adminId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="举报日期">
                    <el-date-picker
                    v-model="time"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="loadReportRecords">查询</el-button>
                </el-form-item>
                </el-form>
            </div>
        </div>
        <div>
            <template>
                <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="reportId"
                    label="ID"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="reportIp"
                    label="IP"
                    width="90">
                </el-table-column>
                <el-table-column
                    prop="userInfo.userName"
                    label="提交人">
                </el-table-column>
                <el-table-column
                    prop="reportType"
                    label="举报类型">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.reportType==1" >帖子</el-tag>
                        <el-tag v-else-if="scope.row.reportType==2" type="warning" >评论</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="reportValue"
                    label="举报描述">
                </el-table-column>
                <el-table-column
                    prop="reportTime"
                    label="举报时间">
                    <template slot-scope="scope">
                        {{scope.row.reportTime | dateFormat}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="reportPid"
                    label="举报帖子id">
                    <template slot-scope="scope">
                        <router-link target="_blank" :to="'/postcontent/?postId='+ scope.row.reportPid"> 
                            <el-button  type="text">{{scope.row.reportPid}}</el-button>
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="commentInfo.commentContent"
                    label="评论内容">
                </el-table-column>
                <el-table-column
                label="封禁状态"
                width="100">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.bannedState==1" @click="fastBanned(scope.row)" size="small">快速封禁</el-button>
                    <el-button v-else type="danger" size="small">已封禁</el-button>
                </template>
                </el-table-column>
                <el-table-column
                    prop="auditState"
                    label="审核状态">
                    <template slot-scope="scope">
                        <el-switch 
                            v-model="scope.row.auditState"
                            active-color="#13ce66"
                            inactive-color="rgb(205 205 205)"
                            active-text="已审核"
                            inactive-text="未审核"
                            :active-value="2"
                            :inactive-value="1"
                            @change="auditStateChange(scope.row)"
                            >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adminInfo.adminName"
                    label="审核人">
                </el-table-column>
                <el-table-column
                    prop="auditRemark"
                    label="审核描述">
                    <template slot-scope="scope">
                        <el-input
                        type="textarea"
                        :rows="2"
                        @blur="auditStateChange(scope.row)"
                        :value="scope.row.auditRemark"
                        v-model="scope.row.auditRemark"
                        placeholder="请输入内容"
                         >
                         </el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="auditTime"
                    label="审核时间">
                    <template slot-scope="scope">
                        {{scope.row.auditTime | dateFormat}}
                    </template>
                </el-table-column>
               
                </el-table>
            </template>
        </div>
        <div class="table_footer_page_box">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="rows">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    name:'ReportRecords',
    data() {
      return {
        time: [new Date(2023, 10, 10, 10, 10), new Date(2024, 10, 11, 10, 10)],
        rows:0,
        tableData:null,
        adminInfos:{},
        report:{
            page:1,
            total:10,
            reportUser:null,
            reportType:null,
            reportPid:null,
            reportCid:null,
            auditState:null,
            auditAdmin:null,
            bannedState:null,
            startTime:null,
            endTime:null,
        }
      }
    },methods:{
        auditStateChange(row){
            this.$http({
                method: "post",
                url: "/web/reportRecords/updateReportRecordsById",
                data: {
                    reportId: row.reportId,
                    auditState:row.auditState,
                    auditRemark:row.auditRemark,
                    token:localStorage.getItem("a_token"),
                }
            })
                .then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('修改成功！');
                    this.loadReportRecords()
                }
                else {
                    this.$message.error(res.data.message);
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        }
        
        ,fastBanned(row){
            this.$http({
                method: "post",
                url: "/web/reportRecords/updatePorOrComState",
                data: {
                    reportId: row.reportId,
                    reportType:row.reportType,
                    reportPid:row.reportPid,
                    reportCid:row.reportCid,
                    token:localStorage.getItem("a_token"),
                }
            })
                .then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('封禁成功！');
                    this.loadReportRecords()
                }
                else {
                    this.$message.error(res.data.message);
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        }
        ,handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.report.page=Number(val);
        this.loadReportRecords();
      },
        loadReportRecords(){
            this.report.startTime=this.time[0];
            this.report.endTime = this.time[1]
            this.$http.post("/web/reportRecords/selectReportByPage",this.report)
            .then((res)=>{
            if(res.data.code==200){
                this.tableData=res.data.data.reportRecordsList
              
            }else{
                this.$message.error(res.data.message);
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
        },
        loadAdminInfos(){
            this.$http.post("/web/admin-info/selectAdminInfoByPage",this.adminInfos)
            .then((res)=>{
            if(res.data.code==200){
                this.adminInfos=res.data.data.adminInfoList
               
            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
        }

    },mounted(){
        this.loadReportRecords();
        this.loadAdminInfos();
    }
}
</script>
<style scoped>



</style>