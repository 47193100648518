<template>
    <div class="index_nav">
      <b-container class="bv-example-row">
         <el-row>
         <el-col :xs="24" :sm="6" :md="4" :lg="3" class="nav_log_box">
            <router-link to="/"><img  class="nav_log" src="http://yujituol.com/logo1.png"/></router-link> 
         </el-col>
         <div v-if="phoneIcon" @click="showMinNav">
               <i  class="el-icon-s-fold showicon"></i>
         </div>
         <div class="md_content">
            <el-col :xs="24" :sm="6" :md="4" :lg="3" >
               <div class="nav_b">
                  <router-link to="/">首页</router-link> 
               </div>
            </el-col>
            <el-col :xs="24" :sm="6" :md="4" :lg="3" >
               <div class="nav_b">
                  <router-link to="/forum" >论坛</router-link> 
               </div>
            </el-col>
            <el-col :xs="24" :sm="6" :md="4" :lg="3" >
               <div class="nav_b">
                  <router-link to="/information" >资讯</router-link> 
               </div>
            </el-col>
            <el-col :xs="24" :sm="6" :md="4" :lg="3" >
               <div class="nav_b">
                  <router-link to="/strategy" >资料</router-link> 
               </div>
            </el-col>
            <el-col class="user_right" :xs="24" :sm="6" :md="4" :lg="3" >
               
               <div class="nav_b">
                  <router-link to="/login" v-if="token==null" >登录/注册</router-link>
                  <span v-else>
                     <router-link to="/usercenter"  >
                           <img  v-if="userPhoto==null" class="user_photo" src="http://yujituol.com/ucenter.png">
                           <img  v-else class="user_photo" :src="userPhoto">
                     </router-link>
                     <span class="out_login" @click="outLogin">退出</span>  
                  </span>
               </div>
            </el-col>
         </div>

         
      </el-row>
      <div v-if="phoneState" class="phone_show">
         <div>
            <router-link to="/">首页</router-link> 
         </div>
         <div>
            <router-link to="/forum">论坛</router-link> 
         </div>
         <div>
            <router-link to="/information">资讯</router-link> 
         </div>
         <div>
            <router-link to="/strategy">资料</router-link> 
         </div>
         <div>
            <router-link to="/login" v-if="token==null" >登录/注册</router-link>
               <span v-else>
                  <router-link to="/usercenter"  >
                        <img  v-if="userPhoto==null" class="user_photo" src="http://yujituol.com/ucenter.png">
                        <img  v-else class="user_photo" :src="userPhoto">
                  </router-link>
                  <span class="out_login" @click="outLogin">退出</span>  
            </span>
         </div>
         
      </div>
      </b-container>
      
    </div>
  </template>
  
  <script>

   // const htmlEl = document.documentElement
   //    function setRemUnit(){
   //       const htmlWidth = htmlEl.clientWidth
   //       const htmlFontSize = htmlWidth / 140
   //       htmlEl.style.fontSize = htmlFontSize + 'px'
   //    }
   //    setRemUnit()
   // window.addEventListener("resize",setRemUnit)

  export default {
    name: 'NavBar',
    data() {
      return {
         phoneIcon:false,
         phoneState:false,
         clientWidth:document.body.clientWidth,
         userPhoto:localStorage.getItem("userPhoto"),
         token: localStorage.getItem("token")
      }
    },
    methods:{
      goToUrl(information){
         this.$router.push({name: information});
            //indow.open('/#/postcontent/?strategyId='+strategyId, '_blank');
        },
      outLogin(){
         this.$confirm('确定要退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          localStorage.removeItem("token") 
          localStorage.removeItem("userId")
          localStorage.removeItem("data")  
          localStorage.removeItem("userPhoto")
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          this.$router.push("/forum");
          location.reload();

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      }
      ,showMinNav(){
         if(this.phoneState){
            this.phoneState=false
         }else{
            this.phoneState=true
         }
        
      }

    },mounted(){
       if(this.clientWidth<800){
         this.phoneIcon=true
        }
    }
  }
  </script>
  

  <style scoped>
   .el-row{
      display: flex;
      align-items: center;
   }

   .user_right:last-child {
            margin-left: auto;
           
        }
  .out_login{
   font-size: 1rem;
   margin-left: 10px;
  }
  .nav_log_box{
   text-align: center;
  }
  .nav_log{
     height: 100%;
     max-height: 4rem;
  }
  .user_photo{
      height: 50%;
      height: 2.7rem;
      border-radius: 50%;
  }
 .index_nav{
    background-color: #111;
    color: #fff;
    /* float: left;
    position: fixed;
    z-index: 999; */
    width: 100%;
   
    padding: 1rem 0rem;;
 }
 .nav_b{
    font-size: 1.1rem;
    text-align: center;
 }
 .nav_b a:hover{
   color: #ebebeb;
 }
 .nav_b a{
   text-decoration: none;
   list-style: none;
   color: #fff;
 }
 .md_content{
   flex: auto;
   display: flex;
    align-items: center;
 }
 .phone_show{
   text-align: center;
   transition: 0.5s;
 }
 .phone_show div{
   margin: 10px 0;
 }
 .phone_show a{
   color: #fff;
   text-decoration: none;
 }
 .showicon{
   font-size: 20px;
 }
 @media all and (orientation : portrait) {
   .md_content{
      display: none;
   }
 }

 /* @media all and (orientation : portrait) {
   .nav_log{
      max-height: 10rem;
   }
   .index_nav{
      padding: 5rem;
   }
   .nav_b{
      font-size: 5rem;
   }
   .user_photo{
      height: 10rem;
   }
   .user_photo{
      font-size: 3rem;
   }
   .out_login{
      font-size: 3rem;
   }
} */
  </style>
  