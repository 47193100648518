<template>
    <div>
        <div>
            <el-form ref="form" :model="officialInfo" label-width="80px">
                <el-form-item label="标题名称">
                    <el-input v-model="officialInfo.officialTitle"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="officialInfo.officialType" placeholder="请选择类型">
                        <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label="状态">
                    <el-select v-model="officialInfo.officialState" placeholder="请选择状态">
                        <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="推荐状态">
                    <el-select v-model="officialInfo.officialShow" placeholder="请选择推荐状态">
                        <el-option v-for="item in showOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="内容">
                    <div class="editor_box">
                        <Toolbar
                            style="border-bottom: 1px solid #ccc"
                            :editor="editor"
                            :defaultConfig="toolbarConfig"
                            :mode="mode"
                        />
                        <Editor class="editor_value"
                            style=";"
                            v-model="officialInfo.officialContent"
                            :defaultConfig="editorConfig"
                            :mode="mode"
                            @onCreated="onCreated"
                        />
                        </div>
                </el-form-item>
                <el-form-item>
                    <el-button v-if="insertState" type="primary" @click="addPost">立即创建</el-button>
                    <el-button v-else type="primary" @click="updatePost">提交修改</el-button>
                   
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    name:'AdminAddOfficial',
    components: { Editor, Toolbar },
    props: ['rowData'],
    data() {
      return {
        editor: null,
        page: 1,
        total: 10,
        rows:0,
        insertState:true,
        typeOptions: [
            {
              value: 1,
              label: '公告'
            },
            {
              value: 2,
              label: '活动'
            },
            {
              value: 3,
              label:'新闻'
            }
          ],stateOptions: [
            {
              value: 1,
              label: '正常'
            },
            {
              value: 2,
              label: '下架'
            }
          ],showOptions: [
            {
              value: 1,
              label: '不推荐'
            },
            {
              value: 2,
              label: '推荐'
            }
          ],
        officialInfo:{
            officialId:null,
            officialTitle:null,
            officialState:1,
            officialShow:1,
            officialType:1,
            officialContent:"",
            officialText:"",
            officialImgsList:"",
            token:localStorage.getItem("a_token"),
        }
        ,mode: 'default', // or 'simple'
            toolbarConfig: {
                // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
                 excludeKeys: [ 'group-video','fullScreen'/* 隐藏哪些菜单 */ ],
            },
            editorConfig: {
                withCredentials:true,//定义该属性为ture表示允许跨域访问
                autofocus:false,
                scroll:true,
                placeholder: '请输入内容...',
                maxLength:1200,
                minLength:300,
                MENU_CONF:{
                    uploadImage:{
                    fieldName:'file',
                    server:'/web/upload/adminEditroUploadImg',//后台服务器地址
                    headers: {
                        a_token: localStorage.getItem("a_token"),
                    },
                    maxFileSize: 10 * 1024 * 1024, //
                    // maxNumberOfFiles: 200,
                    allowedFileTypes: ['image/*'],
                    timeout: 20 * 1000, 
                    maxNumberOfFiles: 1,
                    // 自定义插入图片
                    customInsert: (res, insertFn) => {
                        console.log("res-->"+JSON.stringify(res))
                        if (res.code == 200) {
                            // 从 res 中找到 url alt href ，然后插入图片
                            //insertFn(url, alt, href)
                            insertFn(res.data.data.url,"",res.data.data.href)
                        
                        } else {
                            this.$message.error(res.message);
                        }
                    },

                    onFailed:(file, res)=> {           // JS 语法
                        this.$message.error("上传失败,"+res);
                    },
                    onError:(file, err, res)=> {      
                        console.log(this) 
                        console.log(`${file.name} 上传出错`, err, res)
                        this.$message.error("上传出错:"+err);
                    }

                    }
                }
            }
      };
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        addPost(){
            let images = this.editor.getElemsByType('image')
            var arr = []
            images.forEach(element => {
                let  res = element.src
                arr.push(res);
              
            });
            this.officialInfo.officialImgsList=arr;
            this.officialInfo.officialText=this.editor.getText();
            this.officialInfo.token=localStorage.getItem("a_token")
            if(this.officialInfo.officialTitle!=null&&this.officialInfo.officialTitle!=""){
                 this.$http.post('/web/official/insertOfficialInfo',this.officialInfo)
                .then((res)=>{
                  if(res.data.code==200){
            
                    this.$message.success('发布成功！');
                  }else{
                    this.$message.error('发布失败！');
                   
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
            }else{
                this.$message.error('请填写标题');
            }

            
        },
        updatePost(){
            let images = this.editor.getElemsByType('image')
            var arr = []
            images.forEach(element => {
                let  res = element.src
                arr.push(res);
              
            });
            this.officialInfo.officialImgsList=arr;
            this.officialInfo.officialText=this.editor.getText();
            this.officialInfo.token=localStorage.getItem("a_token")
            if(this.officialInfo.officialTitle!=null&&this.officialInfo.officialTitle!=""){
                 this.$http.post('/web/official/updateOfficialInfo',this.officialInfo)
                .then((res)=>{
                  if(res.data.code==200){
                    this.$message.success('修改成功！');
                    this.$emit('customEvent');
                  }else{
                    this.$message.error('修改失败！');
                   
                  }
                })
                .catch(function(err){
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                  console.log(err);
              
                })
            }else{
                this.$message.error('请填写标题');
            }

            
        }
        ,officialTitleLength(){
            let titleLength = this.postTitle.length
            if(titleLength>40){
                this.$message.error("标题太长")
                this.postTitle=this.postTitle.substring(0,40)
            }
        }
    },mounted(){
          
          if(this.rowData!=null){
                this.officialInfo=this.rowData;
                setTimeout(() => {
                    let officialContent = this.rowData.officialContent
                    console.log("officialContent---->"+officialContent)
                    this.officialInfo.officialContent =officialContent+" "
                    this.insertState=false
                }, 500)
               
          }else{
            this.insertState=true
          }
        
      }
    ,beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    
}
</script>
<style scoped>
.editor_box{
    min-height: 500px;
    border: 1px solid #ccc;
}

</style>