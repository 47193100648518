<template>
    <div>
        <NavBar></NavBar>
        <b-container>
            <b-row>
                <div class="row_box">
                    <el-carousel indicator-position="none" :interval="4000" type="card" :height="carouselWidth" >
                        <el-carousel-item v-for="item in showItems" :key="item.officialId"  >
                            <div class="carousel_img" :style="`background-image: url(${item.officialImgsList[0]});`" @click="goToUrl(item.officialId)">
                                <div class="carousel_title">{{item.officialTitle}}</div>    
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </b-row>
            <b-row>
                <b-col md="9"  sm="12">
                   <div >
                        <div class="content_title">
                            <div>公告</div>
                            <div class="title_r">
                                <router-link to="/officialinfos">>>></router-link>
                            </div>
                        </div>
                        <div class="content_item wow slidelinDown" data-wow-duration="2s"  data-wow-offset="10"  v-for="item in announcement" :key="item.officialId" @click="goToUrl(item.officialId)">
                            <div class="item_title">
                               {{item.officialTitle}}
                               
                            </div>
                            <div class="item_box">
                                <div class="item_img" :style="`background-image: url(${item.officialImgsList[0]});`" >

                                </div>
                                <div class="item_text">
                                    <div class="span_time">{{item.officialTime |dateFormat}}</div>
                                    <div class="item_t">
                                        {{item.officialText}}
                                    </div>
                                   
                                </div>
                               
                            </div>
                        </div>
                        <div class="load-more-box">
                            <span class="load-more-text" v-if="moreState" @click="nextPage()">加载更多内容</span>
                        </div>
                   </div>
                </b-col>
                <b-col md="3"  sm="12">
                    <el-row>
                        <el-col :md="24" :xs="12">
                        <div class="content_title">
                            <div >活动</div>
                            <div class="title_r">
                                <router-link to="/officialinfos">>>></router-link>
                            </div>
                        </div>
                        <div class="item_r wow slidelinDown" data-wow-duration="2s"  data-wow-offset="10" v-for="item in activity" :key="item.officialId" @click="goToUrl(item.officialId)">
                            <div class="item_title">
                                {{item.officialTitle}}
                            </div>
                            <div class="item_img" :style="`background-image: url(${item.officialImgsList[0]});`">
                            </div>
                            <div>
                                <div class="span_time">{{item.officialTime |dateFormat}}</div>
                                <div class="r_text">
                                    {{item.officialText}}
                                </div>
                            </div>
                            <!--  -->
                        </div>
                    </el-col>
                     <!--  -->
                     <el-col :md="24" :xs="12">
                   
                        <div class="content_title">
                            <div>新闻</div>
                            <div class="title_r">
                                <router-link to="/officialinfos">>>></router-link>
                            </div>
                        </div>
                        <div class="item_r wow slidelinDown" data-wow-duration="2s"  data-wow-offset="10" v-for="item in news" :key="item.officialId" @click="goToUrl(item.officialId)">
                            <div class="item_title">
                                {{item.officialTitle}}
                            </div>
                            <div class="item_img" :style="`background-image: url(${item.officialImgsList[0]});`">
                            </div>
                            <div>
                                <div class="span_time">{{item.officialTime |dateFormat}}</div>
                            </div>
                        </div>
                    </el-col>
                     </el-row>
                </b-col>
            </b-row>
           
        </b-container>
        <PageBottom></PageBottom>
    </div>
</template>
<script>
   
import NavBar from '@/components/NavBar.vue'
import PageBottom from '@/components/PageBottom.vue';
import { WOW } from "wowjs";
export default {
  name: 'informationView',
  components: { 
    NavBar,
    PageBottom,
  },data() {
      return {
        carouselWidth:'20rem',
        moreState:true,
        clientWidth:document.body.clientWidth,
        showItems: [],
        url:'http://yujituol.com/img/a.png',
        official:{
            page:1,
            total:5,
            officialType:1
        },
        rows:0,
        announcement:[],
        activity:[],   
        news:[],   
      }
    },methods:{
        goToUrl(officialId){
            this.$router.push({path:'InfoShowView',query:{'officialId':officialId}})
            //window.open('/#/InfoShowView/?officialId='+officialId, '_blank');
        },
        loadOfficialShow(){
        this.$http.post("/web/official/selectOfficialInfoShow")
            .then((res)=>{
            if(res.data.code==200){
                if(res.data.data.length<1){
                    this.$refs.hide.style.display="block";
                    
                }else{
                    let resItems=res.data.data;  
                    this.showItems=resItems;
                }
               
            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      }
      ,loadOfficialInfo(type){
        this.official.officialType=type
        this.$http.post("/web/official/selectOfficialInfoByPage",this.official)
            .then((res)=>{
            if(res.data.code==200){
                let resItems=res.data.data.officialInfoList; 
                if(type==1){
                    this.announcement=this.announcement.concat(resItems)
                }else if(type==2){
                    this.activity=resItems
                }else  if(type==3){
                    this.news=resItems
                }
                if(this.official.page*this.official.total>res.data.data.rows){
                    this.moreState=false
                }else{
                    this.moreState=true
                }

            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      },
      nextPage(){
        this.official.page=this.official.page+1;
        this.loadOfficialInfo(1)
      }
    }
    ,watch:{ 
        clientWidth(newVal)
        {
            if(newVal<800){console.log("800");
                this.carouselWidth='40rem'
            }
        } 
    }
    ,mounted() {
        this.loadOfficialShow();
        //1 公告 2 活动 3 新闻
       this.loadOfficialInfo(1);
        this.loadOfficialInfo(2);
         this.loadOfficialInfo(3);

        if(this.clientWidth<800){
                console.log("800");
                this.carouselWidth='10rem'
        }
        setTimeout(function(){
            let wow = new WOW({
          boxClass: "wow", //需要执行动画的元素的 class
          animateClass: "fadeInUp", //fadeInDown 动画的 class
          offset: 10, //距离可视区域多少开始执行动画
          mobile: true, //是否在移动设备上执行动画
          live: true, //异步加载的内容是否有效
        });
        wow.init();
        },100)
       
       
       
  },
}
</script>


<style scoped>
.row_box{
    margin: 2rem 0rem;
   
}
.info_bg{
    width: 100%;
    background-color: antiquewhite;
    height: 100%;
}
.content_title{
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    color: #555;
    font-size: 1.3rem;
    padding-left: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.item_title{
    font-size: 1.1rem;
   
    font-weight: 600;
}
.content_item{
    border: 1px solid #dfdfdf;
    padding: 1rem;
    margin: 1rem 0;
    box-shadow: 2px 2px 2px #dfdfdf;
    cursor: pointer;
    transition: 0.8s;
}
.content_item:hover{
    box-shadow: 5px 5px 5px #dfdfdf;
}
.carousel_img{
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
}
.carousel_title{
    text-align: center;
    color: #fff;
    padding: 0.5rem 0px;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.item_box{
    display: flex;
}
.item_img{
    flex: 1;
    aspect-ratio: 5/3;
    background-position: center;
    background-size: cover;
}
.item_text{
    flex: 1;
    margin: 1rem;
    color: #333;
    
}
.span_time{
    font-size: 0.8rem;
    color: #c63000;
}
.item_t{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 6rem;
    line-height: 1.5rem;
}
.item_r{
    padding: 1rem;
    border: 1px solid #dfdfdf;
    margin: 1rem 0;
    box-shadow: 2px 2px 10px #dfdfdf;
    cursor: pointer;
}
.r_text{
    color: #888;
    font-size: 0.8rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 2rem;
    line-height: 1rem;
}
.title_r{
    margin-left: auto;
    font-size: 0.8rem;
    padding-right: 1rem;
    cursor: pointer;
}
.title_r a{
    color: #666;
    text-decoration: none;
}
.title_r a:hover{
    color: #c63000;
}
.load-more-box{
    margin-top: 2rem;
    text-align: center;
    color: #666;
    cursor: pointer;
}
.load-more-text:after{
    display: inline-block;
    content: "";
    margin-left: 6px;
    border: 4px solid transparent;
    border-top-color: #c63000;
}
@media all and (orientation : portrait) {
    /* .el-carousel__container{
        height: 100px;
    }
    .carousel_title{
        padding: 5.5rem 0px;
        font-size: 4rem;
    }
    .content_title{
        font-size: 6rem;
    }
    .title_r{
        font-size: 4rem;
    }
    .item_title{
        font-size: 5rem;
    }
    .span_time{
        font-size: 3rem;
    }
    .item_text{
        font-size: 3.8rem;
    }
    .item_t{
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 20rem;
        line-height: 5rem;
        color: #555;
       
    }
    .r_text{
        font-size: 3.8rem;
        height: 5rem;
          line-height: 4rem;

    }
    .load-more-text{
        font-size: 5rem;
    }
    .load-more-box{
        margin: 5rem;
    } */
}
</style>