<template>
    <div>
        <el-container style="min-height:100vh">
            <el-header>
                <AdminTopNav></AdminTopNav>
            </el-header>
            <el-container>
                <el-aside width="200px">
                    <AdminLeftNav  @child-event="choose"></AdminLeftNav>
                </el-aside>
                <el-container>
                <el-main>
                    <router-view/>
                </el-main>
                <el-footer></el-footer>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import AdminTopNav from '@/components/admin/AdminTopNav.vue'  
import AdminLeftNav from '@/components/admin/AdminLeftNav.vue' 
  
    export default {
    name: 'AdminView',
    components:{
        AdminTopNav,
        AdminLeftNav,
    },
    data(){
    return{
       index:0,
      //currentComponents:[AdminHome,AdminUserInfo,AdminPost,AdminComment,AnnounceList,AnnounceList,AdminAddOfficial,AdminUser,AdminVisit,ReportRecords],
      adminPhone: "",
      adminPassword:1,
    }
  },methods:{
    choose(res){
          console.log("res------->"+res)
          this.index=Number(res-1)
        }
  }
}
</script>
<style>

</style>