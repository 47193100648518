<template>
   <div>
      <div style="display: flex;justify-content: center;">
        <b-container>
          <el-row >
                <el-col :xs="24" :sm="24" :md="24" :lg="12">
                    <div class="an_img_show">
                      <el-carousel  direction="vertical" :autoplay="false" height="100%" style="height: 100%;">
                          <el-carousel-item class="annunciator_blg" v-for="item in showItems" :key="item.officialId" :style="`background-image: url(${item.officialImgsList[0]});background-size: cover;`">
                          </el-carousel-item>
                      </el-carousel>
                    </div>
                </el-col>
                
                <el-col :xs="24" :sm="24" :md="24" :lg="12">
                    <div class="an_content">
                        <div class="tab_box">
                            <div class="ul_tabs pad_left">
                                <div :class="{blueColor:0==officialType}" @click="chooseType(item.number)"  v-for="item in officialTypeList" :key="item.number">
                                    {{item.title}}
                                </div>
                            </div> 
                            <div class="tab_box_right">
                              <router-link to="/officialinfos">更多>></router-link>
                             
                            </div>
                        </div>
                        <div class="tab_box wow" data-wow-duration="1s">
                            <div class="noListMsg" ref="hide">暂无数据</div>
                            <ul class="ul_item">
                                <li  v-for="item in officialInfoList" :key="item.officialId" class=" wow slidelinDown" data-wow-duration="2s"  data-wow-offset="10">
                                  <!-- <router-link :to="'/show/?officialId='+ item.officialId"> <div class="ul_item_type"> -->
                                  <router-link :to="'/InfoShowView/?officialId='+ item.officialId">
                                    <div class="router_box">
                                        <div class="ul_item_type">
                                              <span v-if="item.officialType==1">
                                              【公告】
                                            </span>
                                            <span v-if="item.officialType==2">
                                              【活动】
                                            </span>
                                            <span v-if="item.officialType==3">
                                              【新闻】
                                            </span>
                                      </div>
                                      <div class="ul_item_title">
                                          {{item.officialTitle}}
                                      </div>
                                      <div class="ul_item_time">
                                          {{item.officialTime |dateFormat}}
                                      </div>
                                    </div>
                                  
                                  </router-link>
                                </li>
                                
                            </ul> 
                        </div> 

                           
                    </div>
                </el-col>
            </el-row>
          </b-container>
      </div>
  </div>    
</template>
  
  <script>
import { WOW } from "wowjs";
import axios from "axios";
  export default {
    name: 'AnnunciatorBoards',
    data() {
      return {
        activeName: 'second',
        page: 1,
        total:7,
        showItems:[],
        officialTypeList:[
            {number:0,title: '最新' },
            {number:1,title: '公告' },
            {number:2,title: '活动' },
            {number:3,title: '新闻' },
            ] ,
        officialType: 0 ,     
        officialInfoList:[]
      }
    },
    methods: {
      chooseType(index) {
        this.officialType = index;
        this.loadOfficialInfo()
      },loadOfficialInfo(){
        axios({
            method: "post",//指定请求方式
            url: "/web/official/selectOfficialInfoByPage",
            data: {
                page:this.page,
                total:this.total,
                officialType:this.officialType,
            }
            })
            .then((res)=>{
            if(res.data.code==200){
                if(res.data.data.length<1){
                    this.$refs.hide.style.display="block";
                }else{
                    //
                    let resItems=res.data.data.officialInfoList; 
                    this.officialInfoList=resItems;
                }
            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      }
      ,loadOfficialShow(){
        this.$http.post("/web/official/selectOfficialInfoShow")
            .then((res)=>{
            if(res.data.code==200){
                if(res.data.data.length<1){
                    this.$refs.hide.style.display="block";
                    
                }else{
                    let resItems=res.data.data;  
                    this.showItems=resItems;
                }
               
            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      }
    },
    mounted(){
        let wow = new WOW({
          boxClass: "wow", //需要执行动画的元素的 class
          animateClass: "fadeInDown", //fadeInDown 动画的 class
          offset: 10, //距离可视区域多少开始执行动画
          mobile: true, //是否在移动设备上执行动画
          live: true, //异步加载的内容是否有效
        });
        wow.init();

        this.loadOfficialInfo();
        this.loadOfficialShow();
        
  }
    
  }
  
  </script>
  

  <style scoped>
  .annunciator_blg{
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .an_content{
    color: #fff;
    height: 100%;
    aspect-ratio: 5/3;
    float: left;
    width: 100%;
  }
  .an_img_show{

    aspect-ratio: 5/3;

  }
  .tab_box{
   
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .ul_tabs{
    float: left;
    color: #fff;
  }
  .pad_left{
    padding-left: 2rem;
  }
  .ul_tabs {
    overflow: hidden;
  }
  .ul_tabs div{
    float: left;
    padding-right: 1rem;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
  }
  .ul_tabs div::before{
    content: "|";
  }
  .an_content div{
    list-style: none;
    text-align: left;
    cursor: pointer;
  }
 
  .ul_item{
    float: left;
    width: 100%;
    
  }
  .router_box{
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    /* height: 2rem; */
    white-space: nowrap;
  }
  .ul_item li{
    line-height: 2.5rem;
    font-size: 0.9rem;
    float: left;
    width: 100%;
    border-bottom: 1px solid;
    list-style: none;
    cursor: pointer;
  }
  .ul_item li div:hover{
    /* line-height:41px; */
    /* box-shadow: 2px 0px #fff; */
    color: rgb(241, 180, 100);
  }

.ul_item_type{
    
float: left;
}
.ul_item_title{
    float: left;
   

}
.ul_item_time{
  margin-left: auto;

}
.ul_item a{
  text-decoration: none;
  color: #fff;
}
.tab_box_right{
  font-size: 0.8rem;
  color: #fff;
}
.tab_box_right:last-child {
            margin-left: auto;
           
        }
.tab_box_right a{
  text-decoration: none;
  color: #fff;
}
.noListMsg{
  display: none;
  text-align: center;
}
@media all and (orientation : portrait) {
  .an_content{
    margin-top: 2rem;
  }
  .pad_left{
    padding: 0;
  }
  .ul_item{
    padding: 0;
  }
}

/* @media all and (orientation : portrait) {

.ul_item li{
  font-size: 8px;
  line-height: 20px;
}
.ul_tabs li{
  font-size: 5rem;
}
.tab_box_right{
  font-size: 3rem;
  padding-top: 0;
}
.ul_tabs div{
  font-size: 4rem ;
}
.an_content{
  margin-top: 14rem;
}

} */



  </style>
  