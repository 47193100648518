<template>
    <div class="content_list_bg" >
      <div v-for="item in topPostInfo" :key="item.postId" class="content_item_box" @click="open(item.postId)">
          <div class="item_head">
              <div>
                <img v-if="item.userInfo.userPhoto!=null" class="item_head_img" :src="item.userInfo.userPhoto">
                <img v-else class="item_head_img" src="http://yujituol.com/poto1.png">
              </div>
              <div>
                <span class="item_head_name">              
                  {{item.userInfo.userName}}
                </span>
              </div>
              <div class="item_head_right">
                
                  <!-- <div v-if="item.postState==2" class="item_head_right_bg bg_red">
                    置顶
                  </div> -->
                  <div v-if="item.postType==2" class="item_head_right_bg bg_red">
                      BUG
                    </div>
                    <div v-else-if="item.postType==3" class="item_head_right_bg bg_blue">
                      活动
                    </div>
              </div>
          </div>
          <div class="item_content">
              <div class="item_content_title">
                    {{item.postTitle}}
              </div>
              <div class="item_content_value">
                    {{item.postText}}
              </div>
              <div class="item_content_img">
                  <div v-if="item.imagesList!=''" style="display: flex;">
                      <div v-for="(img,index) in item.imagesList" :key="index">
                          <div v-if="index<4"  class="item_content_img_box" :style="`background-image: url(${img});`">
                          </div> 
                      </div>
                  </div>
                  
              </div>
          </div>
          <div class="item_bottom">
            <div class="item_bottom_right">
              <div class="item_bottom_right_i">
                <b-icon icon="chat-right-dots"></b-icon><span class="total"> {{item.commentNumber}}</span>
              </div>
              <div class="item_bottom_right_i">
                <b-icon icon="hand-thumbs-up"></b-icon><span class="total"> {{item.likeNumber}}</span>
              </div>
            </div>
          </div>    
      </div>
      <!--  -->
      <div v-for="item in items" :key="item.postId" class="content_item_box" @click="open(item.postId)">
          <div class="item_head">
              <div>
                <img v-if="item.userInfo.userPhoto!=null" class="item_head_img" :src="item.userInfo.userPhoto">
                <img v-else class="item_head_img" src="http://yujituol.com/poto1.png">
              </div>
              <div>
                <span class="item_head_name">              
              
                  {{item.userInfo.userName}}
                </span>
              </div>
              <div class="item_head_right">
                  <!-- <div v-if="item.postType==1" class="item_head_right_bg bg_black">
                    普通
                  </div> -->
                  <div v-if="item.postType==2" class="item_head_right_bg bg_red">
                    BUG
                  </div>
                  <div v-else-if="item.postType==3" class="item_head_right_bg bg_blue">
                    活动
                  </div>
              </div>
          </div>
          <div class="item_content">
              <div class="item_content_title">
                    {{item.postTitle}}
              </div>
              <div class="item_content_value">
                    {{item.postText}}
              </div>
              <div class="item_content_img">
                  <div v-if="item.imagesList!=''" style="display: flex;">
                      <div v-for="(img,index) in item.imagesList" :key="index">
                          <div v-if="index<4"  class="item_content_img_box" :style="`background-image: url(${img});`">
                          </div> 
                      </div>
                  </div>
                  
              </div>
          </div>
          <div class="item_bottom">
            <div class="item_bottom_right">
              <div class="item_bottom_right_i">
                <b-icon icon="chat-right-dots"></b-icon><span class="total"> {{item.commentNumber}}</span>
              </div>
              <div class="item_bottom_right_i">
                <b-icon icon="hand-thumbs-up"></b-icon><span class="total"> {{item.likeNumber}}</span>
              </div>
            </div>
          </div>    
      </div>
      <!--  -->
      <div style="text-align: center;">
          <i  v-if="loadingState" class="el-icon-loading" style="font-size: 2rem;"></i>
          <span v-else >已经到底了</span>
      </div>
  </div>
</template>
  
  <script>
  import axios from "axios";
  // import ForumLeft from '@/components/Forum/ForumLeft.vue';
  // import OffInfo from '@/components/Forum/OfficialInformation.vue';
  // import HotActivity from '@/components/Forum/HotActivity.vue';
  
  export default {
    name: 'ContentList',
    components: {
     // ForumLeft,
      // OffInfo,
      // HotActivity
    },
    props: ["oType", "pType"],
    data() {
      return {
        page:1,
        total:5,
        number:0,
        //orderByType:this.$parent.forumType,
        //postType:this.$parent.postType,
        items: [],
        topPostInfo:null,
        loadingState:true
      }
    },
    computed:{

      
    }
    ,methods:{
      open(postId){
        window.open('/#/postcontent/?postId='+postId, '_blank');
      },
      
      loadPost(){
          axios({
            method: "post",//指定请求方式
            url: "/web/post-info/selectPostInfoByPage",
            data: {
              page:this.page,
              total:this.total,
              orderByType:this.oType,
              postType:this.pType
            }
          })
          .then((res)=>{
            if(res.data.code==200){
              this.number = res.data.data.total;
              let resItems=  res.data.data.postInfoList;
              this.items=this.items.concat(resItems)
              if(this.items.length<4){
                this.loadingState=false
              }

            }else{
              this.$message.error('加载失败！');
            }
          })
          .catch(function(err){
          //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
          })  
        }
      //
      ,scrollLoad(){
          this.throttle(5000)
        } 
      ,throttle(delay) {

        //console.log("throttle请求。。。")
          let timer = null
          // 这里返回的函数是每次用户实际调用的节流函数 
          if (!timer) { //判断timer是否有值,如果没有则说明定时器不存在即可继续执行
              timer = setTimeout(() => { //关
                this.posturl();        
                timer = null; //开
              }, delay)
            }
        }
       ,posturl(){ 
            let scrollTop = document.documentElement.scrollTop;//滚动高度
            let clientHeight = document.documentElement.clientHeight;//可视高度
            let scrollHeight = document.documentElement.scrollHeight;//内容高度
            if(scrollTop+clientHeight>scrollHeight-700){
                //加载 判断是否还有数据
                  let a = Number(this.page)*Number(this.total);
                  
                  if(this.number >a){
                    
                    if(this.loadingState){
                      this.page=this.page+1

                      this.loadPost()
                    }
                  }else{
                    this.loadingState=false
                    //console.log("没数据了")
                  }
            }
       }
       
    }
    
    ,mounted(){
      window.addEventListener('scroll', this.scrollLoad,true); // 添加滚动事件监听器
      
      //alert("sortType"+this.oType+"postType"+this.pType)
      //this.$message.success("sortType"+this.oType+"postType"+this.pType);
      this.loadPost();//加载
     
    
  }}

   
  </script>
  

  <style scoped>
  .content_list_bg{
   
    min-height: 70rem;
    width: 100%;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
   
  }
  .content_list_right{
    min-height: 70rem;
   
  }
  .content_item_box{
    width: 100%;
    min-height: 10rem;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    margin: 1rem 0rem;
    background-color: #fff;
    border-radius: 1rem;
  }
  .item_head{
    height: 4rem;
    /* border: 1px solid #888; */
    line-height: 4rem;
    display: flex;
    align-items: center;
  }
  .item_head_img{
     width: 2.5rem;
     height: 2.5rem;
     border-radius: 50%;
     margin: 0 0.5rem;
  }
  .item_head_name{
    color: #333;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
  }
  .item_head_name:hover{
    color: rgb(42, 152, 255);
  }
  .item_head_right{
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;
    justify-content: flex-end;
    
  }
  .item_head_right_bg{
    float: right;
    width: 4rem;
    text-align: center;
    color: #fff;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .bg_red{
    background-color: #F56C6C;
  }
  .bg_blue{
    background-color: #409EFF;
  }
  .bg_black{
    background-color: #909399;
  }
  .item_content{
    width: 100%;
    padding: 1rem;
  }
  .item_content_title{
    font-size: 20px;
    font-weight: 900;
    color: #333;
    cursor: pointer;
  }
  .item_content_value{
    font-size: 0.9rem;
    color: #999;
    margin: 0.5rem 0;
    width: 60%;
    text-overflow:ellipsis;
    overflow: hidden;
    height: 2rem;
    white-space: nowrap;
  }
  .item_content_img_box{
    height: 10rem;
    width: 10rem;
    overflow: hidden;
    border-radius: 1rem;
    display: inline-block;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    /* box-shadow: 1px 1px 2px #555; */
  }
  .item_bottom{
    display: flex;
    padding: 1rem;
  }
  .item_bottom_right{
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    display: flex;
    color: #ccc;
   
  }
  .item_bottom_right_i{
    display: flex;
    width: 7rem;
    height: 15px;
    line-height: 15px;
    margin: 0 10px;
    align-items: center;
  }
  .total{
    padding: 0 5px;
  }
  @media all and (orientation : portrait) {
    .item_content_img_box{
      width: 5rem;
      height: 5rem;
    }
  }
  /* @media all and (orientation : portrait) {

   .content_item_box{
    min-height: 40rem;
    margin: 5rem 0;
   }
    .item_head{
      height: 10rem;
      line-height: 10rem;
      
    }
    .item_head_img{
      width: 8rem;
      height: 8rem;
      margin: 2rem;
    }
   .item_head_name{
    font-size: 4rem;
    line-height:11rem
   }
   .item_content_title{
    font-size: 4.5rem;
    font-weight: 500;
    padding: 1rem 4rem;
   }
   .item_head_right_bg{
    width: 10rem;
    border-bottom-left-radius:5rem;
    font-size: 2rem;
   }
   
   .item_content_value{
    height: 10rem;
    font-size: 3.5rem;
    width: 100%;
    padding: 0 4rem;
   }
   .item_content_img{
    padding: 0 4rem;
   }
   .item_content_img_box{
    height: 25rem;
    width: 25rem;
    margin-right:2rem
   }
   .item_bottom_right_i{
    font-size: 4rem;
   }
} */
  </style>
  