<template>
    <div>
        <div>
            <div class="cus_lable">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="ID">
                        <el-input v-model="officialId" placeholder="ID"></el-input>
                    </el-form-item>
                    <el-form-item label="添加人">
                        <el-select v-model="officialAdminId" placeholder="添加人">
                            <el-option label="不限" value="0"></el-option>
                            <el-option v-for="admin in adminInfo" :key="admin.admin_id" :label="admin.adminName" :value="admin.adminId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="标题">
                        <el-input v-model="officialTitle" placeholder="标题"></el-input>
                    </el-form-item>

                    <el-form-item label="类型">
                        <el-select v-model="officialType" placeholder="类型">
                        <el-option label="不限" value="0"></el-option>
                        <el-option label="公告" value="1"></el-option>
                        <el-option label="活动" value="2"></el-option>
                        <el-option label="新闻" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="officialState" placeholder="状态">
                        <el-option label="不限" value="0"></el-option>
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="置顶" value="2"></el-option>
                        <el-option label="异常" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="展示状态">
                        <el-select v-model="officialShow" placeholder="展示状态">
                        <el-option label="不限" value="0"></el-option>
                        <el-option label="不展示" value="1"></el-option>
                        <el-option label="展示" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间">
                        <el-date-picker
                            v-model="time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @keydown.enter="onSubmit" @click="onSubmit">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>

        <div>
            <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="officialId"
                    label="ID"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="adminInfo.adminName"
                    label="添加人"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="officialTitle"
                    label="标题">
                </el-table-column>
                <el-table-column
                    prop="officialText"
                    label="内容"
                    width="280">
                    <template slot-scope="scope">
                      <div class="table_cell">
                        {{scope.row.officialText}}
                      </div>
                    </template>

                </el-table-column>
                <el-table-column
                    prop="officialImgsList"
                    label="图片">
                    <template v-if="scope.row.officialImgsList[0]!=''" slot-scope="scope">
                        <el-image 
                             class="table_img"
                            :src="scope.row.officialImgsList[0]" 
                            :preview-src-list="scope.row.officialImgsList">
                        </el-image>    
                           <!-- <img class="table_img" v-for="(img,index) in scope.row.officialImgsList" :key="index" :src="img"> -->
                    </template>
                </el-table-column>
                <el-table-column
                    prop="officialType"
                    label="类型">
                    <template slot-scope="scope">
                            <el-tag v-if="scope.row.officialType==1" >公告</el-tag>
                            <el-tag v-else-if="scope.row.officialType==2" type="warning" >活动</el-tag>
                            <el-tag v-else type="success">新闻</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="officialState"
                    label="状态">
                    <template slot-scope="scope">
                            <el-tag v-if="scope.row.officialState==1" >正常</el-tag>
                            <el-tag v-else-if="scope.row.officialState==2" type="danger" >下架</el-tag>
                            <el-tag v-else type="success">活动</el-tag>
                        </template>
                </el-table-column>

                <el-table-column
                    prop="officialShow"
                    label="展示状态">
                    <template slot-scope="scope">
                            <el-tag v-if="scope.row.officialShow==1" >普通</el-tag>
                            <el-tag v-else-if="scope.row.officialShow==2" type="warning" >推荐</el-tag>
                            
                        </template>
                </el-table-column>
                <el-table-column
                    prop="officialTime"
                    label="发布时间">
                    <template slot-scope="scope">
                            {{scope.row.officialTime| dateFormat}}
                        </template>

                </el-table-column>
                <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <router-link target="_blank" :to="'/show/?officialId='+ scope.row.officialId"> 
                        <el-button  type="text" size="small">查看</el-button>
                    </router-link>
                    <el-button type="text" @click="showModeal(scope.row)" size="small">编辑</el-button>
                </template>
                </el-table-column>
                </el-table>
        </div>
        <div class="table_footer_page_box">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="rows">
            </el-pagination>
        </div>
        <!--  -->
        <b-modal id="offModal" size="xl" centered  scrollable title="编辑" hide-footer hide-header>
           <AdminAddOfficial  @customEvent="refLoad" :rowData='this.rowData'></AdminAddOfficial>
        </b-modal>
    </div>
</template>
<script>
import AdminAddOfficial from '@/components/admin/announce/AdminAddOfficial.vue'  
export default {
    name:'AnnounceList',
    components:{
        AdminAddOfficial
    },
   
    data() {
      return {
        page: 1,
        total: 10,
        rows:0,
        officialId:null,
        officialAdminId:null,
        officialTitle:null,
        officialType:null,
        officialState:null,
        officialShow:null,
        time: [new Date(2023, 10, 10, 10, 10), new Date(2024, 11, 11, 10, 10)],
        officialTime:null,
        endTime:null,
        adminInfo:[],
        tableData: [],
        rowData:null
        
      };
    },provide() {
        return {
            childData: this.total
        }
    },
    methods: {
        onSubmit() {
            this.loadOffInfo();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page=Number(val);
        this.loadOffInfo();
      },
      showModeal(rowData){
        this.$bvModal.show('offModal')
        this.rowData=rowData

      }
      ,refLoad(){
        this.$bvModal.hide('offModal')
        this.loadOffInfo()
      }
      ,loadOffInfo(){
        this.$http({
            method: "post",//指定请求方式
            url: "/web/official/selectAdmOffByPage",
            data: {
                page:this.page,
                total:this.total,
                officialId:this.officialId,
                officialAdminId:this.officialAdminId,
                officialTitle:this.officialTitle,
                officialType:this.officialType,
                officialState:this.officialState,
                officialShow:this.officialShow,
                officialTime:this.time[0],
                endTime:this.time[1],
                
            }
            })
            .then((res)=>{
            if(res.data.code==200){
                let resItems=res.data.data.officialInfoList; 
                this.tableData=resItems;
                this.rows=res.data.data.rows;
            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      },loadAdmInfo(){
      
        this.$http({
            method: "post",//指定请求方式
            url: "/web/admin-info/selectAdminInfoByPage",
            data: {
                page:1,
                total:100,
               
            }
            })
            .then((res)=>{
                console.log(res.data)
            if(res.data.code==200){
                this.adminInfo=res.data.data.adminInfoList;
                   console.log(this.adminInfo)
            }else{
                this.$message.error('admin加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      }
    },mounted(){
            this.loadAdmInfo();
            this.loadOffInfo();
        
      }
  }
</script>
<style>
.cus_lable{ 
    border: 1px solid #efefef;
    margin: 10px;
    box-shadow: 2px 2px 2px #e6e6e6;
    border-left: 5px solid #409ffe;
    padding: 20px;
}
.table_img{
    height: 31px;
    overflow: hidden;
}
.table_cell{
  
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.5rem;
    white-space: nowrap;
}
.table_footer_page_box{
    width: 100%;
    text-align: center;
    margin-top: 40px;
}
</style>
<style scoped>



</style>