<template>
    <div>
        <div>
            <div class="cus_lable">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="评论ID">
                        <el-input v-model="commentId" placeholder="评论ID"></el-input>
                    </el-form-item>
                    <el-form-item label="帖子ID">
                        <el-input v-model="postId" placeholder="帖子ID"></el-input>
                    </el-form-item>
                    <el-form-item label="用户ID">
                        <el-input v-model="userId" placeholder="用户ID"></el-input>
                    </el-form-item>
                    <el-form-item label="被回复用户ID">
                        <el-input v-model="replyId" placeholder="被回复用户ID"></el-input>
                    </el-form-item>
                    <el-form-item label="评论类型">
                        <el-select v-model="commentType" placeholder="类型">
                        <el-option label="不限" value=null></el-option>
                        <el-option label="1级" value="1"></el-option>
                        <el-option label="2级" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评论状态">
                        <el-select v-model="commentState" placeholder="状态">
                        <el-option label="不限" value=null></el-option>
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="异常" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="排序方式">
                        <el-select v-model="orderByType" placeholder="排序方式">
                        <el-option label="ID" value="post_id"></el-option>
                        <el-option label="回复数量" value="reply_number"></el-option>
                        </el-select>
                    </el-form-item>
                  
                    <el-form-item label="创建时间">
                        <el-date-picker
                            v-model="time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @keydown.enter="loadCommentInfo" @click="loadCommentInfo">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!--  -->
        <div>
            <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="commentId"
                    label="评论id"
                    width="80">
                </el-table-column>
                
                <el-table-column
                    prop="postId"
                    label="帖子ID"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="userId"
                    label="用户ID"
                    width="80">
                </el-table-column>
                
                <el-table-column
                    prop="userInfo.userName"
                    label="用户名"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="replyCommentId"
                    label="被回复用户ID"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="reUserInfo.userName"
                    label="被回复用户名称"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="commentContent"
                    label="评论内容"
                     >
                </el-table-column>
                <el-table-column
                    prop="replyNumber"
                    label="回复数"
                    width="80">
                </el-table-column>
                
                
                <el-table-column
                    prop="commentType"
                    label="类型"
                    width="80">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.commentType==1" >1级</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.commentType==2" >2级</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="commentState"
                    label="状态"
                    width="80">


                    <template slot-scope="scope">
                        <el-switch
                        v-model="scope.row.commentState"
                        :active-value="1"
                        :inactive-value="2"
                        @change="switchChange(scope.row)"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                        </el-switch>
                        <!-- <el-switch v-if="scope.row.commentState==2"
                        value ="false"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                        </el-switch> -->
                       
                    </template>
                </el-table-column>
                <el-table-column
                    prop="commentTime"
                    label="评论时间">
                    <template slot-scope="scope">
                            {{scope.row.commentTime| dateFormat}}
                        </template>

                </el-table-column>
                <!-- <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button type="text" @click="showModeal(scope.row)" size="small">编辑</el-button>
                </template>
                </el-table-column> -->
            </el-table>
        </div>
        <!--  -->
        <div class="table_footer_page_box">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="rows">
            </el-pagination>
        </div>
        <!--  -->
        <b-modal id="commentModal" size="xl" centered  scrollable title="编辑" hide-footer hide-header>
           <AdminUpdateComment @customEvent="refLoad" :rowData='this.rowData'></AdminUpdateComment>
        </b-modal>
    </div>
</template>
<script>
import AdminUpdateComment from './AdminUpdateComment.vue';

export default {
    name: 'AdminComment',
    components:{
        AdminUpdateComment
    },
    data() {
        return {
            page: 1,
            total: 10,
            rows: 0,
            postId:null,
            userId:null,
            replyId:null,
            commentId:null,
            commentType:null,
            replyNumber:null,
            commentState:null,
            orderByType:null,
            time: [new Date(2023, 10, 10, 10, 10), new Date(2024, 11, 11, 10, 10)],
            tableData: null,
            rowData: null,
            sort:'DESC'
            
        };
    },
    methods: {
        loadCommentInfo() {
            this.$http({
                method: "post",
                url: "/web/comment-info/selectyByPage",
                data: {
                    page: this.page,
                    total: this.total,
                    postId: this.postId,
                    userId:this.userId,
                    replyId:this.replyId,
                    commentType:this.commentType,
                    commentId: this.commentId,
                    replyNumber:this.replyNumber,
                    commentState: this.commentState,
                    orderByType:this.orderByType,
                    commentTime:this.time[0],
                    endTime:this.time[1],
                    sort:this.sort,
                }
            })
                .then((res) => {
                if (res.data.code == 200) {
                    let resItems = res.data.data.commentInfoList;
                    this.tableData = resItems;
                    this.rows = res.data.data.rows;
                }
                else {
                    this.$message.error('加载失败！');
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = Number(val);
            this.loadCommentInfo();
        },
        showModeal(rowData) {
            this.$bvModal.show('commentModal');
            this.rowData = rowData;
        },
        refLoad() {
            this.$bvModal.hide('commentModal');
            this.loadCommentInfo();
        }
        ,switchChange(row){
           
            this.$http({
                method: "post",
                url: "/web/comment-info/updateCommentById",
                data: {
                    commentId: row.commentId,
                    commentState:row.commentState
                }
            })
                .then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('修改成功！');
                }
                else {
                    this.$message.error(res.data.message);
                }
            })
                .catch(function (err) {
                //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
                console.log(err);
            });

            
        }
    }, mounted() {
        this.loadCommentInfo();
    },
   
}
</script>
<style scoped>


</style>