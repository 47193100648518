import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/web/HomeView.vue'
import LoginView from '../views/web/LoginView.vue'
import ForumView from '../views/web/ForumView.vue'
import UserCenterView from '../views/web/UserCenterView.vue'
import PostContentView from '../views/web/PostContentView.vue'
import OfficialInfsView from '../views/web/OfficialInfsView.vue'
import ShowView from '../views/web/InfoShowView.vue'
import information from '@/views/web/InformationView.vue'

import AdminLoginView from '../views/AdminLoginView.vue'
import AdminView from '../views/AdminView.vue'

import AnnounceList from '@/components/admin/announce/AnnounceList.vue'   
import AdminAddOfficial from '@/components/admin/announce/AdminAddOfficial.vue'   
import AdminUserInfo from '@/components/admin/user/AdminUserInfo.vue'   
import AdminPost from '@/components/admin/post/AdminPost.vue'   
import AdminComment from '@/components/admin/comment/AdminComment.vue'   
import AdminUser from '@/components/admin/administrator/AdminUser.vue'   
import AdminHome from '@/components/admin/echart/AdminHome.vue'   
import AdminVisit from '@/components/admin/visit/AdminVisit.vue'   
import ReportRecords from '@/components/admin/report/AdminReportRecords.vue'   
import AdminPage from '@/components/admin/AdminPage.vue'   
import AdminSensitiveWords from '@/components/admin/sensitivue_words/AdminSensitiveWords.vue'   

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      title:"禹迹图|禹迹图online|禹迹图官方|主页"
      ,keywords:'禹迹图|禹迹图online|禹迹图官方|主页|游戏',
      description:'禹迹图科技有限公司'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta:{
      title:"禹迹图|禹迹图online|登录"
      ,keywords:'禹迹图|禹迹图online|登录|游戏',
      description:'禹迹图科技有限公司'
    }
  },
  {
    path: '/forum',
    name: 'forum',
    component: ForumView,
    meta:{
      title:"禹迹图|禹迹图online|论坛"
      ,keywords:'禹迹图|禹迹图online|论坛|游戏',
      description:'禹迹图科技有限公司'
    }
  },
  {
    path: '/usercenter',
    name: 'usercenter',
    component: UserCenterView,
    meta:{
      title:"禹迹图|禹迹图online|用户中心"
      ,keywords:'禹迹图|禹迹图online|用户中心|游戏',
      description:'禹迹图科技有限公司'
    }
  },
  {
    path: '/postcontent',
    name: 'postcontent',
    component: PostContentView,
    meta:{
      title:"禹迹图|禹迹图online|帖子详情"
      ,keywords:'禹迹图|禹迹图online|帖子详情|游戏',
      description:'禹迹图科技有限公司'
    }
  },
  {
    path: '/officialinfos',
    name: 'officialinfos',
    component: OfficialInfsView,
    meta:{
      title:"禹迹图|禹迹图online|资讯列表"
      ,keywords:'禹迹图|禹迹图online|资讯列表|游戏',
      description:'禹迹图科技有限公司'
    }
  },
  {
    path: '/InfoShowView',
    name: 'InfoShowView',
    component: ShowView,
    meta:{
      title:"禹迹图|禹迹图online|资讯详情"
      ,keywords:'禹迹图|禹迹图online|资讯详情|游戏',
      description:'禹迹图科技有限公司'
    }
  },{
    path: '/adminlogin',
    name: 'adminlogin',
    component: AdminLoginView,
    meta:{
      title:"管理员登录"
      ,keywords:'禹迹图|管理员登录|游戏',
      description:'禹迹图科技有限公司'
    }
  },{
    path: '/information',
    name: 'information',
    component: information,
    meta:{
      title:"禹迹图|禹迹图online|资讯"
      ,keywords:'禹迹图|禹迹图online|资讯列表|游戏',
      description:'禹迹图科技有限公司'
    }
  },{
    path: '/strategy',
    name: 'strategy',
    component: () => import('@/views/web/StrategyView.vue'),
    meta:{
      title:"禹迹图资料最新资讯"
      ,keywords:'禹迹图',
      description:'禹迹图科技有限公司'
    },
  },{
    path: '/StrategyShow',
    name: 'StrategyShow',
    component: () => import('@/views/web/StrategyShow.vue'),
    meta:{
      title:"禹迹图资料资讯"
      ,keywords:'禹迹图',
      description:'禹迹图科技有限公司'
    }
  },{
    path: '/GameDataView',
    name: 'GameDataView',
    component: () => import('@/views/web/GameDataView.vue'),
    meta:{
      title:"禹迹图资料库"
      ,keywords:'禹迹图',
      description:'禹迹图科技有限公司'
    }
    ,children:[
      {
        path:'EquipView',
        name:'EquipView',
        component: () => import('@/views/web/data/EquipView.vue'),
      },
      {
        path:'StoreView',
        name:'StoreView',
        component: () => import('@/views/web/data/StoreView.vue'),
      },
    ]
  },
  {
    path: '/PropShowView',
    name: 'PropShowView',
    component: () => import('@/views/web/PropShowView.vue'),
    meta:{
      title:"禹迹图资料详情"
      ,keywords:'禹迹图',
      description:'禹迹图科技有限公司'
    }
  }
  ,{
    path: '/admin',
    name: 'admin',
    component: AdminView,
    redirect:'/admin/AdminHome',
    children:[
      {
        path:'AnnounceList',
        name:'AnnounceList',
        component:AnnounceList
      },
      {
        path:'AdminAddOfficial',
        name:'AdminAddOfficial',
        component:AdminAddOfficial
      }
      ,{
        path:'AdminUserInfo',
        name:'AdminUserInfo',
        component:AdminUserInfo
      }
      ,{
        path:'AdminPost',
        name:'AdminPost',
        component:AdminPost
      }
      ,{
        path:'AdminComment',
        name:'AdminComment',
        component:AdminComment
      }
      ,{
        path:'AdminUser',
        name:'AdminUser',
        component:AdminUser
      }
      ,{
        path:'AdminHome',
        name:'AdminHome',
        component:AdminHome
      }
      ,{
        path:'AdminVisit',
        name:'AdminVisit',
        component:AdminVisit
      }
      ,{
        path:'ReportRecords',
        name:'ReportRecords',
        component:ReportRecords
      }
      ,{
        path:'AdminPage',
        name:'AdminPage',
        component:AdminPage
      }
      ,{
        path:'AdminSensitiveWords',
        name:'AdminSensitiveWords',
        component:AdminSensitiveWords
      },
      {
        path:'Blacklist',
        name:'Blacklist',
        component:() => import('@/components/admin/blacklist/Blacklist.vue')
      },
      {
        path:'SysDict',
        name:'SysDict',
        component:() => import('@/components/admin/sys_dict/SysDict.vue')
      },
      {
        path:'Strategy',
        name:'Strategy',
        component:() => import('@/components/admin/strategy/Strategy.vue')
      },
      {
        path:'AddStrategy',
        name:'AddStrategy',
        component:() => import('@/components/admin/strategy/AddStrategy.vue')
      },
      {
        path:'GameProp',
        name:'GameProp',
        component:() => import('@/components/admin/prop/GameProp.vue')
      },
      {
        path:'EquipCompound',
        name:'EquipCompound',
        component:() => import('@/components/admin/prop/EquipCompound.vue')
      },
      {
        path:'CompoundFormula',
        name:'CompoundFormula',
        component:() => import('@/components/admin/prop/CompoundFormula.vue')
      },
      
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/web/AboutView.vue')
  }
]





const router = new VueRouter({
  routes
})
router.beforeEach((to,from,next)=>{

     /*********动态修改keywords和description*************/
  if(Object.keys(to.meta).length>0 && to.matched.length>0){
    let this_meta=to.matched[to.matched.length-1].meta
    //console.log(this_meta,'---this_meta---')
    if(this_meta.title)document.title=this_meta.title
    let head = document.getElementsByTagName('head');
    let meta_keyword=document.createElement('meta');
    if(document.querySelector('meta[name="keywords"]')){
      document.querySelector('meta[name="keywords"]').setAttribute('content',this_meta.keywords)
    }else{
      meta_keyword.setAttribute('name','keywords')
      meta_keyword.setAttribute('content',this_meta.keywords)
      head[0].appendChild(meta_keyword)
    }
    let meta_description=document.createElement('meta');
    if(document.querySelector('meta[name="description"]')){
      document.querySelector('meta[name="description"]').setAttribute('content',this_meta.description)
    }else{
      meta_description.setAttribute('name','description')
      meta_description.setAttribute('content',this_meta.description)
      head[0].appendChild(meta_description)
    }
  }
  /**********************************************/



    if(to.path == '/login' || to.path== '/'|| 
    to.path == '/forum'|| 
    to.path == '/comment'|| 
    to.path == '/postcontent/'|| 
    to.path == '/officialinfos'|| 
    to.path == '/show/'||
    to.path == '/information'||
    to.path == '/strategy'||
    to.path == '/StrategyShow/'||
    to.path == '/StrategyShow'||
    to.path == '/GameDataView/'||
    to.path == '/InfoShowView/'||
    to.path == '/InfoShowView'||
    to.path == '/GameDataView'||
    to.path == '/PropShowView'||
    to.path == '/PropShowView/'||
    to.path == '/adminlogin'
  
    ){
        next();
    }else{
      let token = localStorage.getItem("token");
      let a_token = localStorage.getItem("a_token");
        if(token){
          console.log("getItem-------->"+token);
          // console.log(JSON.parse(localStorage.store).token);
          let data = localStorage.getItem("data");
          let json =   JSON.parse(data);
          let  overTime =   json.overTime
          //时间
          var date1 = new Date(overTime);
          //现在时间
          var date2 = new Date();
          //判断时间是否过期
          if(date1 < date2 ){
            alert("登录过期请重新登录！")
            localStorage.removeItem("token") 
            localStorage.removeItem("userId")
            localStorage.removeItem("data")  
            localStorage.removeItem("userPhoto")
            location.reload();
          }  
          if(token == null || token == ''){
              
              next('/forum');
          }else{
              next();
          }
        }else if(a_token){
          console.log("getItem-------->"+token);
          // console.log(JSON.parse(localStorage.store).token);
          let data = localStorage.getItem("a_data");
          let json =   JSON.parse(data);
          let  overTime =   json.overTime
          //时间
          let date1 = new Date(overTime);
          //现在时间
          let date2 = new Date();
          //判断时间是否过期
          if(date1 < date2 ){
            alert("登录过期请重新登录！")
            localStorage.removeItem("a_token") 
            localStorage.removeItem("adminId")
            localStorage.removeItem("a_data")  
            location.reload();
          }  
          if(a_token == null || a_token == ''){
              
              next('/adminlogin');
          }else{
              next();
          }
        }
       
        
    }
  
  
})

export default router
