<template>
    <div class="">
      <el-carousel height="45vw">
        <el-carousel-item class="ban_bg" v-for="item in items" :key="item.number"  :style="`background-image: url(${item.url});`">
          <h3 class="small">{{ item.title }}</h3>
          <div>
             
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CarouselEl',
    data() {
      return {
        slide: 0,
        items: [
            {number:1, url:'http://yujituol.com/img/a.png',title: '' },
            {number:2, url:'http://yujituol.com/10006.png',title: '' },
            {number:3, url:'http://yujituol.com/10007.png',title: '' },
          
            ],
       
      }
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
  }
  </script>
  

  <style scoped>
  .ban_bg{
    background-size: 100%;
    
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }
/* 
  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  } */
  </style>
  