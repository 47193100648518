<template>
    <div class="bg">
      <NavBar></NavBar>
       <div class="bg_f">
          <div class="info_header">

          </div>
          <b-container class="bv-example-row">
            <b-row>
              <div class="info_box">
            <!-- <div class="info_left">
                <div class="info_left_box">
r
                </div>
            </div> -->
            <div class="info_content">
               <div class="info_content_header">
                    <div :class="{blueColor:null==officialInfo.officialType}" @click="chooseType(null)">
                      最新 
                    </div>
                    <div :class="{blueColor:1==officialInfo.officialType}" @click="chooseType(1)">
                     公告 
                    </div>
                    <div :class="{blueColor:2==officialInfo.officialType}" @click="chooseType(2)">
                       活动 
                    </div>
                    <div :class="{blueColor:3==officialInfo.officialType}" @click="chooseType(3)">
                        新闻
                    </div>
                </div>
                <div class="info_content_value">
                     <div class="info_content_item" v-for="officialInfo in officialInfoList" :key="officialInfo.officialId">
                   
                        <router-link :to="'/InfoShowView/?officialId='+ officialInfo.officialId">{{officialInfo.officialTitle}} </router-link>
                      <span class="info_content_item_time">
                        {{officialInfo.officialTime|dateFormat }}
                      </span>
                    </div>
                </div>
                <div class="info_content_more">
                  <button  v-if="moreBtn" class="info_content_more_btn" @click="moreOfficial()" >更多>>></button>
                </div>
            </div>
          
          </div>
            </b-row>
          </b-container>
          
         
       </div>
       <PageBottom></PageBottom>
    </div>
  </template>
  <script>
 import PageBottom from '@/components/PageBottom.vue';
 import NavBar from '@/components/NavBar.vue'
  
  export default {
    name: 'OfficialInforView',
    components: {
      NavBar,
        PageBottom,
       
  },
    data() {
      return {
        moreBtn:true,
        url: "background-image: url('http:localhost/10013bg.png');",
       officialInfo:{
        page:1,
        total:10,
        officialType:0,
       },
        officialInfoList:[],
        rows:0
      }
    },
    methods:{
      chooseType(index) {
        this.officialInfo.officialType = index;
        this.officialInfoList=[]
        this.loadOfficialInfo()
      },
      moreOfficial(){
        this.officialInfo.page++
        this.loadOfficialInfo()
      },
      loadOfficialInfo(){
        this.$http.post("/web/official/selectOfficialInfoByPage",this.officialInfo)
            .then((res)=>{
            if(res.data.code==200){
              let resItems=res.data.data.officialInfoList;
              this.officialInfoList=this.officialInfoList.concat(resItems);
              this.rows=res.data.data.rows;
              let number = (this.officialInfo.page*this.officialInfo.total)
              if(number>this.rows){
                this.moreBtn=false
              }else{
                this.moreBtn=true
              }
               
            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      }

    },mounted(){
         //let id =  this.$parent.moreReCommentId;
            //alert(this.ReCommentId)
            this. loadOfficialInfo();
        }
   
   
  }
  </script>
  <style scoped>
  .bg{
    background-color: #efefef;
    /* background-image: url("https://www.sanguosha.com/static/pc/dist/img/bg3.jpg?165c7ee7129"); */
    width: 100%;
    min-height: 70rem;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
  }
  .bg_f{
    width: 100%;
    min-height: 80rem;
    /* background-color: #e4e4e4; */
  }
  .info_header{
    min-height: 10rem;
    background-size: 100%;
    background-attachment: fixed;
    background-position: center;
    /* background-image: url("http://localhost/10013bg.png"); */
    width: 100%;
   
  }
  .info_box{
    display: flex;
    justify-content: center;
  }
  .info_left{
    
    width: 25rem;
    min-height: 80rem;
    padding: 0 2rem;
  }
  .info_content{
    background-color: #fff;
    /* box-shadow: 1px 1px 1px #999; */
    width: 100%;
  }
  .info_content_value{
    padding: 1rem 5rem;
  }
  .info_left_box{
    width: 100%;
    background-color: #fff;
    /* box-shadow: 5px 5px 5px #999; */
    min-height: 40rem;
  }
  .info_content_header{
    height: 5rem;
    text-align: center;
    font-size: 1rem;
    color: #fff;
    background-color: #668;
    align-items: center;
    font-weight: 600;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
  }
  .info_content_item{
    padding: 2rem;
    border-bottom: 1px dashed #999;
    
  }
  .info_content_item a{
    text-decoration: none;
    color: #333;
  }
  .info_content_item_time{
    float: right;
    font-size: 1rem;
    color: #999;
  }
  .info_content_more{
    display: flex;
    justify-content: center;
  }
  .info_content_more_btn{
    border: 0;
    padding: 1rem 4rem;
    background-color: #333;
    color: #fff;
  }
  .blueColor{
    color: #409ffe;
  }
  @media all and (orientation : portrait) {
    .info_header{
      min-height: 2rem;
    }
    .info_content_value{
      padding: 0;
    }
    /* .info_content_header{
      height: 10rem;
      font-size: 2.3rem;
    }
    .info_content_item{
      font-size: 2rem;
    }
     */
  }
  </style>
  