<template>
    <div>
        <div class="cus_lable">

        </div>
        <div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'AdminPage',
    data() {
        return {

        }
    }
}
</script>
<style scoped>
</style>