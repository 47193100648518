<template>
    <div>
        <div>
            <div class="cus_lable">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="IP">
                        <el-input v-model="visitIp" placeholder="ip"></el-input>
                    </el-form-item>
                    <el-form-item label="地区">
                        <el-input v-model="visitArea" placeholder="地区"></el-input>
                    </el-form-item>
                    <el-form-item label="时间">
                        <el-date-picker
                            v-model="time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="loadVisitInfo()">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>

        <div>
            <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="visitId"
                    label="ID"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="visitIp"
                    label="Ip"
                  >
                </el-table-column>
                <!-- <el-table-column
                    prop="visitNumber"
                    label="账号">
                </el-table-column> -->
                <el-table-column
                    prop="visitArea"
                    label="地区"
                    width="280">
                </el-table-column>
                <el-table-column
                    prop="visitType"
                    label="类型">
                </el-table-column>
                <el-table-column
                    prop="visitTime"
                    label="时间">
                    <template slot-scope="scope">
                            {{scope.row.visitTime| dateFormat}}
                    </template>
                </el-table-column>
               
                </el-table>
        </div>
        <div class="table_footer_page_box">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="rows">
            </el-pagination>
        </div>
        <!--  -->
    </div>
</template>
<script>

export default {
    name:'AdminVisit',
    components:{
        
    },
    data() {
      return {
        page: 1,
        total: 10,
        rows:0,
        visitIp:null,
        visitArea:null,
        time: [new Date(2023, 10, 10, 10, 10), new Date(2024, 11, 11, 10, 10)],
        visitTime:null,
        endTime:null,
        tableData: [],
        rowData:null
        
      };
    }
    ,methods: {
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page=Number(val);
        this.loadVisitInfo();
      },
      showModeal(rowData){
        this.$bvModal.show('offModal')
        this.rowData=rowData

      }
      ,refLoad(){
        this.$bvModal.hide('offModal')
        this.loadVisitInfo()
      }
      ,loadVisitInfo(){
        this.$http({
            method: "post",//指定请求方式
            url: "/web/visit/selectVisitRecordByPage",
            data: {
                page:this.page,
                total:this.total,  
                visitIp:this.visitIp,  
                visitArea:this.visitArea,  
                visitTime:this.time[0],  
                endTime:this.time[1],  
            }
            })
            .then((res)=>{
            if(res.data.code==200){
                let resItems=res.data.data.visitRecordList; 
                this.tableData=resItems;
                this.rows=res.data.data.rows;
            }else{
                this.$message.error('加载失败！');
            }
            })
            .catch(function(err){
            //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
            console.log(err);
        
            }) 
      },
    },mounted(){
            this.loadVisitInfo();
        
      }
  }
</script>
<style>
.cus_lable{ 
    border: 1px solid #efefef;
    margin: 10px;
    box-shadow: 2px 2px 2px #e6e6e6;
    border-left: 5px solid #409ffe;
    padding: 20px;
}
.table_img{
    height: 31px;
    overflow: hidden;
}
.table_cell{
  
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.5rem;
    white-space: nowrap;
}
.table_footer_page_box{
    width: 100%;
    text-align: center;
    margin-top: 40px;
}
</style>
<style scoped>



</style>