<template>
    <div class="content_show">
        <b-container>
            <el-row justify="center">
                <el-col :xs="24" :sm="24" :md="12" :lg="8"  v-for="itme in items" :key="itme.number" >
                        <div class="show_box wow slidelinDown" data-wow-duration="2s"  data-wow-offset="10">
                            <div class="show_box_card">
                                <div class="show_img_box" :style="`background-image: url(${itme.url});`">
                                <!-- <img :src="itme.url" class="image"> -->
                                </div>
                                <div class="show_title">
                                    <span>{{itme.title}}</span>
                                </div>
                            </div>
                        </div>
                </el-col>
           
        </el-row>
        </b-container>
    </div>
 

  </template>
  
  <script>
  import { WOW } from "wowjs";
  export default {
    name: 'ContentShow',
    data() {
      return {
     
        items: [
            {number:1, url:'http://yujituol.com/10005.png',title: '葬魔洞' },
            {number:2, url:'http://yujituol.com/10006.png',title: '白帝城' },
            {number:3, url:'http://yujituol.com/10007.png',title: '三仗原' },
            {number:4, url:'http://yujituol.com/10008.png',title: '归云谷' },
            {number:5, url:'http://yujituol.com/10009.png',title: '洛水间' },
            {number:6, url:'http://yujituol.com/10010.png',title: '大荒城' },
           
            ],
     
      }
    },
    methods: {
     
    },mounted() {
        let wow = new WOW({
          boxClass: "wow", //需要执行动画的元素的 class
          animateClass: "fadeInUp", //fadeInDown 动画的 class
          offset: 10, //距离可视区域多少开始执行动画
          mobile: true, //是否在移动设备上执行动画
          live: true, //异步加载的内容是否有效
        });
        wow.init();
  }
}
  </script>
  

<style scoped>



.content_show{
    background-image: url("http://yujituol.com/10013bg.png");
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    padding: 2rem 0px;
    }
    .show_box{
        width: 100%;
        /* height: 20vw; */
        padding: 1rem;
        text-align: center;
        color: #fff;
        border-radius: 1rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        cursor: pointer;
       
    }
   
    .show_box_card{
        border-radius: 1rem;
        box-shadow: 5px 5px 5px #464545;
        cursor: pointer;
        width: 100%;
        height: 100%;
        /* -webkit-animation: show_box_card 5s;
        animation: show_box_card 5s; */
        transition-duration:1s;
        overflow: hidden;
        aspect-ratio:1/1;
    }
    .show_box_card:hover{
        box-shadow: 7px 7px 7px #464545;
        transform: scale(1.1);
    }
 

    .show_img_box{
        width: 100%;
        height: 85%;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
    }
       
    .show_img_box img{
        height: 100%;
    }
    
    .show_title{
        display: flex;
         width: 100%;
         height: 15%;
         background-color: #333;
        justify-content: center;
        flex-direction: column-reverse;
        font-size: 1rem;

}
@media all and (orientation : portrait) {


}
</style>
  