<template>
    <div class="bottom">
      <div>
         <div class="bottom_logo_box">
            <img src="http://yujituol.com/logo1.png"/>
         </div>
      </div>
      <div class="bottom_remark_a">
        <router-link to="/about">公司简介</router-link>-
        <a href="">隐私政策及儿童个人信息保护规则</a>-
        <a href="https://www.zhipin.com/gongsi/bc3bae83be4fac3a1XRy3Nq5EFI~.html?ka=company-intro">商务合作</a>-        
        <a href="https://www.zhipin.com/gongsi/bc3bae83be4fac3a1XRy3Nq5EFI~.html?ka=company-intro">加入我们</a>
      </div>
      <el-row>
         <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="bottom_img_box">
               <div>扫码关注官方公众号</div>
               <div class="bottom_img_bg">
                  <img  class="bottom_code" src="http://yujituol.com/二维码.png"/>
               </div>
            </div>
         </el-col>
         <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="bottom_qqurl">
               
               <ul>
                  <li>
                     禹迹图官方玩家1群：903569121
                  </li>
                  <li>
                     禹迹图官方玩家2群：903569121
                  </li>
                  <li>
                     禹迹图官方玩家3群：903569121
                  </li>
                  <div class="bottom_icon">
                     <a href="https://tieba.baidu.com/f?ie=utf-8&kw=%E7%A6%B9%E8%BF%B9%E5%9B%BE&fr=search" target="_blank"><img  class="nav_log" src="http://yujituol.com/百度贴吧.png"/></a>
                     
                     <a href="https://weibo.com/u/7883381546" target="_blank"> <img  class="nav_log" src="http://yujituol.com/新浪微博.png" /></a>
                     <a href="https://www.xiaohongshu.com/user/profile/64523f45000000002901369b" target="_blank"> <img  class="nav_log" src="http://yujituol.com/img/xhs.png" /></a>
      </div>
               </ul>
            </div>
              
         </el-col>

      </el-row>
      
      <div class="bottom_text">
            <a href="https://beian.miit.gov.cn"> 晋ICP备2023026532号</a>
            出版服务公司：重庆禹迹图科技有限公司
            <a href= "https://beian.mps.gov.cn/#/query/webSearch?code=50010402001495" rel="noreferrer" target="_blank">
              <img src="@/assets/beian.png">
              渝公网安备50010402001495
            </a >
           
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PageBottom',
    
  }
  </script>
  

  <style scoped>
  .bottom{
   color: #fff;
   list-style: none;
   margin-top: 2rem;
   background-color: #17171A;
   text-align: center;
  }
  .bottom a{
   list-style: none;
   text-decoration: none;
   color: #fff;
   cursor: pointer;
   line-height: 5rem;
   transition: 0.5s;
  }
  .bottom a:hover{
   color: #999;
  }
 .bottom_img_box{
   height: 10rem;
   float: right;
 }
 .bottom_logo_box img{
   height: 10rem;
 }
 .bottom_img_box img{
   height: 100%;
  
 }
 .bottom_img_bg{
  text-align: center;
   padding: 5px;
   height: 100%;
   
 }
 .bottom_img_bg img{
   height: 100%;
 }
 .bottom_qqurl{
   float: left;
   padding-top: 2rem;
 }
 
 .bottom_icon img{
   height: 3rem;
   margin: 1rem;
 }
 .bottom_text{
   background-color: black;
   padding: 2rem;
   display: flex;
    justify-content: center;
    align-items: center;
 }
 .bottom_text a{
   text-decoration: none;
   color: #fff;
   margin: 0 1rem;
 }
 .bottom_text img{
  height: 1rem;
 }
 @media all and (orientation : portrait) {
  .bottom_img_box{
    float: none;
  }
  .bottom_qqurl{
    float: none;
  }
  .bottom_text{
    flex-wrap: wrap;
  }
 }
 /* @media all and (orientation : portrait) {

    .bottom_logo_box img{
      height: 20rem;
    }
    .bottom_remark_a{
      font-size: 3rem;
    }
    .bottom_img_bg{
      padding: 0;
    }
    .bottom_img_box{
      height: 25rem;
      font-size: 2rem;
    }
    .bottom_qqurl{
      padding-top: 5rem;
      font-size: 3rem;
    }
    .bottom_icon img{
      height: 10rem;
    }
    .bottom_text{
      font-size: 2rem;
    }

 } */
  </style>
  