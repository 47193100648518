<template>
    <div>
        <div>
            <!--  -->
            <AdminHomeTop></AdminHomeTop>
            <!--  -->
            <div>
                <el-row :gutter="20">
                    <el-col :span="16">
                        <div>
                            <AdminVisitEchart></AdminVisitEchart>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <AdminCommentEchart></AdminCommentEchart>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div>
                            <AdminUserEchart></AdminUserEchart>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div>
                            <AdminPostEchart></AdminPostEchart>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import AdminVisitEchart from '@/components/admin/echart/AdminVisitEchart.vue';
import AdminPostEchart from '@/components/admin/echart/AdminPostEchart.vue';
import AdminCommentEchart from '@/components/admin/echart/AdminCommentEchart.vue';
import AdminUserEchart from '@/components/admin/echart/AdminUserEchart.vue';
import AdminHomeTop from '@/components/admin/echart/AdminHomeTop.vue';
export default {
    name: 'AdminHome',
    components:{
        AdminHomeTop,
        AdminVisitEchart,
        AdminPostEchart,
        AdminCommentEchart,
        AdminUserEchart
    },
    methods:{
    
    },
    mounted() {
   
    }
}
</script>
<style scoped>

</style>